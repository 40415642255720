.attr-ratings {
  padding: 15px;
  overflow: hidden; }

.attr-ratings-content {
  position: relative;
  height: 500px; }

.attr-ratings_title {
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  font-size: 15px;
  font-weight: bold; }

.attr-ratings_legend {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.attr-ratings_legend-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &.-left-bottom {
    left: 0;
    bottom: 0; }
  &.-right-bottom {
    right: 0;
    bottom: 0; }
  &.-left-top {
    left: 0;
    top: 0; }
  &.-right-top {
    right: 0;
    top: 0; }
  &[class*="right"] {
    align-items: flex-end; }
  &[class*="bottom"] {
    flex-direction: column-reverse; } }

.attr-ratings_legend-title {
  color: #fff;
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 20px; }

.attr-ratings_legend-hint {
  font-size: 13px;
  line-height: 1.8;
  opacity: 0.6; }

.attr-ratings_color {
  background-color: #dddddd;
  &.-low-low {
    background-color: #A9C3CA; }
  &.-high-low {
    background-color: #FF6900; }
  &.-low-high {
    background-color: #00AEEF; }
  &.-high-high {
    background-color: #3CBCA1; } }

.attr-ratings_graph {
  position: absolute;
  top: 50px;
  bottom: 80px;
  left: 75px;
  right: 75px; }

.attr-ratings_graph-grid-lines {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }


.attr-ratings_graph-grid-line {
  position: absolute;
  &:first-child, &:last-child {
    .attr-ratings_graph-grid-line-label {
      display: none; } }
  .-horizontal > & {
    width: 100%;
    height: 1px;
    border-top: 1px dashed rgba(60, 188, 161, 0.3);
    &:first-child, &:last-child {
      left: -100px;
      right: -100px;
      width: auto; } }
  .-vertical > & {
    height: 100%;
    width: 1px;
    border-left: 1px dashed rgba(60, 188, 161, 0.3); } }

.attr-ratings_graph-grid-line-label {
  font-size: 13px;
  line-height: 1.2;
  opacity: 0.6;
  position: absolute;
  .-horizontal & {
    right: 100%;
    text-align: right;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: 7px; }
  .-vertical & {
    top: 100%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 5px; } }

.attr-ratings_graph-grid-label {
  position: absolute;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.4;
  &.-vert {
    left: 0;
    top: 50%;
    text-align: left;
    transform: translate(-50%, 0) rotate(-90deg);
    transform-origin: 50% 50%;
    margin-left: -60px; }
  &.-hor {
    bottom: 0;
    text-align: center;
    width: 100%;
    margin-bottom: -60px; } }

.attr-ratings_graph-data-avg {
  position: absolute;
  background-color: #3CBCA1;
  &.-vert {
    height: 100%;
    width: 3px;
    transform: translate(-50%, 0); }
  &.-hor {
    width: 100%;
    height: 3px;
    transform: translate(0, 50%); } }

.attr-ratings_graph-data-avg-label {
  position: absolute;
  font-size: 13px;
  line-height: 1.3;
  font-weight: bold;
  opacity: 0.6;
  white-space: nowrap;
  .-vert > & {
    left: 100%;
    bottom: 0;
    margin-left: 7px; }
  .-hor > & {
    right: 15px;
    bottom: 100%;
    &.-label-bottom {
      top: 100%; } } }

.attr-ratings_graph-data-dot {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 4px solid #413C58;
  border-radius: 100%;
  transform: translate(-50%, 50%);
  cursor: pointer;
  &:hover {
    border-color: #fff;
    z-index: 2; } }

.attr-ratings_graph-data-dot-label {
  position: absolute;
  cursor: pointer;
  transform: translate(0, 50%);
  margin-left: 4px;
  font-size: 13px;
  line-height: 1.1;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  &.-to-left {
    transform: translate(-10px) translate(-100%, 50%); }
  span {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    padding: 1px 3px;
    display: inline-block; } }

.attr-ratings_graph-data-dot-info {
  color: #fff;
  margin-bottom: 5px;
  z-index: 1101; }

.attr-ratings_graph-data-dot-info-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center; }

.attr-ratings_graph-data-dot-info-values {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2; }
