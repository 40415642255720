// Bootstrap table styles overrides and adding for pages without using legacy bootstrap

.table {
  font-family: $font-family-base;
  color: $color-gray;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  //thead
  //border: none

  thead > tr > th {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    vertical-align: bottom;
    //border-bottom-width: 1px
    //border-bottom-style: solid
    padding: 20px 8px;
    border: none;
    //&.-with-dropdown-hover
    //&:hover
    //  z-index: 1
    //&.-nowrap
 }    //white-space: nowrap

  //todo tl: check and delete unused styles
  //thead.table_dummy-head > tr > th
  //border: none
  //padding-top: 0
  //padding-bottom: 0
  //height: 0

  tbody > tr > td {
    padding: 15px 8px;
    border-top: 1px solid $color-border;
    &[ng-click] {
      cursor: pointer; } }

  thead > tr > th,
  tbody > tr > td {
    &.-max-width {
      width: 100%; }
    &.-half-width {
      width: 50%; }
    &.-third-width {
      width: 33%; }
    &.-nowrap {
      white-space: nowrap; } }

  //> tbody > tr:first-child > td,
  //> tbody > tr:first-child > th
  //  border-top: none

  //thead > tr > th,
  //tbody > tr > th,
  //tfoot > tr > th,
  //thead > tr > td,
  //tbody > tr > td
  //  border-bottom: 1px solid $color-border

  //tbody > tr:last-child > td
  //border-bottom: none

  ////&.table_control
  //  text-align: right
  //  width: 30px
  ////&.-max-width
  //  width: 100%

  //&.-striped
  //tbody > tr > td
  //  border-top: none
  //  border-bottom: none
  //> tbody > tr:nth-child(even) > td
  //  background: $color-lightgray

  //&.-bordered
  //tbody > tr:not(:first-child) > td
  //  border-top-width: 1px
  //  border-top-style: solid

  //&.-small-header
  //thead > tr > th
  //  font-size: 10px
  //  line-height: 1.2
  //  vertical-align: top

  //&.-small-text
  //tbody > tr > td
  //  font-size: 13px

  //&.-smaller-text
  //tbody > tr > td
  //  font-size: 11px

  //&.-no-header-border
  //thead > tr > th
  //  border: none

  //&.-no-side-border
  //thead > tr > th,
  //tbody > tr > td
  //  border-left: none

  &.-with-bottom-border {
    tbody > tr:last-child > td,
    tbody > tr:last-child > th {
      border-bottom: solid 1px $color-border; } }

  //&.-big-padding
  //thead > tr > th,
  //tbody > tr > th,
  //tfoot > tr > th,
  //thead > tr > td,
  //tbody > tr > td,
  //tfoot > tr > td
  //  padding: 20px 10px

  //&.-big-md-padding
  //thead > tr > th,
  //tbody > tr > th,
  //tfoot > tr > th,
  //thead > tr > td,
  //tbody > tr > td,
  //tfoot > tr > td
  //  padding: 20px 15px

  //&.-equal-padding
  //thead > tr > th,
  //tbody > tr > th,
  //tfoot > tr > th,
  //thead > tr > td,
  //tbody > tr > td,
  //tfoot > tr > td
  //  padding: 15px

  //&.-big-equal-padding
  //thead > tr > th,
  //tbody > tr > th,
  //tfoot > tr > th,
  //thead > tr > td,
  //tbody > tr > td,
  //tfoot > tr > td
  //  padding: 20px

  //&.-no-side-padding
  //tr > th:first-child,
  //tr > td:first-child
  //  padding-left: 0
  //tr > th:last-child,
  //tr > td:last-child
  //  padding-right: 0

  //&.-no-side-padding-left
  //tr > th:first-child,
  //tr > td:first-child
  //  padding-left: 0

  //&.-no-side-padding-right
  //tr > th:last-child,
  //tr > td:last-child
  //  padding-right: 0

  //.table-wrap.-bleed &
  //  tr > th:first-child,
  //  tr > td:first-child
  //    padding-left: 20px
  //  tr > th:last-child,
  //  tr > td:last-child
  //    padding-right: 20px

  //&.-no-margin
  //margin: 0

  //&.-shrink
  //width: 100px
  //table-layout: auto
 }  //min-width: 0

.table_link {
  text-decoration: none;

  &:hover {
    text-decoration: none; } }

.table_heading-corner {
  position: relative;
  height: 100%;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 10%;
    left: 32%;
    right: 32%;
    border-top: solid 1px $color-border;
    transform-origin: 50% 50%;
    transform: rotate(30deg); } }

.table_heading-corner-row,
.table_heading-corner-col {
  color: $color-gray;
  opacity: .5;
  position: absolute; }

.table_heading-corner-row {
  top: 0;
  right: 0; }

.table_heading-corner-col {
  bottom: 0;
  left: 0; }

// element for hover dropdown (ex, table in image frequency)
.th_inner {
  &.-with-remove {
    position: relative;
    padding-right: 23px;
    display: inline-block; } }

.th_btn-remove {
  position: absolute;
  right: 0;
  &.-top {
    top: 1px; } }

.table_dropdown-hover {
  border-bottom: 1px dashed currentColor;
  display: inline !important;
  cursor: pointer; }

.table_hints-list {
  padding: 10px;
  min-width: 160px; }

.table_hint {
  font-size: 13px;
  & + & {
    margin-top: 5px; } }


