.navigation {
  display: flex;
  flex-direction: column;
  height: 100vh;
  border-left: 1px solid $color-line-light;
  width: 200px;
  padding: 25px;
  overflow-y: auto; }


.navigation_item {
  flex: 0 0 auto;
  &.-grow {
    flex: 1 1 auto; }
  & + & {
    margin-top: 20px; } }

.navigation_steps {
  list-style: none;
  padding: 0;
  margin: 0; }

.navigation_steps-item {
  position: relative;
  padding-left: 20px;
  color: $color-text-nav;
  &.-active {
    color: $color-text;
    font-weight: bold; }
  & + & {
    margin-top: 10px; } }

.navigation_steps-link, .navigation_steps-text {
  display: block;
  font-size: 14px;
  line-height: 1.4;
  color: inherit; }

.navigation_steps-link {
  &:hover, &:active,&:focus {
    color: $color-text; } }

.navigation_steps-item-icon {
  position: absolute;
  left: 0;
  top: 2px; }

.navigation_steps-item-num {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 11px; }

.navigation_section {
  & + & {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #DEE5E6; }
  &.-border-bottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #DEE5E6; } }

.navigation_examples-text {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  margin: 5px 0 -5px; }

.navigation_examples-preview {
  display: block;
  width: 100%; }

.navigation_examples-preview-img {
  width: 100%;
  height: auto; }
