$btn-colors: (default: $color-default, warning: $color-danger, success: $color-success-darker);

@mixin button-variant($color) {
  border-color: $color;
  &:hover {
    background-color: $color; }
  &:active,
  &:focus {
    border-color: darken($color, 10%);
    background-color: darken($color, 10%); }
  &.-selected {
    background-color: $color; } }

.button-filter {
  display: inline-block;
  vertical-align: middle;
  font-family: $font-family-base;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  padding: 7px 14px;
  border-radius: $button-height;
  position: relative;
  margin: 0;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  background-color: transparent;
  color: $color-text;
  border: 2px solid transparent;
  text-decoration: none;
  outline: none;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    color: #fff;
    border-color: transparent; }
  &.-selected {
    border-color: transparent;
    color: #fff;
    cursor: default;
    pointer-events: none;
    //&.-default
 }    //  background-color: $color-default

  @each $color, $value in $btn-colors {
    &.-#{$color} {
      @include button-variant($value); } } }

