.express-variants {
  padding: 25px 20px 15px 25px; }

.express-variants_list-item {
  & + & {
    margin-top: 15px; } }

.express-variants_item {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.express-variants_item-text {
  font-size: 14px;
  color: #6f7588;
  margin-left: 20px;
  flex: 0 1 auto; }

.express-variants_item-preview {
  display: block;
  width: 106px;
  flex: 0 0 auto; }

.express-variants_item-preview-img {
  width: 100%;
  height: auto; }
