.projects_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 1.5vw;
  &.-admin {
    grid-template-columns: repeat(auto-fill, minmax(314px, 1fr)); } }

.projects_item {
  &.-stub {
    opacity: 0.5; }
  &.-copying {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0.1;
      border-radius: 14px; } } }

.projects_controls-grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  &.-gap-md {
    gap: 30px; } }

.projects_controls-grid-item {
  flex: 0 0 auto;
  &.-v_center {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    min-height: 38px;
    border-bottom: 1px SOLID; }
  &.-auto_margin {
    margin: auto 0 auto auto; } }

.projects_item-inner {
  height: 100%; }

.projects_pagination {
  .pagination {
    margin: 0; } }

.filters_info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  white-space: pre;
  color: #6F7171;
  font-weight: normal; }

.filters_clear {
  display: flex;
  align-items: center;
  margin-left: 8px; }

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 25px 0 0;
  align-items: center;
  align-content: center; }

.filters_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 38px;
  background: #FFF;
  border-radius: 20px;
  border: 1px solid;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: #E3EAED; }
  &:disabled {
    opacity: 50%;
    color: $color-gray; } }

.row-plate-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0 0;
  align-items: center;
  align-content: center;

  .mode-buttons {
    display: flex;
    background: #E7EEF1;
    border-radius: 20px;
    .icon {
      margin-right: 7px; }
    input {
      display: none; }
    label {
      user-select: none;
      cursor: pointer;
      color: #787F93;
      padding: 5px 18px;
      border-radius: 20px;
      &.-active {
        background: $color-primary;
        color: #fff; } } } }
