.barchart {
  width: 100%; }

.barchart_container {
  width: $overview-col-width;
  height: $overview-col-height;
  position: relative; }

.barchart_bar {
  position: absolute;
  width: 32px;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  &.-color-top_20 {
    background-color: $color-top_20; }
  &.-color-top_40 {
    background-color: $color-top_40; }
  &.-color-middle_20 {
    background-color: $color-middle_20; }
  &.-color-bottom_40 {
    background-color: $color-bottom_40; }
  &.-color-bottom_20 {
    background-color: $color-bottom_20; }
  &.-color-par {
    background-color: $color-neutral; } }

.barchart_bar-text {
  width: 100%;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  padding: 2px 0;
  letter-spacing: -1px;
  font-weight: bold;
  &.-small-bar {
    color: #000000;
    position: absolute;
    bottom: 100%; } }
