.heatmaps {
  &.-grid {
    display: flex;
    justify-content: space-between; } }

.heatmaps_item {
  flex: 1 1 50%;
  & + & {
    margin-left: 40px; }
  p {
    margin: 15px 0; } }

.heatmaps_title {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 15px;
  text-align: left;
  span {
    display: inline-block; } }
