// Button overrides
button.icon-link {
  //color: inherit
  //transition: color .3s, opacity .3s
  //cursor: pointer
  //font-weight: 600
  @include reset-button-style; }

// General styles
.icon-link, button.icon-link {
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  display: inline-block;
  color: $color-link;
  font-weight: 500;

  &.-keyword-popup-back,
  &.-modal-back {
    margin-right: 10px; }

  &.-small {
    font-size: 12px; }

  &.-square {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 20px; }

  &.-disabled {
    font-weight: 400;
    opacity: 1;
    filter: grayscale(0);
    color: $color-disabled;
    pointer-events: none; }

  //&.-no-text
  //&.-loading
  //  @include icon-link-no-text-loader-loading

  &.-gray {
    color: #BBD1D6;

    &:hover,
    &:focus,
    &:active {
      color: #9CAFB4; } }

  &.-white {
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      opacity: .8; } }

  &.-danger {
    color: $color-danger;

    &:hover,
    &:focus,
    &:active {
      color: $color-danger; } }

  & + & {
    margin-left: 2px; }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $color-link-hover; }

  &.-flex {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between; } }

.icon-link_icon {
  vertical-align: middle;
  margin-top: -2px;

  .-disabled & {
    color: $color-disabled; }

  .-can-wrap & {
    vertical-align: top;

    &.-svg-location {
      margin-top: 0; } } }

.icon-link_text {
  display: inline-block;

  &.-txt-overflow {
    vertical-align: middle;
    padding-right: 30px;
    margin-top: -1px;
    margin-bottom: 1px; }

  .-no-text & {
    display: none; }

  .-can-wrap & {
    white-space: normal; } }

.icon-link_text + .icon-link_icon,
.icon-link_icon + .icon-link_text {
  margin-left: 8px; }
