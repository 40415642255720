html, body {
  min-width: $width-body; }

.layout-grid {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100%; }

.layout-grid_item {
  flex: 0 0 auto;
  min-width: 0;
  &.-grow {
    flex: 1 1 auto; }
  &.-navigation {
    background-color: #fff; }
  &.mw200 {
    min-width: 200px; } }

.layout-screenshots {
  display: inline-block;
  height: auto;
  width: auto;
  min-width: auto;
  min-height: auto; }
