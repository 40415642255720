.brand-options {
  font-size: 15px;
  max-width: 650px;
  margin: auto;
  @include desktop {
    &.-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: stretch; } } }

.brand-options_list {
  margin-left: 25px;
  padding: 0;
  list-style: none; }

.brand-option-list-inner {
  margin-bottom: 10px; }
