.hover-hide-tool {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  cursor: default;
  &:not(:first-child) {
    margin-left: 8px; }
  &.-force-margin {
    margin-left: 5px; }
  &.-error {
    margin-left: 4px;
    &:hover {
      .info-tooltip_control {
        background-color: $color-danger;
        opacity: 0.7; } }
    .info-tooltip_control {
      background-color: $color-danger; } }
  &.-range {
    display: flex;
    flex-direction: row; }

  .info-tooltip_drop {
    opacity: 0;
    visibility: hidden; }

  .hover-hide-text {
    &:hover + .info-tooltip_drop {
      opacity: 1;
      visibility: visible; } } }
