.custom-panel-fields + .custom-panel-fields {
  margin-top: 15px; }

.custom-panel-fields {
  max-width: 600px;
  .subtitle {
    color: $color-payment-form-label;
    font-size: 11px;
    font-weight: bold;
    &.-bigger {
      font-size: 13px; } }
  &.-no-max-limit {
    max-width: none; } }

.subtitle-wrapper {
  .info-tooltip {
    margin-left: 0;
    &.-force-margin {
      margin-left: 5px; } } }

.input-with-subtitle {
  input {
    &.-error {
      border-color: $color-danger; } }
  .readonly-input {
    .grid {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: space-between; }
    .grid-item {
      &:first-child {
        margin-right: 5px; } } } }

.provider-settings-save {
  margin-top: 25px;
  .info-tooltip {
    margin-left: 0; } }

.buttons-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
.button-grid-item + .button-grid-item {
  margin-left: 10px; }
