.header {
  width: 100%;
  &.-with-button {
    padding-right: 170px; } }

.header_title {
  color: $color-text;
  max-height: 65px;
  @include line-clamp(2);
  em {
    font-weight: bold;
    margin-left: 5px;
    font-style: normal;
    color: $color-gray; } }

.header_grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.-first-button {
    justify-content: flex-start; } }

.header_grid-item {
  flex: 0 0 auto;
  min-width: 0;
  & + & {
    margin-left: 20px; }
  &.-grow {
    flex: 1 1 auto; }
  &.-top-pad {
    padding-top: 26px; }
  &.-gl-buttons {
    display: flex;
    align-content: center;
    align-items: center; }
  &.-margin-left {
    margin-left: 30px; }
  &.-margin-left-sm {
    margin-left: 16px; } }

.header_button {
  min-width: 120px;
  & + & {
    margin-left: 15px; } }
