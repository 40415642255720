.form-field-with-icon {
  position: relative;
  display: inline-block;
  max-width: 100%; }

.form-field-with-icon_input {
  padding-left: 38px;

  &.-icon-right {
    padding-left: 0;
    padding-right: 38px; }

  &:focus {
    + .form-field-with-icon_icon {
      color: $color-primary; } } }

.form-field-with-icon_icon {
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  transition: color .3s;
  color: $color-text;
  pointer-events: none;
  z-index: 1;
  &.-icon-right {
    left: auto;
    right: 11px; }
  &.-global-search {
    color: #dddee3; } }

.form-field-with-icon_input[disabled] + .form-field-with-icon_icon,
.form-field-with-icon_input.-disabled + .form-field-with-icon_icon {
  color: $color-disabled; }
