.status-bar {
  background: #EFF4F5;
  height: 8px;
  border-radius: 8px;
  min-width: 100px;
  max-width: 300px;
  position: relative;
  color: $color-neutral;
  &.-color-below {
    color: $color-danger; }
  &.-color-above {
    color: $color-success; }
  &.-color-top_20 {
    color: $color-top_20; }
  &.-color-top_40 {
    color: $color-top_40; }
  &.-color-middle_20 {
    color: $color-middle_20; }
  &.-color-bottom_40 {
    color: $color-bottom_40; }
  &.-color-bottom_20 {
    color: $color-bottom_20; }
  &.-color-par {
    color: $color-neutral; }
  &.-green {
    color: $color-success; }
  &.-blue {
    color: $color-primary; }
  &.-to-right {
    margin-left: auto; }
  &.-with-right-text {
    margin-right: 40px; }
  &.-with-left-text {
    margin-left: 40px; }
  &.-no-max-limit {
    max-width: none; } }

.status-bar_inner {
  height: 8px;
  border-radius: 8px;
  width: 0;
  background: currentColor;
  &.-to-right {
    margin-left: auto; } }

.status-bar_text {
  position: absolute;
  font-size: 13px;
  line-height: 8px;
  font-weight: bold;
  color: inherit;
  top: 0;
  width: 35px;
  .-with-right-text & {
    left: 100%;
    margin-left: 5px;
    text-align: right; }
  .-with-left-text & {
    right: 100%;
    margin-right: 5px; } }




