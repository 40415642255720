.form-concept {
  width: 110px; }

.form-concept_image {
  position: relative;
  width: 110px;
  height: 110px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  //background-color: #EFF4F5
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%; } }

.form-concept_delete {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background: rgba(255,255,255,0.75);
  border-radius: 0 0 0 10px;
  text-align: center; }

.form-concept_name {
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: stretch; }

.form-concept_name-field {
  outline: none;
  border: none;
  display: block;
  width: 100%;
  font-size: 12px;
  border-bottom: 1px solid transparent;
  text-overflow: ellipsis;
  resize: none;
  overflow-y: hidden;
  flex: 0 0 100%;
  padding: 5px;
  border-radius: 10px;
  min-height: 66px;
  &:focus {
    border-bottom-color: #E5EAEB; } }

.form-concept_name-text {
  font-size: 12px;
  width: 100%;
  white-space: normal;
  word-break: break-word;
  flex: 0 0 100%;
  opacity: 0; }
