.avs {
  position: relative;
  padding-right: 33%; }

.avs_data-section {
  & + & {
    margin-top: 20px; } }

.avs_data-section-title {
  font-size: 15px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 10px; }

.avs_data-section-hint {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  .avs_data-section-title + & {
    top: -10px;
    position: relative; } }

.avs_data-chart-list {
  list-style: none;
  margin: 0;
  padding: 0 40px 0 0; }

.avs_data-chart-item {
  & + & {
    margin-top: 12px; } }

.avs_data-chart {
  position: relative; }

.avs_data-chart-line {
  width: 100%;
  height: 8px;
  border-radius: 10px; }

.avs_color {
  background-color: #dddddd;
  &.-top2 {
    background-color: #00A4AD; }
  &.-top {
    background-color: #20BEE8; }
  &.-bottom2 {
    background-color: #868294; } }

.avs_data-chart-value {
  position: absolute;
  left: 100%;
  margin-left: 8px;
  text-align: left;
  color: #65C18B;
  font-size: 13px;
  line-height: 1.2;
  font-weight: bold;
  top: 50%;
  margin-top: -0.6em;
  &.-zero {
    margin-left: 0; } }


.avs_legend {
  position: absolute;
  max-width: 30%;
  right: 0;
  top: 25px; }

.avs_legend-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.avs_legend-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-top: 10px; } }

.avs_legend-circle {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  margin-right: 5px;
  flex: 0 0 auto; }

.avs_legend-value {
  font-size: 13px;
  font-weight: 400;
  opacity: 0.5;
  line-height: 1.2; }
