.products-greed {
  &.-wrapper {
    border-radius: 10px;
    background-color: $color-body;
    padding: 16px; }
  &.-with-min-width {
    min-width: 300px; }

  &-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style-type: none; }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    user-select: none;

    &:not(:last-child) {
      margin-bottom: 24px; }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between; }

    &-modal-wrapper {
      position: relative;
      width: 300px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > .tooltip {
        top: 18px;
        left: 0;
        flex-shrink: 0;
        width: 300px; } }

    &-show-modal {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      color: $color-primary;
      cursor: pointer; }

    &-title {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 17px;
      color: $color-text; }

    &-uploaded {
      position: relative; }

    &-hidden-inputs {
      position: relative;
      z-index: -1; }

    &-image-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: #E7EEF1;
      border: 1px solid $form-border-color-dark;
      border-radius: 10px;
      padding: 32px;
      margin-top: 10px;
      position: relative;
      .image-delete {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 22px;
        height: 22px;
        &:hover {
          opacity: 1; }
        &:before, &:after {
          position: absolute;
          left: 10px;
          content: ' ';
          height: 22px;
          width: 2px;
          background-color: $color-text; }
        &:before {
          transform: rotate(45deg); }
        &:after {
          transform: rotate(-45deg); } } }

    &-image-rect-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }

    &-image-rect {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid $color-body;
      background: rgba(101, 193, 139, 0.4);
      cursor: grab;
      &.-view-only {
        cursor: default; }
      &.-active {
        cursor: grabbing; }
      &.-on-hover {
        background-color: rgba(0, 174, 239, 0.5); }
      &.-rounded {
        border-radius: 4px; }
      &-name {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 300px;
        text-align: center;
        background-color: #00AEEF;
        border-radius: 100px;
        color: #fff;
        font-size: 12px;
        line-height: 8px;
        vertical-align: bottom;
        padding: 3px 4px;
        height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }

      &-delete-button {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-danger;
        width: 21px;
        height: 21px;
        padding: 0;
        cursor: pointer;
        border-radius: 100%;
        border: 1px solid #fff;
        &:before, &:after {
          content: '';
          display: block;
          height: 1px;
          width: 9px;
          background-color: #fff;
          position: absolute;
          left: 5px;
          top: 9px; }
        &:before {
          transform: rotate(45deg); }
        &:after {
          transform: rotate(-45deg); }
        &.-bottom-left {
          transform: translate(-10px, -11px); } }

      &-point {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        border-width: 0;
        background-color: transparent;
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: crosshair;
        &.-rounded {
          &::before {
            border-radius: 2px; } }
        &.-on-hover {
          &::before {
            border-color: #00AEEF; } }
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          z-index: 1;
          top: 0;
          left: 0;
          border-top: 4px solid #65C18B;
          border-left: 4px solid #65C18B;
          transition: width 0.2s ease-in, height 0.2s ease-in;

          &::before {
            width: 12px;
            height: 12px; } }

        &.-top-left {
          transform: translate(0, 0) rotateZ(0); }

        &.-top-right {
          transform: translate(-10px, 0) rotateZ(90deg); }

        &.-bottom-right {
          transform: translate(-10px, -10px) rotateZ(180deg); }

        &.-bottom-left {
          transform: translate(0, -10px) rotateZ(270deg); } } }

    &-add-image-tip {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $color-text; }

    &-actions {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      align-items: flex-start;
      .upload-button-with-error-message {
        color: $color-danger;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }

    &-button {
      border-radius: 50px;
      border-width: 0;
      padding: 9px 20px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $color-body;
      background-color: #E7EEF1;
      font-family: Arial;
      font-style: normal;
      font-weight: bold;
      cursor: pointer;

      & + & {
        margin-left: 8px; }

      &.-primary {
        background-color: #00AEEF; }

      &.-action {
        background-color: #FF6900; } } } }
