.form-header {
  margin-bottom: 30px; }

.form-header_grid {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.form-header_grid-item {
  flex: 0 0 auto;
  & + & {
    margin-left: 20px; }
  &.-grow {
    flex: 1 1 auto; } }

.form-header_text {
  font-size: 15px; }

.form-header_button {
  min-width: 140px; }
