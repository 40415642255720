.modal-how-it-works {
  .modal-content {
    padding-bottom: 40px; } }

.shelf-how-it-works {
  a {
    font-size: 16px;
    font-weight: bold; } }

.shelf-how-it-works-step {
  .image-section {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto; } }
  .text-section {
    margin-top: 20px; } }
