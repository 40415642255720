.font-selection {
  font-size: 14px;
  text-align: center; }

.font-selection_controls {
  padding-bottom: 10px;
  padding-top: 10px; }

.font-selection_description {
  text-align: center;
  font-size: 16px;
  max-width: 350px;
  color: #413C58;
  margin: auto;
  padding-top: 15px; }

.font-selection_footer {
  text-align: center;
  padding-top: 25px; }

.font-selection_footer-item {
  & + & {
    padding-top: 25px; } }
