$table-width: 800px;
$first-col-width: 100px;

.table-concept-overview {
  table-layout: auto;
  min-width: $table-width;
  &.-stick-header {
    th {
      z-index: 11; } }
  .concept-preview_thumb {
   width: 52px;
   height: 52px; }

  @for $i from 1 through 1000 {
    &.concepts-number-#{$i} {
      th.-with-concept {
        width: calc(100% / #{$i}); } } }

  .table-comparison_concept {
    padding: 0;
    width: $overview-col-width;
    // for prevent show popover full-size image
    //pointer-events: none
    & > div {
      width: 52px;
      margin-left: auto;
      margin-right: auto;
      text-decoration: none; } }

  & > thead {
    & > tr {
      & > th {
        &, &:first-child, &:last-child {
          padding: 0 0 8px; }
        &.-with-concept {
          vertical-align: bottom;
          width: 100px;
          min-width: 100px;
          text-align: center;
          &:last-child {
            width: 100%; } }
        &.-empty {
          width: 0; } } } }
  & > tbody {
    & > tr {
      & > td {
        text-align: left;
        vertical-align: top;
        border: none;
        &, &:first-child, &:last-child {
          padding: 3px 0; }
        &.table-concept-overview_td-chart {
          & > div {
            background-color: #EFF4F5;
            padding-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: center; }
          &.-first {
            & > div {
              border-radius: 10px 0 0 10px; } }
          &.-last {
            & > div {
              border-radius: 0 10px 10px 0; } } }
        &.table-concept-overview_td-title {
          color: #413C58;
          line-height: 1.3;
          width: $first-col-width;
          min-width: $first-col-width;
          & > span {
            max-height: $overview-col-height;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            display: -webkit-box;
            -webkit-box-orient: vertical; } } }
      &.-divider {
        & > td {
          border-top: 1px solid #E5E5E5; } } } } }

.table-concept-overview_avg-col {
  width: 0;
  min-width: 0 !important;
  vertical-align: bottom !important;
  position: sticky !important;
  left: $first-col-width;
  background-color: #fff;
  z-index: 10;
  //fix for show average circle
  border-radius: 0 3px 0 0; }

.table-concept-overview_avg-inner {
  height: $overview-col-height;
  width: 50px;
  position: relative; }

.table-concept-overview_avg-value {
  position: absolute;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: #fff;
  border: 2px solid #B2C0C2;
  border-radius: 100%;
  margin-bottom: -4px;
  margin-right: -5px;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: 0;
    width: calc(100vw - 200px - 60px - 80px - 150px - 20px);
    //1280 - max-width for lg modal
    // 200 - sidebar width, 60 - outer modal paddings, 80 - inner modal paddins
    // 150 - sum width of left cols, 5 - half of width circle
    max-width: calc(1280px - 60px - 80px - 150px - 20px);
    min-width: calc(#{$table-width} - 150px - 20px);
    border-top: 1px dashed #B2C0C2;
    z-index: 1; } }

.table-concept-overview_avg-value-text {
  position: absolute;
  right: 100%;
  bottom: 100%;
  color: #1F2542;
  font-size: 13px;
  line-height: 1.2;
  font-weight: bold;
  padding-right: 5px;
  &.-big {
    top: 0;
    bottom: auto;
    margin-top: -7px; } }

.table-concept-overview_td-inner {
  width: $overview-col-width;
  text-align: center; }

