.stimuli-preview {
  h3 {
    margin: 25px 0; }
  .cit-concept {
    margin: 20px 0 0;
    width: 100%;
    max-width: 600px;
    border-color: $va-black;
    background-color: $va-white;
    font-family: Arial Regular, Arial, sans-serif;
    &.-with-650 {
      max-width: 650px; }
    h2 {
      font-family: inherit;
      font-weight: normal; } } }

