// Dropdown

.dropdown {
  display: inline-block;
  position: relative;
  outline: none !important;

  &.-right-sided {
    text-align: right; }
  &.-no-min-width {
    min-width: 0;
    width: auto; }
  &.-wide {
    display: block;

    .dropdown_toggle {
      width: 100%; } }

  &.-not-wide {
    width: auto !important; }

  &.-sidebar {
    // for more easy movement coursor to dropdown
    padding-right: 20px; }

  &.-global-search {
    display: block; }

  &.-cell-popup {
    display: block;
    height: 0;
    position: static;
    cursor: default; }

  &.-on-hover {
    .dropdown_container {
      z-index: -1;
      transition: opacity 0.3s, visibility 0.3s, z-index 0.3s; }
    &:hover {
      .dropdown_container {
        @include dropdown-show; } }
    &.-hovered {
      .dropdown_container {
        @include dropdown-show; } } }

  .dropdown_container {
    visibility: hidden;
    opacity: 0;
    overflow: hidden; } }

.-cell-with-popup:not(.-no-hover):hover,
.-cell-with-popup.-active {
  > .dropdown.-cell-popup {
    .dropdown_container {
      @include dropdown-show; } } }

// Dropdown inset

.dropdown_inset {
  max-height: 382px;
  overflow-y: auto;
  overflow-x: hidden;
  //min-width: $min-width-dropdown //!!!
  //max-width: 280px //!!!
  border-radius: 10px;
  &.-no-max-width {
    max-width: none; }
  .dropdown .-no-scroll & {
    max-height: none;
    overflow: visible; }
  .-select & {
    max-width: 100%; }
  .-global-search & {
    max-width: 100%;
 }    //max-height: calc(100vh - #{$height-masthead})
  .-cell-popup & {
    max-height: none; }
  .-sidebar-popup & {
    max-height: 324px;
    min-height: 120px;
    min-width: 370px; } }

// Dropdown container

.dropdown_container {
  text-align: left;
  box-shadow: $dropdown-shadow;
  min-width: calc(100% + 36px); //!!! 36px = 18x2; 48px - hack for Safari
  .-select & {
    max-width: 100%; }
  .-global-search & {
    max-width: 100%;
    box-shadow: inset 0 1px 0 0 $color-border, 0 5px 10px 0 rgba(0,0,0,.15); }
  //&.-no-max-width //!!!
  //.dropdown_inset
 }  //  max-width: none

// Dropdown positioning

//Available combinations: .-to-bottom (default), .-to-bottom.-corner-center, .-to-bottom.-corner-right
//                        .-to-top, .-to-top.-corner-center, .-to-top.-corner-right
//                        .-to-left, .-to-left.-corner-middle, .-to-left.-corner-bottom
//                        .-to-right, .-to-right.-corner-middle, .-to-right.-corner-bottom
//                        .-corner-left.-to-bottom is default

@mixin  corner-off {
  &:before {
    display: none; } }

@mixin  corner-on {
  &:before {
    display: block; } }

@mixin  corner-center {
  left: 50%;
  transform: translateX(-50%);
  &:before {
    left: 50%;
    margin-left: -5px; } }

@mixin  corner-right {
  left: auto;
  right: -18px;
  &.-cell-popup {
    right: 0; }
  &.-no-offset {
    right: 0; }
  &:before {
    left: auto;
    right: 23px; } }

@mixin  to-bottom {
  top: 100%;
  bottom: auto;
  margin: 10px 0 0;
  &.-select,
  &.-global-search {
    margin-top: 0; }
  &.-cell-popup {
    margin-top: 0; }
  &:before {
    top: -5px;
    bottom: auto;
    border-bottom: solid 5px $dropdown-light-bg;
    border-top: 0; } }

@mixin  to-top {
  top: auto;
  bottom: 100%;
  margin: 0 0 10px;
  &.-select {
    margin-bottom: 0; }
  &.-cell-popup {
    margin-bottom: 0; }
  &:before {
    top: auto;
    bottom: -5px;
    border-bottom: 0;
    border-top: solid 5px $dropdown-light-bg; } }

@mixin  to-right {
  top: -23px;
  left: 100%;
  margin: 0 0 0 10px;
  &.-cell-popup {
    top: 0;
    margin: 0; }
  &.-no-offset {
    top: 0; }
  &:before {
    top: 23px;
    left: -7px;
    border-top: solid 7px transparent;
    border-bottom: solid 7px transparent;
    border-left: 0;
    border-right: solid 7px $dropdown-light-bg; }
  &.-sidebar-popup {
    margin-left: -4px;
    //&:before
 } }    //top: 54px

@mixin  to-right_corner-middle {
  top: 50%;
  transform: translateY(-50%);
  &:before {
    top: 50%;
    margin-top: -7px; } }

@mixin  to-right_corner-bottom {
  top: auto;
  bottom: -20px;
  &.-cell-popup {
    bottom: 0; }
  &.-no-offset {
    bottom: 0; }
  &:before {
    top: auto;
    bottom: 23px; }
  //&.-sidebar-popup
  //  &:before
 }  //    bottom: 54px

@mixin  to-left {
  top: -23px;
  left: auto;
  right: 100%;
  margin: 0 10px 0 0;
  &.-cell-popup {
    top: 0;
    margin: 0; }
  &.-no-offset {
    top: 0; }
  &:before {
    top: 23px;
    right: -7px;
    left: auto;
    border-top: solid 7px transparent;
    border-bottom: solid 7px transparent;
    border-left: solid 7px $dropdown-light-bg;
    border-right: 0; } }

@mixin  to-left_corner-middle {
  top: 50%;
  transform: translateY(-50%);
  &:before {
    top: 50%;
    margin-top: -7px; } }

@mixin  to-left_corner-bottom {
  top: auto;
  bottom: -20px;
  &.-cell-popup {
    bottom: 0; }
  &.-no-offset {
    bottom: 0; }
  &:before {
    top: auto;
    bottom: 23px; } }

.dropdown,
.dropdown-tether {
  .dropdown_container {
    position: absolute;
    top: 100%;
    left: -18px;
    //min-width: calc(100% + 36px) //!!!
    background: rgba($dropdown-light-bg,.99);
    border-radius: 10px;
    padding: 0;
    z-index: 9999;
    box-sizing: border-box;
    margin: 10px 0 0;
    @include corner-off;
    @include mobile {
      &.-center-mobile {
        left: 50%;
        transform: translateX(-50%); }
      &.-collage-tool {
        margin-top: -5px; } }

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      top: -5px;
      left: 23px;
      width: 0;
      height: 0;
      border-bottom: solid 5px $dropdown-light-bg;
      border-left: solid 5px transparent;
      border-right: solid 5px transparent; }

    &.-select,
    &.-global-search {
      margin-top: 0; }

    &.-global-search {
      //position: fixed
      //top: $height-masthead + 2px
      //left: $width-sidebar
      //right: 0
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      min-width: 0;
      max-width: none;
      background: $color-body;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: 8px;
        background: #fff; }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: 2px;
        background: $color-body; }

      //+breakpoint-hi($screen-xl + 1px)
 }      //max-width: $width-container

    &.-cell-popup {
      margin-top: 0;
      left: 0; }

    &.-no-offset {
      left: 0; }

    &.-corner-on {
      @include corner-on; }

    &.-corner-center {
      @include corner-center; }

    &.-corner-right {
      @include corner-right; }

    &.-to-top {
      @include to-top; }

    &.-to-right {
      @include to-right;
      &.-corner-middle {
        @include to-right_corner-middle; }
      &.-corner-bottom {
        @include to-right_corner-bottom; } }

    &.-to-left {
      @include to-left;
      &.-corner-middle {
        @include to-left_corner-middle; }
      &.-corner-bottom {
        @include to-left_corner-bottom; } }
    &.-dropdown-limit {
      max-width: 600px; }

    &.-to-right-bottom {
      left: auto;
      right: 0; }

    &.-to-right-top {
      left: auto;
      right: 0;
      top: auto;
      bottom: 100%;
      margin: 0 0 10px; }

    &.-to-right-top-offset {
      left: auto;
      right: 0;
      top: auto;
      bottom: -300%;
      margin: 0 0 10px; } } }

@for $i from 140 through 300 {
  .dropdown-tether.-min-width-#{$i} > .dropdown_container,
  .dropdown.-min-width-#{$i} > .dropdown_container {
    min-width: #{$i}px; }
  $i: $i + 10; }

// Dropdown toggle

.dropdown_toggle-text {
  display: inline-block;
  vertical-align: top;
  &.-placeholder {
    color: $color-placeholder;
    .-disabled & {
      color: $color-disabled; }
    .-invalid & {
      color: $color-danger; } } }

.dropdown_toggle-label {
  display: inline-block;
  vertical-align: bottom;
  color: $color-text; }

.dropdown_toggle-icon {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 10px;
  backface-visibility: hidden;
  &.-in-button {
    margin-right: -6px; } }

// Dropdown toggle link

.dropdown_toggle,
a.dropdown_toggle {
  text-decoration: none;
  cursor: pointer;

  &:hover {}
  &:hover {
    text-decoration: none; } }

.-btn-dropdown-toggle,
a.-btn-dropdown-toggle {
  display: inline-block;
  vertical-align: middle;
  color: $color-text;
  //font-weight: 600
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  min-width: 0;
  min-height: 18px; //15px
  font-size: 14px;
  line-height: 18px; //15px
  box-shadow: none !important;
  position: relative;
  padding-right: 24px;
  user-select: none;
  .-white & {
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      color: rgba(255,255,255,.8); } }

  &.-gray {
    color: $color-gray; }
  &.-btn {
    padding-right: 0; }
  &.-disabled {
    pointer-events: none;
    span {
      &:after {
        opacity: .6; } } }
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $color-link-hover; }
  .dropdown_toggle-icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin: -3px 7px 0; }
  &.-to-right {
    .dropdown_toggle-icon {
      transform: rotate(-90deg); } }
  &.-to-left {
    .dropdown_toggle-icon {
      transform: rotate(90deg); } }
  &.-inherit {
    font-size: inherit;
    line-height: inherit; } }

// Dropdown toggle select

.-select-dropdown-toggle {
  cursor: pointer;
  user-select: none;
  .dropdown_toggle-text {
    width: calc(100% - 10px);
    @include truncate; } }

// Open state

.dropdown.-open,
.dropdown.open,
.dropdown-popup:hover {
  > .dropdown_container {
    z-index: 999;
    @include dropdown-show; } }
.dropdown.-open,
.dropdown.open {
  .dropdown_toggle,
  .-btn-dropdown-toggle,
  .-btn-dropdown-toggle[role="link"],
  .-btn-dropdown-toggle[role="button"],
  a.-btn-dropdown-toggle,
  .-select-dropdown-toggle {
    .dropdown_toggle-icon {
      transform: rotate(180deg); }
    &.-to-right {
      .dropdown_toggle-icon {
        transform: rotate(90deg); } }
    &.-to-left {
      .dropdown_toggle-icon {
        transform: rotate(-90deg); } } }
  .-select-dropdown-toggle {
    border-color: $color-primary; } }

// Dropdown absolute positioned

//Apply alongside .dropdown class
.dropdown-absolute {
  position: absolute;
  pointer-events: none;
  //z-index: $zindex-modal + 110 !important
  .dropdown_container {
    pointer-events: auto; } }

.dropdown-fixed {
  position: fixed;
  pointer-events: none;
  //z-index: $zindex-modal + 110 !important
  .dropdown_container {
    pointer-events: auto; } }

// In Form Dropdown

.dropdown.-in-form {
  margin: 6px 0 11px; //5px 0 12px
  max-width: 100%;
  .dropdown_toggle {
    max-width: 100%; }
  .dropdown_toggle-text {
    width: 100%;
    @include truncate; } }

// Row of dropdowns

.dropdown.-in-row {
  margin: 0 30px 12px 0; }

// Inline Dropdown

//.dropdown.-inline.-in-form
//.-select-dropdown-toggle.form-control
//  border-bottom: solid 2px transparent !important
//  padding-top: 2px

//.dropdown.-inline
//width: auto !important
//max-width: calc(100% - 2px)
//margin: 0 8px 0 0
//.dropdown
//  max-width: 100%
//.-select-dropdown-toggle.form-control
//  border: none
//  width: auto
//  max-width: 100%
//  font-size: 14px
//  padding-top: 3px
//  white-space: nowrap
//  .dropdown_toggle-text
//    width: auto
//    max-width: calc(100% - 9px) // -9px - fix for Safari, 'cause it crops  text anyway, even with -10px value
//    &:empty
//      &:before
//        content: "Select Item"
//        color: $input-placeholder-color
//&.-disabled
//  .-select-dropdown-toggle.form-control
//    .dropdown_toggle-text:empty:before
//      color: $color-inactive
//&.-open
//  .-select-dropdown-toggle.form-control
//    .dropdown_toggle-text:empty
//      border-color: $color-ui-highlight

//.dropdown.-inline
//.dropdown_toggle-text
//  margin-right: 5px // Strange hack for the case when .-inline select is made with barebone markup (not select2)

// Disabled Dropdown

.dropdown.-disabled {
  pointer-events: none;
  opacity: 1;
  .dropdown_toggle {
    color: $color-placeholder;
    &.form-control {
      color: $color-disabled;
      border-color: $color-disabled; } }
  //.dropdown_toggle-icon
 }  //opacity: .4

// Dropdown content

.dropdown_content {
  position: relative;
  min-height: 50px;

  .-global-search & {
    min-height: 81px; } }


// Download Dropdown
ul.reports-list {
  background-color: $bg-chosen-form-gray;
  margin: 0;
  min-width: 420px;
  padding: 0 25px;
  li {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    span {
      align-self: center;
      display: flex;
      flex: auto;
      &:first-child {
        font-size: 15px;
        padding-right: 10px; }
      &:last-child {
        flex-direction: row-reverse; }
      a.-export {
        margin: 0 10px 0 0 !important;
        width: auto !important;
        padding: 5px 15px;
        border-radius: 25px;
        font-size: 12px;
        font-weight: bold;
        &.-ppt {
          color: #fff;
          background-color: $dropdown-ppt-color; }
        &.-xlsx {
          color: #fff;
          background-color: $dropdown-xlsx-color; }
        &.-google {
          color: #fff;
          background-color: $dropdown-xlsx-color; } } }
    &.-notice {
      border-top: none;
      padding-top: 0;
      font-size: 10px; }

    border-top: 1px solid $color-line-light;
    &:first-child {
      border-top: none; } } }
