$btn-colors: (default: $va-stone, warning: $va-red, success: $va-green, branded: $va-coral);

@mixin  button-variant($color) {
  background-color: $color;
  &:hover {
    background-color: $color; }
  &:active,
  &:focus {
    background-color: darken($color, 10%); }
  &.-bordered, &.-va-bordered {
    background: none;
    color: $color;
    box-shadow: inset 0 0 0 1px $color;
    &:hover {
      background-color: rgba($color, 0.1); }
    &:active, &:focus {
      background-color: rgba($color, 0.2); } } }


@mixin  button-filter-variant($color) {
  border-color: $color;
  &:hover {
    background-color: $color; }
  &:active,
  &:focus {
    border-color: darken($color, 10%);
    background-color: darken($color, 10%); }
  &.-selected {
    background-color: $color; } }

@mixin  button-override {
  background-color: $va-black;
  color: $va-white;
  transition: background-color 0.15s, outline 0.15s;
  &:hover:not([disabled]) {
    background-color: $va-black-hover; }
  // if need to add focus state (maybe later when keyboard navigation will be added)
  //&:focus, &:focus-visible
  //&:not([disabled])
  //  outline: 2px solid $va-black
  //  outline-offset: 2px
  &.-secondary {
    background-color: $va-gray-light;
    color: $va-black;
    &:hover:not([disabled]) {
      background-color: $va-gray-medium; } }
  &.-default {
    color: $va-black; }
  &.-square {
    width: 37px;
    height: 37px;
    text-align: center;
    border-radius: 0;
    padding: 10px; }

  &.-bordered, &.-va-bordered {
    background: none;
    color: $va-black;
    box-shadow: inset 0 0 0 1px $va-black;
    &:hover:not([disabled]) {
      background: none; } }
  &.-bordered-white {
    background: $va-white;
    color: $va-black;
    box-shadow: inset 0 0 0 1px $va-black;
    &:hover:not([disabled]) {
      background: $va-gray-light; } }
  &.-white {
    color: #747776;
    background: $va-white;
    &:hover:not([disabled]) {
      cursor: pointer;
      background: $va-white; } }
  &.-nav-secondary {
    background-color: $va-white;
    color: $va-black;
    &:hover:not([disabled]) {
      background-color: $va-white; } }
  &.-delete-color {
    color: $va-white;
    background-color: #000;
    .icon.-i-delete {
      color: $va-brand; }
    &:hover:not([disabled]) {
      background-color: $va-black-hover; } }
  &.-va-delete {
    color: $va-white;
    background-color: #000;
    .icon.-i-delete {
      color: $va-brand; }
    &:hover:not([disabled]) {
      background-color: $va-black-hover; } }
  &.-vcc-delete {
    color: $va-white;
    background-color: #FF3C2D; }
  @each $color, $value in $btn-colors {
    &.-#{$color} {
      @include button-variant($value); } } }


@mixin  form-field-override {
  border-color: $va-border;
  font-size: 15px;
  &:focus {
    border-color: $va-black;
    outline: 1px solid $va-black; }
  &:disabled {
    border-color: $va-border;
    background-color: $va-gray-light;
    color: $va-black;
    opacity: 0.4;
    pointer-events: none; } }

@mixin  checkbox-override {
  .checkbox_label {
    font-size: 15px;
    line-height: 1.25;
    color: $va-black;
    padding-left: 27px;
    &.-for-additional-answer, &.-for-non-answer, &.for-any {
      padding: 0; }
    &.-group-header {
      color: $va-gray-dark;
      text-transform: uppercase; }
    &:before {
      box-shadow: inset 0 0 0 2px $va-black;
      background-color: transparent;
      top: -0.05em; }
    &:after {
      border-left-color: $va-black;
      border-bottom-color: $va-black;
      top: 0.1em; }
    &.concept-selection {
      &.-selected {
        &:before {
          background-color: #000; } }
      &:after {
        border-color: #fff; } } } }


@mixin  radio-override {
  .radio_label {
    font-size: 15px;
    &:before {
      box-shadow: inset 0 0 0 2px $va-black; }
    &:hover {
      &:before {
        box-shadow: inset 0 0 0 2px $va-black-hover; } }
    &.-checked {
      &:before {
        box-shadow: inset 0 0 0 8px $va-black; } } }
  input:checked + .radio_label {
    &:before {
      box-shadow: inset 0 0 0 8px $va-black; }
    &:hover {
      &:before {
        box-shadow: inset 0 0 0 8px $va-black-hover; } } } }

@mixin  radio-tabs-override {
  background-color: $va-gray-light;
  box-shadow: none;
  height: 40px;
  border: none;
  &.-white-bg {
    background-color: $va-white; }
  &.-gray-bg {
    background-color: $va-gray-light-2; }
  &.-bordered {
    border: 2px solid transparent;
    .radio-tabs_label {
      line-height: 1.3; } }
  &.-in-table {
    &.-items-1 {
      max-width: 200px; }
    &.-items-2 {
      max-width: 400px; }
    &.-items-3 {
      max-width: 600px; }
    .radio-tabs_label {
      min-width: 120px;
      padding-left: 15px;
      padding-right: 15px; } }
  &.bsg-selector {
    .radio-tabs_label {
      min-width: 100px; } }

  .radio-tabs_label {
    color: $va-black;
    line-height: 1.5;
    @include font-bold;
    outline: none;
    min-width: 0;
    padding: 10px;
    transition: background-color 0.15s, color 0.15s;
    &.-width-1-2 {
      min-width: 50%; }
    &.-header {
      min-width: 124px;
      padding-left: 20px;
      padding-right: 20px; }
    &.-comparison {
      padding-left: 15px;
      padding-right: 15px; }
    &:hover {
      background-color: $va-gray-medium; } }

  input:checked + .radio-tabs_label {
    background-color: $va-black;
    color: $va-white;
    &:hover {
      background-color: $va-black-hover; } }

  input:disabled + .radio-tabs_label {
    &:hover {
      background: none; } }

  input:checked:disabled + .radio-tabs_label {
    &:hover {
      background-color: $va-black;
      color: $va-white; } } }

@mixin  checkbox-multi-override {
  &.-read-only {
    opacity: 1; }

  .checkbox-multi_label {
    background-color: $va-gray-light;
    color: $va-black;
    transition: background-color 0.15s, color 0.15s;
    font-size: 13px;
    height: 34px;
    &:hover {
      background-color: $va-gray-medium; } }

  input:checked + .checkbox-multi_label {
    background-color: $va-black;
    color: $va-white;
    &:hover:not([disabled]) {
      background-color: $va-black-hover; } }

  input:disabled + .checkbox-multi_label {
    &, &:hover {
      cursor: default;
      pointer-events: none;
      opacity: 1;
      background-color: $va-gray-light;
      color: $va-gray-dark; } }

  input:disabled:checked + .checkbox-multi_label {
    &, &:hover {
      cursor: default;
      opacity: 1;
      background-color: $va-gray-dark;
      color: $va-white; } } }


@mixin  toggle-override {
  .form-switch {
    background-color: $va-gray-medium !important;
    &.-disabled {
      opacity: 0.4; }
    &:before {
      background-color: $va-border; } }
  input:checked + .form-switch:before {
    background-color: $va-black; } }

.verizon-modifier {
  font-family: $va-font-bold;
  font-weight: normal;
  .text-font {
    font-family: $va-font-normal; }
  h1, h2, h3, h4 {
    font-family: inherit; }
  a {
    color: $va-black; }
  .link {
    color: $va-brand;
    &.-blue {
      color: $va-blue; }
    &.-light-blue {
      color: $color-link; }
    &.-verizon-red-color {
      color: $va-coral; }
    &.-sm {
      font-size: 80%; }
    &.-reset {
      position: absolute;
      left: 0;
      top: 40px;
      text-decoration: underline;
      color: #6F7171; } }
  .sort-link {
    color: $va-black;
    &:hover, &:active, &:focus {
      color: $va-black; } }

  hr {
    border-top-color: $va-gray-light-2;
    margin: 20px 0; }

  .content {
    background-color: $va-white;
    color: $va-black; }
  .content_top {
    background-color: $va-white;
    color: $va-black;
    border-bottom: 1px solid $va-border; }
  .header_title {
    color: $va-black; }

  .form_plate {
    border-color: $va-gray-light-2;
    padding-top: 15px; }

  .form_plate-title {
    color: $va-black;
    font-size: 15px;
    margin-bottom: 8px;
    &.-secondary {
      color: $va-border; } }

  .form_grid.-plates {
    margin: -3px;
    & > .form_grid-item {
      padding: 3px; } }

  .form_section {
    & + .form_section {
      margin-top: 0;
      padding-top: 25px; } }

  .form_section-title {
    margin-bottom: 8px; }

  .toggle.-manage {
    font-size: 12px;
    font-family: $va-font-normal;
    span, a.quotas {
      font-size: inherit;
      font-family: inherit; } }

  .button, .-gl-button {
    @include button-override; }

  .form-field {
    @include form-field-override; }

  .has-error .form-field, .form-field.has-error {
    border-color: $va-brand;
    &:focus {
      outline-color: $va-brand; } }

  select.form-field {
    background-image: url('../assets/images/icons/i-select-gray.svg');
    background-repeat: no-repeat;
    background-position: right 13px center; }

  textarea.form-field {
    min-height: 100px; }

  .form-field-with-icon_input:focus {
    & + .form-field-with-icon_icon {
      color: $va-black; } }

  .checkbox {
    @include checkbox-override; }

  .radio {
    @include radio-override; }

  .radio-tabs {
    @include radio-tabs-override; }

  .checkbox-multi {
    @include checkbox-multi-override; }

  .toggle {
    @include toggle-override; }

  .link.-delete {
    color: $va-brand; }

  .info-tooltip_control {
    background-color: $va-black;
    cursor: pointer; }

  .info-tooltip:hover {
    .info-tooltip_control {
      background-color: $va-black-hover; } }

  .additional-screener_description {
    color: $va-border; }

  .question-list-item {
    margin: 5px 0;

    .readonly-title {
      &.-bordered {
        background-color: $va-gray-light; }
      .title-inner-list {
        font-family: $va-font-normal;
        font-size: 13px; } } }

  .normal-popup.-long-items.-editable {
    .header-item:first-child {
      width: 170px; } }

  .buttons-with-text {
    display: flex;
    align-items: center;
    .comparison-text {
      margin-left: 16px; } }


  // screening

  .form-question {
    box-shadow: none;
    &.-screening {
      border: 1px solid $va-border; } }


  .form-intro_text {
    &.-gray {
      color: $va-border; } }

  .form-intro_icon {
    color: $va-black;
    border-color: $va-black; }

  .form-question_type-title {
    color: $va-brand; }

  .form-question_type-text {
    color: $va-border;
    font-size: 14px; }

  .form_grid-item.-select-options {
    background-color: $va-gray-light; }

  .form-question_type-col:hover {
    background: $va-gray-dark; }

  .form_inline-select {
    select {
      color: $va-brand; }
    &:after {
      border-top-color: $va-brand; } }

  .form-question_grid-item.-with-custom-placeholder {
    overflow: visible;
    .custom-placeholder-label {
      color: $va-gray-placeholder;
      font-size: 9px; } }

  .form-question_answer-move {
    color: $va-black;
    &:hover {
      color: $va-black-hover; } }

  .form-group_item {
    & + .form-group_item {
      margin-top: 6px; } }

  // concept

  .form-concept_upload-btn {
    display: block;
    .icon {
      display: block; } }

  .form-flavor_image {
    width: 100px;
    height: 100px;
    background-color: $va-gray-light;
    border-radius: 10px 0 10px 10px;
    &.-no-image {
      background: none;
      height: 36px; } }

  .upload-button-processing {
    width: 100px;
    height: 100px;
    background-color: $va-gray-light;
    border-radius: 10px; }

  .form-flavor_image_delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 26px;
    border-radius: 0 0 0 12px;
    background-color: $va-black;
    color: $va-brand;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      display: block;
      margin-top: -1px; } }

  .icon.-i-no-image-current-color {
    color: $va-brand;
    background-color: $va-gray-light; }

  .upload-button-error-message {
    color: $va-brand;
    margin-right: -20px;
    display: block;
    line-height: 1.3;
    margin-top: 5px; }

  .upload-button-with-error-message {
    width: 100px;
    display: block; }

  // launch page

  .form-finish_icon {
    color: $va-black; }

  .form-pay_size-select {
    width: 300px; }

  .form-pay_size-label {
    color: $va-black;
    font-size: 15px; }

  .selected-number-message {
    font-size: 14px;
    color: $va-gray-dark; }

  .form-pay_hint {
    font-size: 14px;
    line-height: 1.3;
    span {
      color: $va-gray-dark; } }


  .form-pay_price-grid-item {
    & + .form-pay_price-grid-item {
      &:before {
        top: 0;
        bottom: auto;
        height: 88px;
        background-color: $va-gray-dark; } } }

  .launch-content {
    .radio-tabs {
      @extend .radio-tabs.-full-width; }
    .form_section {
      & > .form_grid {
        margin: 0; } }
    .form_section-tabs {
      margin: 0; }
    .custom-panel-fields {
      margin-top: 20px; }
    .comparisons-debug-info {
      background-color: $va-gray-dark; }
    .edit-info-item-external {
      border-color: $va-gray-light;
      padding: 20px; }

    .form-pay {
      padding-bottom: 60px; }

    .form-footer.-fixed {
      position: absolute;
      left: 0;
      right: 0;
      background: none;
      padding-right: 200px;
      .footer-inner {
        margin: 0 50px;
        border-top-color: $va-border;
        background: $va-white; } } }

  // tooltips

  .info-tooltip_drop {
    &.-va-top-center-far {
      bottom: 32px;
      top: inherit;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 0;
      padding-top: 7px; } }

  // loading
  .form-upload.-in-progress {
    background-color: $va-gray-light-2; }

  .page-loader {
    filter: grayscale(1); }


  // wysiwyg

  .rdw-editor-toolbar {
    border-color: $va-border; }
  .draft-editor-custom {
    border-color: $va-border;
    ul, ol {
      display: block; } }
  .public-DraftEditorPlaceholder-root {
    color: $va-gray-placeholder; }
  .wysiwyg-textarea {
    color: $va-black;
    font-family: $va-font-normal; }
  .has-error .wysiwyg-textarea {
    border-color: $va-brand; }
  .form_grid-item.has-error {
    border-color: $va-brand; }

  //navigation

  &.navigation {
    background-color: $va-gray-light;
    border-left: none; }

  .navigation_steps-item {
    color: $va-border;
    &.-active {
      color: $va-black; }
    & + .navigation_steps-item {
      margin-top: 12px; } }

  .navigation_steps-link, .navigation_steps-text {
    color: inherit;
    font-size: 13px; }

  .navigation_steps-item-num {
    font-family: $va-font-normal;
    font-size: 12px;
    top: -1px; }

  .navigation_steps-item-icon {
    color: $va-brand; }

  .express-intro_item-icon {
    filter: grayscale(1); }

  // backdrop for modal
  &.modal-backdrop {
    background-color: $va-gray-light; }

  &.layout-grid {
    position: relative;
    min-width: 900px; }

  &.content {
    background-color: $va-white;
    color: $va-black; }

  // hover image in popup
  &.comparison-image-popup {
    .tooltip-inner {
      max-width: 450px;
      padding: 15px;
      img {
        max-width: 100%;
        max-height: 450px;
        object-fit: contain; } } }

  // review page
  .review-respondents_plate-footer {
    background-color: $va-stone; }

  .review-respondents_openends-title {
    @include font-bold;
    color: $va-gray-text; }

  .review-respondents_openends-text {
    color: $va-black;
    @include font-normal; }

  .review-respondents_plate {
    border-color: $va-border;
    &.-blocked {
      border-color: $va-red; }
    &.-reviewed {
      border-color: $va-green; } }

  .review-respondents_plate-footer-text {
    @include font-normal; }

  .paste-detected {
    background: $va-green; }

  .button-filter {
    @each $color, $value in $btn-colors {
      &.-#{$color} {
        @include button-filter-variant($value); } }
    &.-default {
      &.-selected, &:hover {
        color: $va-black; } } }

  // projects page
  .projects_controls {
    padding-top: 24px;
    &.-sticky {
      position: sticky;
      top: $page-header-height;
      background-color: $va-white;
      z-index: $va-sticky-zi + 1; } }

  .projects_pagination {
    margin: 0;
    .page-link {
      color: $va-black;
      border-color: $va-black;
      @include font-bold;
      &:hover, &:active, &:focus {
        color: $va-black;
        background-color: $va-gray-light; } }
    .page-item.active {
      .page-link {
        background-color:  $va-black;
        border-color: $va-black;
        color: $va-white; } } } }

// sidebar
.sidebar {
  &.-verizon {
    .sidebar_nav-link {
      line-height: 1.1;
      color: $va-border;
      &:hover, &:active, &:focus, &.-active {
        color: $va-white; } }
    .sidebar_nav-link-icon {
      top: 0; }
    a {
      &, &.icon-link {
        color: $va-black;
        &:hover, &:active, &:focus {
          color: $va-black; } } } } }

