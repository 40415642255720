.nps_graph {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%; }

.nps_title {
  font-size: 15px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 12px; }

.nps_data {
  flex: 1 1 auto;
  // min value (for case when this block is not near another column)
  height: 170px; }

.nps_data-list {
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  overflow: hidden; }

.nps_data-item {
  text-align: center;
  position: relative; }

.nps_data-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  z-index: 2;
  border-radius: 100%;
  text-align: center;
  &.-small {
    transform: translate(-50%, 0);
    top: 100%;
    .nps_data-item:last-child & {
      top: auto;
      bottom: 100%; } } }

.nps_color {
  color: #dddddd;
  background-color: currentColor;
  &.-promoters {
    color: #9CCC54; }
  &.-passives {
    color: #FFC935; }
  &.-detractors {
    color: #FF6900; } }

.nps_legend {
  margin-top: 18px; }

.nps_legend-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.nps_legend-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-left: 10px; } }

.nps_legend-circle {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  margin-right: 5px;
  flex: 0 0 auto; }

.nps_legend-value {
  font-size: 13px;
  line-height: 1.2;
  font-weight: 400;
  opacity: 0.5; }

.nps_hint {
  text-align: center;
  margin-top: 18px; }

.nps_hint-title {
  font-size: 13px;
  line-height: 1.2;
  font-weight: bold; }

.nps_hint-text {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400; }

