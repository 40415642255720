.playback-button {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  text-indent: -1000em;
  color: $color-primary;
  border: 2px solid currentColor;
  border-radius: 100%;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent currentColor;
    margin: -5px 0 0 -3px; }

  &.-stop {
    color: $color-link;

    &:before {
      border: none;
      width: 8px;
      height: 8px;
      background: currentColor;
      border-radius: 0;
      margin: -4px 0 0 -4px; } }

  &:hover {
    color: $color-link; } }
