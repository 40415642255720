.radio-tabs {
  display: inline-block;
  background: #fff;
  border-radius: 50px;
  box-shadow: inset 0 0 0 1px #E5EAEB;
  height: 38px;
  white-space: nowrap;
  &.-grow {
    flex: 1 1 auto; }
  &.-block {
    display: block; }
  &.-sm {
    height: 32px;
    .radio-tabs_label {
      min-width: 80px;
      padding: 7px 20px; } }
  &.-extra_sm {
    height: 32px;
    .radio-tabs_label {
      min-width: 40px;
      padding: 7px 20px; }
    &.-centered {
      display: table;
      margin: 0 auto; } }
  &.-borderer {
    border: 2px solid #E5EAEB;
    .radio-tabs_label {
      line-height: 1; } }
  &.-survey {
    width: 456px; }
  &.-gray-bg {
    background: #E7EEF1; }
  &.-capitalize {
    text-transform: capitalize; }
  &.-full-width {
    display: flex;
    .radio-tabs_item {
      flex: 1 1 100%; }
    .radio-tabs_label {
      display: block; } } }


.radio-tabs_item {
  display: inline-block;
  cursor: pointer;
  height: 100%;
  &.-width-1-2 {
    width: 50%; }
  &.-width-1-3 {
    width: 33.3%; }
  &.-width-1-4 {
    width: 25%; }
  &.-full-width {
    width: 100%; }
  &.-inline {
    display: inline; }
  &.-disabled {
    opacity: 0.6; }
  input {
    //don't hide it using "display: none" or "vis: hidden" because error tooltip is not shown in this case
    position: absolute;
    opacity: 0;
    &.fixed {
      position: fixed; } } }

.radio-tabs_label {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.4;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 50px;
  min-width: 120px;
  height: 100%;
  text-align: center;
  user-select: none;
  color: #787F93;
  &.-width-1-1 {
    width: 100%; }
  &.-normal-font {
    font-weight: normal; }
  &.-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  &.-sm-padding {
    padding: 5px 5px; }
  &.-disabled {
    color: rgba(120, 127, 147, 0.5);
    cursor: initial; }
  &.-survey {
    width: 226px; }
  &.-width-1-2 {
    min-width: 50%; }

  input:checked + & {
    background: $color-primary;
    color: #fff; }

  input:disabled + & {
    opacity: 0.3;
    cursor: default; }

  span, .icon {
    display: inline-block;
    vertical-align: middle;
    &.-with-pointer {
      cursor: pointer; } }
  .icon + span {
    margin-left: 5px; }

  &.-focus-tabs {
    height: 80%;
    line-height: 0.8;
    margin: 4px; } }

.radio-tabs {
  &.-gray-theme {
    box-shadow: none;
    padding: 2px;
    .radio-tabs_label {
      color: #787F93;
      padding-top: 8px;
      padding-bottom: 8px; }
    input:checked + .radio-tabs_label {
      background: #EFF4F5;
      color: $color-text; } } }

.radio-tabs.has-error {
  border-color: $color-danger; }
