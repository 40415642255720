// modal for choose items for comparison

.case-comparison {
  @include font-normal;
  font-size: 14px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1500;
  animation: moveFromLeft 0.15s;
  animation-fill-mode: both;
  h2 {
    font-size: 26px;
    @include font-bold;
    margin: 0 0 5px; } }

.case-comparison_plate {
  background-color: $va-white;
  padding: 30px;
  position: relative;
  height: 100%;
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 2; }

.case-comparison_plate-top {
  flex: 0 0 auto; }

.case-comparison_plate-body {
  flex: 1 1 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px; }

.case-comparison_backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1);
  z-index: 1;
  animation: fadeIn 0.15s 0.15s;
  animation-fill-mode: both; }

.case-comparison_close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    vertical-align: middle;
    width: 16px;
    height: 16px; } }

.case-comparison_notes {
  margin-bottom: 30px; }

.case-comparison_list {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    padding: 5px 0; } }

.case-comparison_grid {
  display: flex;
  gap: 5px;
  min-height: 24px;
  align-items: flex-start;
  justify-content: space-between;
  .icon {
    vertical-align: middle; } }

.case-comparison_grid-item {
  line-height: 1.3;
  &.-flex {
    flex: 1 1 100%; }
  &.-first {
    flex: 0 0 20px; }
  &.-last {
    flex: 0 0 24px; } }

.case-comparison_button-delete {
  background-color: #D8DADA;
  width: 24px;
  height: 24px;
  text-align: center;
  color: $va-brand;
  cursor: pointer;
  .icon.-i-close-sm {
    width: 10px;
    height: 10px;
    vertical-align: middle; } }

.-no-wrap {
  text-wrap: nowrap; }
