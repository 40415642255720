//https://www.cufonfonts.com/font/arial Designed by: Monotype Type Drawing Office - Robin Nicholas, Patricia Saunders 1982
// License: Free for commercial use

@font-face {
  font-family: 'Arial Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Regular'), url('https://d2d755s474y4ld.cloudfront.net/libraries/fonts/ARIAL.woff') format('woff'); }

@font-face {
  font-family: 'NHaasGroteskText75';
  src: url('../assets/fonts/neue-haas-grotesk-text-75/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-text-75/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskText55';
  src: url('../assets/fonts/neue-haas-grotesk-text-55/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-text-55/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskDisplay75';
  src: url('../assets/fonts/neue-haas-grotesk-display-75/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-display-75/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskDisplay55';
  src: url('../assets/fonts/neue-haas-grotesk-display-55/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-display-55/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskDisplay45';
  src: url('../assets/fonts/neue-haas-grotesk-display-45/font.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk-display-45/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskTXPro';
  src: url('../assets/fonts/neue-haas-grotesk/3B06C3_0_0.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk/3B06C3_0_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'NHaasGroteskTXPro';
  src: url('../assets/fonts/neue-haas-grotesk/3B06C3_1_0.woff2') format('woff2'), url('../assets/fonts/neue-haas-grotesk/3B06C3_1_0.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap; }
