@mixin font-verizon-title {
  font-family: $font-verizon-title;
  font-weight: normal; }

@mixin font-verizon-text {
  font-family: $font-verizon-text;
  font-weight: normal; }

@mixin font-verizon-bold {
  font-family: $font-verizon-bold;
  font-weight: normal; }

@mixin font-verizon-normal {
  font-family: $font-verizon-normal;
  font-weight: normal; }

@mixin font-verizon-title-alt {
  font-family: $font-verizon-text-alt;
  font-weight: bold; }

@mixin font-verizon-text-alt {
  font-family: $font-verizon-text-alt;
  font-weight: normal; }

@mixin  clearfix {
  &:after {
    content: '';
    display: table;
    clear: both; } }

//= container
//  width: 100%
//  max-width: $width-container

@mixin  truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; }

@mixin  break-word {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word; }

@mixin  line-clamp($lines) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  line-clamp: $lines; }

//= mobile-small
//  @media (max-device-width: $break-mobile-small)
//    @content

@mixin  mobile {
  @media (max-width: $break-mobile - 1px) {
    @content; } }
@mixin  mobile-small {
  @media (max-width: $break-mobile-inter - 1px) {
    @content; } }
@mixin  mobile-small-inter {
  @media (max-width: $break-mobile-inter - 1px) {
    @content; } }
@mixin  mobile-between-small-and-inter {
  @media (max-width: $break-mobile-between-small-and-inter) {
    @content; } }
@mixin  less-than-450 {
  @media (max-width: $break-mobile-450 - 1px) {
    @content; } }
@mixin  mobile-break-470 {
  @media (max-width: $break-mobile-470) {
    @content; } }
@mixin  less-than-mobile {
  @media (max-width: $break-mobile-small) {
    @content; } }
@mixin  mobile-break-640 {
  @media (max-width: $break-640) {
    @content; } }
@mixin  break-medium-fonts-projects {
  @media (max-width: $break-medium-fonts-projects) {
    @content; } }
@mixin  break-small-fonts-projects {
  @media (max-width: $break-small-fonts-projects) {
    @content; } }

@mixin  vertical-750 {
  @media (max-height: 750px) {
    @content; } }

@mixin  horizontal-880 {
  @media (max-width: 880px) {
    @content; } }

@mixin  intermediate {
  @media (max-width: $break-intermediate - 1px) {
    @content; } }

@mixin  desktop {
  @media (min-width: $break-mobile) {
    @content; } }

@mixin  breakpoint-hi($point) {
  @media (min-width: $point) {
    @content; } }

@mixin  breakpoint-lo($point) {
  @media (max-width: $point) {
    @content; } }

@mixin  breakpoint-hi-height($point) {
  @media (min-height: $point) {
    @content; } }

@mixin  breakpoint-lo-height($point) {
  @media (max-height: $point) {
    @content; } }

@mixin  mobile-kids-question {
  @media (max-width: $break-kids-question - 1px) {
    @content; } }

@mixin  desktop-kids-question {
  @media (min-width: $break-kids-question) {
    @content; } }

@mixin  no-appearance {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  outline: none;
  box-shadow: none;
  border: none; }

@mixin  reset-button-style {
  @include no-appearance;
  user-select: none;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: auto;
  line-height: inherit;
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-transform: none;
  text-align: left;
  font-family: $font-family-base;
  font-weight: inherit;
  font-size: inherit;
  white-space: normal;
  background: transparent;
  border: none; }

@mixin  dropdown-show {
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  height: auto;
  width: auto;
  overflow: visible;
  transition: opacity 0.3s, visibility 0.3s, z-index 0.3s; }

@mixin  dropdown-hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  transition: opacity 0s, visibility 0s, height 0s, width 0s, overflow 0s; }
