.form-footer {
  border-top: 1px solid $color-line-light;
  padding-top: 20px;
  margin-top: 30px;
  &.-fixed {
    border: 0;
    background-color: $bg-gray;
    position: sticky;
    bottom: 0;
    margin: 25px 0 0;
    padding: 0;
    z-index: 100;
    p {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle; }

    @media (max-width: 1000px) {
      width: 585px; } } }

.footer-inner {
  border-top: 1px solid $color-line-light;
  padding: 20px 0; }

.form-footer_button {
  min-width: 140px;
  &.-right {
    float: right; } }
