.form-flavor_image {
  position: relative;
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  //background-color: #EFF4F5
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  .-pointer, &.-pointer {
    cursor: pointer; }

  &.-no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px; }
  &.-with-margins {
    margin-left: 12px;
    margin-right: 12px; }
  &.-grid {
    display: grid;
    background-color: #F3F6F8; }
  &.-content-flex-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;

    .upload-button-processing {
      color: $color-primary; } }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%; } }

.form-flavor_image_delete {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background: #F2F2F3;
  border-radius: 0 0 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  button.link, a.-delete {
    color: #A9BFC8;
    margin: 0; } }

.form-flavors {
  background-color: #fff;
  border-radius: 12px;
  padding: 0 15px 15px 30px;
  margin-top: 10px; }

.form-flavors_num {
  font-weight: bold;
  margin-top: -30px;
  min-width: 19px;
  text-align: right;
  margin-right: 5px; }

.form-flavors_list {
  padding: 0;
  margin: 0;
  list-style: none; }

.form-flavors_item.-new {
  padding: 15px 0 0;
  &.-no-items {
    padding: 50px 0 0; }
  .form-flavors_num {
    opacity: 0; }
  .-flavor-text textarea {
    border-radius: 7px 0 0 7px; }
  .-flavor-label textarea {
    border-radius: 0 7px 7px 0; } }

.form-flavors_item.-ready {
  padding: 0;
  &:not(:first-child) {
    margin-top: -1px; }

  margin-bottom: 10px;

  &.-no-margin {
    margin-bottom: 0; }
  &.-one {
    &:first-child, &:last-child {
      .-flavor-text textarea {
        border-radius: 7px 0 0 7px; }
      .-flavor-label textarea {
        border-radius: 0 7px 7px 0; } } }

  &:first-child {
    padding: 50px 0 0;
    .-flavor-text textarea {
      border-radius: 7px 0 0 0; }
    .-flavor-label textarea {
      border-radius: 0 7px 0 0; } }

  &.-last-child {
    margin-bottom: 0;
    .-flavor-text textarea {
      border-radius: 0 0 0 7px;
      border-bottom-color: $form-border-color; }
    .-flavor-label textarea {
      border-radius: 0 0 7px 0;
      border-bottom-color: $form-border-color; } }

  .-flavor-text textarea {
    border-radius: 0;
    border-bottom-color: transparent; }
  .-flavor-label textarea {
    border-radius: 0;
    border-bottom-color: transparent; } }

.form-flavors_grid {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form-flavors_grid-item {
  flex: 0 0 auto;
  button {
    margin-left: 15px; }
  &.-flavor-text {
    flex: 1 1 auto; }
  &.-flavor-label {
    flex: 0 0 25%;
    textarea {
      border-left: none; } }
  textarea:focus {
    border-color: $form-border-color; } }

.form-flavors_group {
  position: relative;

  input.form-field {
    margin-left: 15px;
    width: calc(100% - 15px);
    border-radius: 10px; } }

.control-label_tooltip {
  margin-left: 5px;
  margin-top: -2px; }

textarea.form-flavors_textarea {
  resize: none;
  height: 80px;
  min-height: 60px;
  padding: 12px; }

textarea.has-error {
  border-color: $color-danger !important; }
