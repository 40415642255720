* {
  box-sizing: border-box; }

*:before,
*:after {
  box-sizing: border-box; }

html, body {
  min-height: 100%;
  height: 100%; }

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: $font-size-base; }

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: normal;
  color: $color-text;
  background-color: $color-body;
  line-height: $line-height-base;

  &.modal-open {
    margin-right: 0;
    overflow: hidden; }

  //&.-mapper
  //  .sidebar,
  //  .project-sidebar
 }  //    display: none

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

body, input, button, textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a,
[role="button"] {
  cursor: pointer;
  color: $color-link;
  text-decoration: none;
  outline: none;
  &:hover,
  &:focus {
    color: $color-link-hover;
    text-decoration: none;
    text-decoration-skip-ink: auto; } }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

hr {
  margin-bottom: 20px;
  margin-top: 20px;
  border: 0;
  border-top: 1px solid #e8eaec;
  clear: both; }

[role="button"] {
  cursor: pointer; }

input::-ms-clear, input::-ms-reveal {
  display: none; }

form {
  display: block;
  margin: 0; }

fieldset {
  padding: 0; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

button:-moz-focusring {
  outline: none; }
