.checker {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin: 0; }

.checker_checkbox {
  opacity: 0;
  position: absolute;
  left: -1000em; }

.checker_label {
  padding-left: 18px;
  margin: 0;
  font-weight: 400;
  display: inline-block;
  position: relative;
  user-select: none;
  min-height: 22px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.1em;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #BFBFBF;
    margin-top: 1px;
    background: #fff; }
  &:after {
    content: '';
    display: block;
    width: 12px;
    height: 7px;
    border-left: 2px solid $color-page;
    border-bottom: 2px solid $color-page;
    transform: rotate(-45deg);
    position: absolute;
    left: 3px;
    top: 0.1em;
    margin-top: 5px;
    opacity: 0; }
  .checker_checkbox:checked + & {
    &:before {
      background-color: $color-primary;
      box-shadow: none; }
    &:after {
      opacity: 1; } }
  .checker_checkbox:disabled + & {
    opacity: 0.3;
    cursor: default; }
  .checker_checkbox:hover + &:before {
    box-shadow: inset 0 0 0 1px $color-primary; } }

.checker_label-text {
  font-size: 13px;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;
  padding: 0 0 0 12px;
  color: $color-gray; }
