.form-field {
  position: relative;
  z-index: 1;
  display: inline-block;
  outline: none;
  font-size: 14px;
  padding: 10px 15px;
  background-color: $color-page;
  margin: 0;
  min-height: $form-field-height;
  line-height: normal;
  border: 1px solid $form-border-color;
  border-radius: $form-field-height;
  box-shadow: none;
  color: $color-text;
  -webkit-appearance: none;
  transition: border-color $transition;
  &:focus {
    border-color: $color-primary; }
  .has-error &, &.has-error {
    border-color: $color-danger; }
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0; }
  &::-webkit-calendar-picker-indicator {
    margin-left: 0px; }
  &::placeholder {
    color: $color-placeholder; }
  &:focus::placeholder {
    color: transparent !important; }
  &[disabled] {
    border-color: $color-border-disabled;
    color: $color-disabled;
    &::placeholder {
      color: $color-disabled; } }
  &.-sm {
    min-height: 32px;
    padding-top: 5px;
    padding-bottom: 5px; }
  &.-md {
    min-height: 32px;
    padding-top: 10px;
    padding-bottom: 6px; }
  &.-block {
    display: block;
    width: 100%; }
  &.-with-margins {
    margin-left: 11px;
    margin-right: 8px; }
  &.-with-left-margin {
    margin-left: 11px; }

  &.-link {
    border: none;
    padding: 2px 0;
    line-height: 1.4;
    cursor: pointer;
    color: $color-link;
    min-height: 0;
    border-radius: 0;
    &[disabled] {
      color: $color-disabled;
      &::placeholder {
        color: $color-disabled; } } }
  &.-global-search {
    transition: width 0.15s;
    width: 300px;
    &.-closed {
      width: 38px;
      padding-right: 0;
      pointer-events: none; }
    //&:focus
    //  width: 450px
 }    //  max-width: 100%
  &.-full-width-standalone {
    width: 100%; }

  &.-brand-name {
    margin-left: 8px;
    min-height: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 250px;
    &.-swipe-brand {
      padding-top: 7px;
      padding-bottom: 7px; }

    &.-transparent {
      visibility: hidden;
      opacity: 0; } }

  //&.-rounded
  //border-radius: 18px
  //&.-name
  //max-width: 540px
  //&.-like
  //border: 10px solid #D9F7EC
  //box-shadow: inset 0 0 0 1px #68D8C2
  //color: $color-success
  //&.-dislike
  //border: 10px solid #FFDBDB
  //box-shadow: inset 0 0 0 1px #FF7777
  //color: #FF7777
  //&.-inline
  //display: inline-block
  //vertical-align: middle
  //width: auto
  //min-width: 160px
  //& + &
  //  margin-left: 16px
  //&.-maxlength
  //display: inline-block
  //width: 94px
  //padding-right: 60px
  //margin-right: 10px
  //& + label
  //  font-size: 12px
  //  color: $color-placeholder
  //  position: absolute
  //  margin: 0 0 0 -60px
  //  z-index: 2
  //  padding: 9px 0
  //  cursor: text
  //&.-link-like
  //border: none
  //padding: 2px 0
  //line-height: 1.4
  //cursor: pointer
  //&::placeholder
  //  color: $color-primary
  //  font-weight: 500
  //&.-short
 }  //max-width: 89px

textarea.form-field {
  resize: vertical;
  min-height: 7.5rem;
  border-radius: $form-border-radius;
  padding: 15px; }

input.form-field.-block-left {
  border-radius: 19px 0 0 19px;
  min-width: 223px; }
input.form-field.-block-right {
  border-radius: 0 19px 19px 0;
  min-width: 223px; }

select.form-field,
.dropdown_toggle.form-field {
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-position: right 13px center;
  background-image: url('../assets/images/icons/i-select.svg');
  padding-right: 30px;
  &[disabled] {
    background-image: url('../assets/images/icons/i-select-disabled.svg'); }
  &::-ms-expand {
    display: none; }
  &.-link {
    padding-right: 12px;
    background-position: right 44%; }
  &.-not-selected-disabled {
    background-image: none; }
  &.-selected-but-disabled {
    &[disabled] {
      opacity: 1;
      border-color: $form-border-color;
      color: $color-text;
      background: #FFFFFF;
      background-image: none; } }
  &.-with-top-margin {
    margin-top: 10px; } }


.form-field {
  &.-select {
    cursor: pointer;
    &.-not-selected {
      color: $color-placeholder; }
    &.-disabled {
      cursor: default; }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $color-options-description;
      border-bottom: none;
      right: 18px;
      top: 44%; }
    &.-opened {
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $color-options-description;
        border-top: none; } } } }

