.form-intro {
  min-height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.-top {
    align-items: flex-start; } }

.form-intro_content {
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;
  padding: 20px 0; }

.form-intro_section {
  & + & {
    border-top: 1px solid $color-line-light;
    padding-top: 35px;
    margin-top: 35px; } }

.form-intro_section-inner {
  margin: auto;
  &.-text-center {
    text-align: center; }
  &.-center {
    max-width: none; }
  &.-center-wider {
    max-width: 350px; }
  &.-swipe-config {
    max-width: 510px; }
  &.-launch {
    max-width: 550px; } }

.form-intro_icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-primary;
  color: $color-primary;
  margin: 0 auto 25px; }

.form-intro_icon_big {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-primary;
  margin: 0 auto 25px; }

.form-finish_icon {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-primary;
  margin: 0 auto;
  svg {
    width: 120px;
    height: 120px; } }

.form-intro_title {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  &.-with-margin-10 {
    margin: 10px; }
  &.-with-bottom-margin {
    margin-bottom: 10px; }
  &.-concept-focus {
    width: 425px;
    margin: 30px auto 0; }
  &.-claims-title {
    width: 220px;
    margin: 0 auto 15px; } }

.form-intro_description {
  width: 350px;
  font-size: 15px;
  margin: 0 auto;
  text-align: left;
  padding-left: 32px;
  ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-indent: -24px;
    padding-left: 24px;
    li {
      list-style-type: none;
      &:before {
        content: '\2014\a0';
        margin-right: 5px; } }
    &.-claims-margin, &.-names-margin {
      li {
        margin-bottom: 10px; }
      li:last-child {
        margin-bottom: inherit; } } }
  &.-names-hint {
    padding-left: 0px;
    text-align: center; } }


.form-intro_button {
  margin-top: 25px;
  .button {
    min-width: 170px; }
  &.-btn-md {
    .button {
      min-width: 215px; } }
  &.-preview-row {
    margin: 25px auto 0;
    display: flex;
    flex-direction: row;
    width: 80%;
    .button {
      flex: 1 1;
      min-width: 170px;
      &:first-child {
        margin-top: 0;
        margin-right: 5px; }
      &:last-child {
        margin-top: 0;
        margin-left: 5px; } } }
  &.-no-top-margin {
    margin-top: 0; } }

.form-intro_or-row {
  color: $color-text-gray;
  font-size: 15px;
  text-align: center;
  margin: 10px 0; }

.form-intro_buttons {
  margin-top: 25px;
  &.-claims {
    .button {
      min-width: 230px; } }
  &.-names {
    .button {
      min-width: 230px; } } }

.form-intro_button-separate {
  color: $color-gray;
  margin: 10px 0;
  line-height: 1; }

.form-intro_text {
  font-size: 15px;
  line-height: 1.6;
  &:not(:first-child) {
    margin-top: 25px;
    &.-with-margin-10 {
      margin-top: 10px; } }
  &.-gray {
    color: $color-text-gray; }
  &.-little-top-margin {
    margin-top: 5px; }
  &.-middle-top-margin {
    margin-top: 10px; } }


.form-intro_plates {
  margin: 20px -10px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between; }

.form-intro_plate {
  flex: 1 1 50%;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 14px;
  border: 1px solid $form-border-color;
  overflow: hidden;
  background-color: #fff;
  &.-one-concept {
    flex-direction: row; } }

.form-intro_plate-top {
  padding: 20px 25px;
  flex: 1 0 auto; }

.form-intro_plate-bottom {
  flex: 0 0 auto;
  padding: 0; }

.form-intro_plate-left {
  padding: 20px 25px 0;
  flex: 1 0 auto;
  width: 50%;
  text-align: left; }

.form-intro_plate-right {
  flex: 0 0 auto;
  width: 50%;
  padding: 3px 3px 3px 0;
  img {
    border-radius: 0 14px 14px 0; } }

.form-intro_plate-title {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 10px; }

.form-intro_plate-text {
  font-size: 15px;
  line-height: 1.5;
  color: $color-text-gray;
  &.-align-left {
    text-align: left; } }

.form-intro_example {
  &.-question {
    width: 228px;
    margin: 20px auto 0; }
  &.-concept {
    height: 100%;
    position: relative; }
  &.-grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .form-intro_example-preview {
      margin: 0 8px;
      flex: 1 1 50%; } } }

.form-intro_example-preview-img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto; }
