.modal.-scorecard {
  transition-property: visibility, opacity;
  transition-duration: 0s, 0.5s;
  &.-animated-hide {
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.5s, 0s;
    transition-delay: 0s, 0.5s; } }

.comparisons_modes {
  text-align: center;
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
  position: relative; }

.comparisons_modes-item-icon {
  color: #A9BFC7;
  margin: 2px 0; }

.comparisons_modes-link {
  text-decoration: none;
  font-size: 11px;
  font-weight: normal;
  color: $color-primary;
  text-transform: capitalize;
  &.-current {
    color: $color-text;
    pointer-events: none; } }

.comparisons_plate {
  background: #EFF4F5;
  padding: 20px;
  border-radius: 20px;
  &.-full-height {
    height: 100%; }
  &.-limited-height {
    max-height: 450px; }
  &.-margin {
    margin: 10px 0 40px; }
  &.-more-bottom-padding {
    padding-bottom: 30px; }
  &.-flex-vertical {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    & > * {
      flex: 0 0 auto; }
    & > .comparisons_plate-spacer {
      flex: 1 1 100%; } } }

.comparisons_plate-legend {
  margin-top: 25px;
  &.-margin-xs {
    margin-top: 10px; } }

.claims-explanation {
  margin-top: 25px;
  line-height: 1.3;
  p {
    margin: 0; } }

.comparisons_plate-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.-filters {
    width: 100%; }
  .comparisons_plate-item {
    &:first-child {
      margin-left: 0; }
    margin-left: 15px; } }

.comparisons_plate-item {
  flex: 0 0 auto;
  &.-grow {
    flex: 1 1 auto; }
  &.-width-1-3 {
    flex: 0 0 30%; }
  &.-width-1-2 {
    flex: 1 1 50%; }
  &.-with-export-button {
    width: 120px;
    text-align: right; }
  & + & {
    margin-left: 20px; }
  &.-with-inner-dropdown {
    .search-field input {
      width: 230px !important; } }
  &.-with-help {
    select, .chosen-container {
      width: 89% !important;
      display: inline-block; } } }

.comparisons_plate-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
  &.-center {
    text-align: center; } }

.comparisons_plate-button {
  min-width: 100px; }

.comparisons_intro-grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px; }

.comparisons_intro-grid-item {
  flex: 1 1 auto;
  & + & {
    margin-left: 12px; }
  &.-fix {
    flex: 0 0 auto; }
  &.-stretch-height {
    align-self: stretch;
    .comparisons_intro-plate {
      min-height: 100%; } } }

.comparisons_intro-text {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 25px;
  .comparisons_intro-grid & {
    margin-bottom: 0; } }

.comparisons_intro-plate {
  font-weight: bold;
  font-size: 12px;
  min-width: 66px;
  min-height: 52px;
  border: 1px solid #B2C0C2;
  padding: 12px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.-white {
    background-color: #fff;
    border: none; } }

.comparisons_table {
  margin-top: 40px;
  &.-relative {
    position: relative; }
  &.-claims {
    margin-top: 15px;
    min-height: 150px; }
  &.-with-border {
    border: 1px solid #EFF4F5;
    border-radius: 20px;
    padding: 0 20px 5px 20px; }
  span.-green {
    color: $color-success; }
  &.-gray-bg {
    background-color: #F6F6F7; }
  &.-without-margin {
    margin-top: 0; } }

.comparisons_grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: auto;
  margin: -6px;
  &.-space-md {
    margin: -12px; }
  &.-space-lg {
    margin: -24px; }
  &.-wrap {
    flex-wrap: wrap; }
  &.-gap-hor {
    column-gap: 30px; } }

.comparisons_grid-item {
  position: relative;
  flex: 1 1 100%;
  min-width: 0;
  padding: 6px;
  .-space-md > & {
    padding: 12px; }
  .-space-lg > & {
    padding: 24px; }
  &.-fix {
    flex: 0 0 auto; }
  &.-thin {
    flex: 0 0 30%; }
  &.-narrow {
    flex: 0 0 42%; }
  &.-half {
    flex: 0 0 50%; }
  &.-full {
    flex: 0 0 100%; }
  &.-grow {
    flex: 1 1 auto; }

  &.warning {
    color: $color-danger;
    font-weight: bold; } }

.comparisons_filters {
  margin: 30px 0;
  &:first-child {
    margin-top: 0; } }

.comparisons_plates {
  margin: 0;
  & + &, & + .comparisons_plate {
    margin-top: 20px; }
  &.with-padding {
    margin: 20px 0 0 0;
    padding: 20px;
    border: 1px solid $color-border; } }

.comparisons_plates-grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: -4px;

  &.-g2x2 {
    display: grid;
    justify-content: normal; } }

.comparisons_plates-grid-item {
  flex: 1 1 100%;
  padding: 4px;
  &.-fix {
    flex: 0 0 auto; }
  &.-one-third {
    flex: 0 0 30%; }
  &.-thirty-five {
    flex: 0 0 35%; }
  &.-one-quarter {
    flex: 0 0 25%; }
  &.-no-left-padding {
    padding-left: 0; }
  &.-no-right-padding {
    padding-right: 0; }
  &.-g-col-1 {
    grid-column: 1; }
  &.-g-col-2 {
    grid-column: 2; }
  &.-g-row-1 {
    grid-row: 1; }
  &.-g-row-2 {
    grid-row: 2; } }

.comparisons_tabs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0; }

.comparisons_metrics_tabs {
  margin-bottom: 10px; }

.comparisons_col {
  flex: 0 0 auto;
  &.-grow {
    flex: 1 1 auto; }
  &.-width-1-3 {
    width: 33%; }
  &.-width-1-1-3 {
    width: 37%; }
  &.-width-50 {
    width: 47.5%; }
  & + & {
    margin-left: 5%; } }

.comparisons_legend {
  margin-top: 15px;
  padding-top: 25px;
  padding-bottom: 10px;
  &.-scorecard {
    margin-top: 0;
    padding: 25px 0;
    border-top: 0; }
  &.-claims {
    margin-top: -1px; } }

.comparisons_legend-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  &.-left {
    justify-content: left;
    margin-top: 27px;
    margin-bottom: 27px; } }

.comparisons_legend-grid-item {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-left: 25px; }
  .arrow {
    margin-right: 5px; } }

.comparisons_legend-label {
  display: inline-block;
  min-width: 17px;
  height: 17px;
  padding: 0 7px;
  border-radius: 17px;
  text-align: center;
  line-height: 17px;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  &.-color-below {
    background: $color-red; }
  &.-color-above {
    background: $color-success; }
  &.-color-neutral {
    background: $color-neutral; }
  &.-color-danger {
    background: $color-danger; }
  &.-color-top_20 {
    background: $color-top_20; }
  &.-color-top_40 {
    background: $color-top_40; }
  &.-color-middle_20 {
    background: $color-middle_20; }
  &.-color-bottom_40 {
    background: $color-bottom_40; }
  &.-color-bottom_20 {
    background: $color-bottom_20; }
  &.-empty-gray {
    border: 3px solid #B2C0C2;
    padding: 0; } }

.comparisons_legend-text {
  font-size: 13px;
  line-height: 1.3;
  color: $color-text-gray;
  .comparisons_legend-label + & {
    margin-left: 10px; }
  &.-bold {
    font-weight: bold; }
  &.-center {
    text-align: center; }
  & + & {
    margin-top: 15px; }
  p + p {
    margin-top: 0; } }

.comparisons_legend-title {
  font-size: 13px;
  line-height: 1.3;
  color: $color-text-gray;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px; }

.comparisons_legend-arrow-top {
  color: $color-success;
  margin-right: 7px; }

.comparisons-maxdiff-description {
  margin-top: 10px;
  color: $color-text-gray;
  //padding: 0 10px 0
  line-height: 1.3;
  p + p {
    margin-top: 5px; } }

.claims-for-loader {
  margin-top: 20px;
  position: relative;

  .info-text {
    margin-top: 32px;
    text-align: center;
    font-size: 1rem;
    color: $color-text-gray; } }

.comparisons_legend-letters {
  .letter-item {
    font-size: 13px;
    color: $color-base-gray; } }

.comparison-warning-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.comparisons {
  .-tour-hide {
    display: none !important; } }

.comparisons-warning {
  padding: 8px 13px;
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.comparisons-warning {
  font-size: 15px;
  color: #fff;
  left: 274px;
  top: 314px;
  max-width: 760px;
  line-height: 1.4;
  background: #FDA878;
  border-radius: 16px;
  padding: 8px 13px; }

.comparisons-warning-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #FDA878 transparent transparent transparent;
  margin-left: auto;
  margin-right: 50px; }

.comparisons-debug-info {
  padding: 8px 13px;
  font-size: 15px;
  color: #fff;
  left: 274px;
  top: 314px;
  line-height: 1.4;
  border-radius: 16px;
  background: #6D7993;
  &.-with-margin-below {
    margin-bottom: 10px; } }

.comparisons-customize-button {
  height: 24px;
  font-size: 12px;
  margin-left: 8px; }

h3.-with-icon {
  display: flex;
  justify-content: center;

  .icon {
    margin-left: 40px;
    color: white;
    background-color: black;
    padding: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer; } }
