.sidebar {
  display: flex;
  flex-direction: column;
  width: $sidebar-width;
  padding: 0 0 25px;
  background: #505154;
  color: #C8C8C8;
  height: 100vh;
  &.-verizon {
    background-color: #000;
    color: #fff;
    .img-logo.-verizon-logo {
      width: auto;
      height: 45px; } }
  &.-verizon-cit, &.-verizon-cat {
    &.-verizon {
      background-color: #F3EDE0;
      color: #000;
      .img-logo.-verizon-logo {
        width: auto;
        height: 45px; }
      .sidebar_nav-link-icon {
        color: #000; }
      .sidebar_nav-link, .sidebar_nav-list-item {
        color: #000;
        &.-active, &:hover, &:active, &:focus, .sidebar_nav-list-item.dropdown.-on-hover:hover & {
          color: #FF3C2D;
          & + a {
            color: #FF3C2D; }
          .sidebar_nav-link {
            color: #FF3C2D; } }
        &.-with-inner {
          &.-hide-highlight {
            &.-active, &:hover, &:active, &:focus, .sidebar_nav-list-item.dropdown.-on-hover:hover & {
              color: #000; } }
          .sidebar_nav-link, .sidebar_nav-list-item {
            color: #000;
            &.-active, &:hover, &:active, &:focus, .sidebar_nav-list-item.dropdown.-on-hover:hover & {
              color: #FF3C2D;
              .sidebar_nav-link {
                color: #FF3C2D; } } } }
        &.-active {
          color: #FF3C2D;
          &.-travel-title {
            color: #000; } } }
      .sidebar_nav-title {
        color: #000; } } } }

.sidebar_item {
  flex: 0 0 auto;
  padding: 0 20px;
  &.-grow {
    flex: 1 1 auto;
    overflow-y: auto; } }

.sidebar_top {
  height: $page-header-height;
  border-bottom: 1px solid #5E6063;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; } }

.sidebar_logo {
  &, &:hover, &:active, &:focus {
    color: #fff; } }

.sidebar_nav {
  padding-top: 20px; }

.sidebar_nav-link {
  display: inline-block;
  color: #a7aba9;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  position: relative;
  padding-left: 20px;
  word-break: break-word;
  &.-active {
    color: #fff; }
  &:hover, &:active, &:focus,
  .sidebar_nav-list-item.dropdown.-on-hover:hover & {
    color: #fff; }
  &.-sub {
    //font-weight: normal
    padding-left: 0; }
  &.-normal {
    font-weight: normal; }
  &.-opened {
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #fff;
      border-bottom: none;
      right: -10px;
      top: 7px; } } }

.sidebar_nav-link-icon {
  //margin-right: 10px
  vertical-align: middle;
  position: absolute;
  top: 3px;
  left: 0;
  margin: auto;
  color: #65C18B;
  &.-menu-project-naming {
    top: 0; } }

.sidebar_nav-title {
  font-size: 11px;
  font-weight: bold;
  color: #C8C8C8;
  margin: 0 0 5px; }

.sidebar_nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  &.-sub {
    padding: 5px 0 5px 20px; } }

.sidebar_nav-list-item {
  & + & {
    margin-top: 8px; }
  &.-sub + &.-sub {
    margin-top: 0; }
  &.-active {
    color: #fff; } }
