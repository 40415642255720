// Bootstrap-based pagination styles and overrides

.pagination {
  display: inline-block;
  padding-left: 0;
  border-radius: 20px;
  margin: 10px 0;
  & > li {
    user-select: none;
    display: inline;
    & > a, & > span {
      position: relative;
      float: left;
      padding: 7px 12px;
      margin-left: -1px;
      line-height: 1.428571429;
      text-decoration: none;
      background: #fff;
      border: 1px solid #ddd;
      &:hover, &:focus {
        background-color: $color-body; } }
    &:first-child {
      & > a, & > span {
        margin-left: 0;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        padding-left: 16px; } }
    &:last-child {
      & > a, & > span {
        margin-right: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        padding-right: 16px; } } }
  & > .active {
    & > a, & > span {
      &, &:active, &:hover, &:focus {
        background-color: $color-primary;
        border-color: $color-primary;
        z-index: 2;
        color: #fff;
        cursor: default; } } }

  .visually-hidden {
    display: none; } }
