.-side-by-side-modal {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .modal-dialog {
    max-height: 100vh; }
  .modal-content, .normal-popup, .content-body {
    height: 100%; }
  .content-body-fields {
    height: auto; } }

.verizon-modifier {
  &.-side-by-side-modal-dialog {
    .react-select__multi-value {
      background-color: $va-coral;
      color: #fff;
      &:nth-child(2n) {
        background-color: #F6F6F6;
        .react-select__multi-value__label {
          color: $va-coral;
          padding: 3px; }
        .react-select__multi-value__remove {
          display: none; } } }
    .react-select__indicator-separator {
      display: none; }
    .clear-button {
      cursor: pointer;
      background: #000;
      border: none;
      border-radius: 100%;
      width: 29px;
      height: 29px;
      position: relative;
      &:before, &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 15px;
        top: 7px;
        left: 14px;
        background-color: $va-coral;
        border-radius: 20px; }
      &:before {
        transform: rotate(-45deg); }
      &:after {
        transform: rotate(45deg); } }
    .custom-indicators {
      display: flex;
      flex-direction: row;
      align-items: center;
      .logic-list {
        display: flex;
        flex-direction: row;
        background-color: #000;
        border-radius: 70px;
        overflow: hidden;
        padding: 2px;
        button {
          cursor: pointer;
          background: transparent;
          border: 0;
          font-size: 13px;
          padding: 0;
          width: 41px;
          height: 26px;
          color: #fff;
          &.-selected {
            background-color: #fff;
            color: $va-coral;
            border-radius: 50px; } } }

      .logic-list + .clear-button {
        margin-left: 4px; } }
    .button-multi-value-remove {
      cursor: pointer;
      padding: 0;
      margin: 0;
      width: 8px;
      height: 8px;
      background: transparent;
      border: none;
      position: relative;
      &:before, &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        top: -1px;
        left: 3px;
        background-color: #fff;
        border-radius: 20px; }
      &:before {
        transform: rotate(-45deg); }
      &:after {
        transform: rotate(45deg); } }

    .add-logic-separator {
      position: relative;
      margin: 18px 0;
      text-align: center;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      &:before, &:after {
        content: '';
        position: absolute;
        background-color: $va-grey-border;
        width: calc(50% - 65px);
        height: 1px;
        top: 50%; }
      &:before {
        left: 0; }
      &:after {
        right: 0; }
      .button-add-separator {
        color: $va-coral;
        font-size: 12px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent; }
      .clear-button {
        width: 20px;
        height: 20px;
        &:before, &:after {
          height: 12px;
          top: 4px;
          left: 9px; } } }
    .logic-separator-title {
      @include font-normal;
      font-size: 12px; }
    .logic-separator-title + .clear-button {
      margin-left: 5px; } }


  button {
    &.new-plus {
      background-color: $va-green;
      width: 37px;
      height: 37px;
      position: relative;
      border-radius: 0;
      padding: 0;
      margin: 0;
      &:before, &:after {
        content: '';
        position: absolute;
        background-color: #fff;
        width: 2px;
        height: 10px;
        border-radius: 20px;
        top: 14.5px;
        left: 18px; }
      &:before {
        transform: rotate(-90deg); }
      &:after {
        transform: rotate(0); }
      &:hover {
        &:not([disabled]) {
          background-color: rgba(0, 184, 69, 0.85); } } } }

  .side-by-side-edit {
    position: relative;
    &:hover {
      background-color: $va-stone;
      button {
        &.edit-icon {
          display: block; } } }
    button {
      &.edit-icon {
        display: none;
        position: absolute;
        padding: 0;
        margin: 0;
        background-color: $va-coral;
        width: 37px;
        height: 37px;
        border-radius: 0;
        top: 0;
        right: 0;
        .edit-pencil {
          width: 16px;
          height: 16px; } }
      &:hover:not([disabled]) {
        background-color: rgba(255, 60, 45, 0.8); } } } }
