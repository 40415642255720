.form-claims {
  background-color: #fff;
  border-radius: 12px;
  padding: 0 15px 15px 30px;
  margin-top: 20px; }

.form-claims_num {
  font-weight: bold;
  margin-top: -30px;
  min-width: 19px;
  text-align: right;
  margin-right: 5px; }

.form-claims_list {
  padding: 0;
  margin: 0;
  list-style: none; }

.form-claims_item.-new {
  padding: 15px 0 0;
  &.-no-items {
    padding: 50px 0 0; }
  .form-claims_num {
    opacity: 0; }
  .-claim-text textarea {
    border-radius: 7px 0 0 7px; }
  .-claim-label textarea {
    border-radius: 0 7px 7px 0; } }

.form-claims_item.-ready {
  padding: 0;
  &:not(:first-child) {
    margin-top: -1px; }

  &.-one {
    &:first-child, &:last-child {
      .-claim-text textarea {
        border-radius: 7px 0 0 7px; }
      .-claim-label textarea {
        border-radius: 0 7px 7px 0; } } }

  &:first-child {
    padding: 50px 0 0;
    .-claim-text textarea {
      border-radius: 7px 0 0 0; }
    .-claim-label textarea {
      border-radius: 0 7px 0 0; } }

  &.-last-child {
    .-claim-text textarea {
      border-radius: 0 0 0 7px;
      border-bottom-color: $form-border-color; }
    .-claim-label textarea {
      border-radius: 0 0 7px 0;
      border-bottom-color: $form-border-color; } }

  .-claim-text textarea {
    border-radius: 0;
    border-bottom-color: transparent; }
  .-claim-label textarea {
    border-radius: 0;
    border-bottom-color: transparent; } }

.form-claims_grid {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form-claims_grid-item {
  flex: 0 0 auto;
  button {
    margin-left: 15px; }
  &.-claim-text {
    flex: 1 1 auto; }
  &.-claim-label {
    flex: 0 0 25%;
    textarea {
      border-left: none; } }
  textarea:focus {
    border-color: $form-border-color; } }

.form-claims_group {
  position: relative; }

.form-claims_label {
  position: absolute;
  font-size: 13px;
  color: $color-flavor-label;
  top: -30px;
  margin: 0; }

.control-label_tooltip {
  margin-left: 5px;
  margin-top: -2px; }

textarea.form-claims_textarea {
  resize: none;
  height: 80px;
  min-height: 60px;
  padding: 12px; }

textarea.has-error {
  border-color: $color-danger !important; }
