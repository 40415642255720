.admin-price-group {
  padding-bottom: 20px;
  margin-bottom: 16px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $form-border-color; } }

.form-pay_price {
  font-size: 30px;
  line-height: 1.3;
  height: $form-field-height;
  &.-no-height {
    height: auto; }
  &.-nsize {
    line-height: 1; }
  &.-green {
    color: $color-success; }
  &.-red {
    color: $color-danger; } }

.price-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.estimated-price {
  font-size: 11px; }
