.comparisons_grid-item {
  &.-two-columns-wrapper {
    position: relative;
    height: 280px;
    &.-no-title {
      height: 240px; } } }


.two-columns {
  font-family: $font-verizon-bold;
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  margin-top: 30px;

  .bar {
    position: absolute;
    width: 40%;
    border-radius: 4px; }

  .bar-1 {
    bottom: 0;
    left: 7%;
    background-color: $color-label; }

  .bar-2 {
    bottom: 0;
    right: 7%;
    background-color: $color-bar-gray; }

  .label {
    font-size: 18px;
    position: absolute;
    top: -26px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    text-align: center; } }
