$text-color: #051350;
$text-size: 14px;

.concepts-database {
  color: $text-color;
  font-size: $text-size;
  background-color: $bg-gray; }

.concepts-database_header-title {
  margin-top: 27px;
  max-width: 330px;
  color: $text-color; }

.concepts-database_header_buttons {
  width: 830px;
  display: flex;
  justify-content: space-between; }

.concepts-database_filter_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 38px;
  background: #E7EEF1;
  border-radius: 20px;
  border: none;
  color: #00AEEF;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: #E3EAED; }
  &:disabled {
    opacity: 50%;
    color: $color-gray; } }

.concepts-database_content {
  padding-left: 50px;
  height: 100vh;
  overflow-y: auto; }

.concepts-database_filter {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.concepts-database_filter-list {
  margin: 0 -4px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.concepts-database_filter-item {
  margin: 0 4px; }

.concepts-database_filter-legend {
  margin-top: 30px;
  margin-left: 8px;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 13px; }

.concepts-database_filter-legend-letter {
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  color: $color-danger;
  background-color: #fff;
  height: 18px;
  min-width: 18px;
  text-align: center;
  padding: 2px;
  border-radius: 18px; }

.concepts-database_filter-legend-text {
  color: #413C58;
  font-size: 12px;
  line-height: 1.2;
  margin-left: 5px; }

.concepts-database_header {
  min-height: 88px;
  border-bottom: 1px solid $color-line-light;
  display: flex;
  justify-content: space-between;
  .-last {
    margin-left: auto; } }


.concepts-database_submit_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;

  width: 546px;
  height: 42px;
  left: 246px;
  top: 345px;

  color: #FFF;
  background: #20BEE8;
  border-radius: 24px;
  border-width: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  &.-disabled {
    cursor: initial;
    color: #000;
    background-color: #DDE5E9; }
  &.-gray {
    color: #000;
    background: #DDE5E9; }
  &.-separate {
    margin-top: 29px; } }

.concepts-database_list {
  margin: 0;
  padding: 0;
  list-style: none; }

.concepts-database_item {
  & + & {
    margin-top: 8px; } }

.concepts-database_plate {
  margin-top: 10px;
  &.-blocked {
    border-color: $color-danger; }
  &.-reviewed {
    border-color: $color-success-darker; }
  &.-bulk {
    min-height: 400px; } }

.concepts-database_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 31px;
  &.-lg {
    height: 100%;
    margin-top: 50px; }
  &.-top-margin {
    margin-top: 220px; } }

.concepts-database_warning-icon {
  position: absolute;
  text-align: right;
  top: 8px;
  right: 8px; }

.concepts-database_selected_num {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  margin-left: 5px;
  margin-right: -10px;
  color: #fff;
  width: 29px;
  height: 29px;

  background: #FF6900;
  border-radius: 100px; }

.concept-database_button {
  display: flex;
  font-weight: bold;
  font-size: 13px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 123px;
  height: 34px;
  background: #65C18B;
  color: #FFF;
  border-radius: 17px;
  border: none;
  cursor: pointer;
  &.-include {
    background: #65C18B; }
  &.-exclude {
    background: #FD787A; }
  & + & {
    margin-left: 8px; } }

.concepts-database_loader {
  position: relative;
  top: 20%;
  left: 50%; }

.paste-detected {
  padding: 5px 10px;
  background: $color-success-darker;
  color: #fff;
  text-align: center; }

.concepts-database_plate-header {
  display: flex;
  background-color: #f3f6f8;
  padding: 20px;
  height: 127px;
  border-bottom: solid 1px #A9BFC7; }

.concepts-database_no-data-text {
  margin-top: 24px;
  font-weight: 700;
  font-size: 15px;
  text-align: center; }

.concepts-database_bulk_section {
  margin-top: 50px;
  padding-top: 28px;
  border-top: solid 1px #A9BFC7; }

.concepts-database_plate-body {
  background-color: #f3f6f8; }

.concepts-database_prop-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.concepts-database_prop-item {
  display: flex;
  & + & {
    margin-top: 8px; } }

.concepts-database_prop-title {
  font-size: 15px;
  line-height: 1.2;
  color: black;
  align-self: center;
  min-width: 218px;
  &.-concept_title {
    font-size: 24px;
    font-weight: 700;
    margin-left: 27px; }
  &.-footer {
    font-weight: 700;
    align-self: auto;
    margin-top: 18px; } }


.concepts-database_concept-image {
  align-self: center; }

.concepts-database_prop-text {
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap; }

.concepts-database_select_container {
  display: flex;
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap;
  min-height: 38px; }

.concepts-database_concepts_table_container {
  margin-top: 18px;
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap;
  min-height: 38px;
  overflow-x: auto; }

.concepts-database_concepts_table_settings {
  margin-top: 38px;
  display: flex; }

.concepts-database_metrics_container {
  display: flex;
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap;
  min-height: 38px;
  flex-wrap: wrap;
  & + & {
    margin-top: 8px; } }

.concepts-database_toggle_container {
  margin-top: 48px;
  display: flex;
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap;
  min-height: 38px; }

.concepts-database_select_element {
  width: 324px;
  min-height: 38px;
  &.-grow {
    width: 548px; }
  & + & {
    margin-left: 8px; } }

.concepts-database_btn_element {
  height: 38px;
  margin-left: 8px; }

.concepts-database_metrics_element {
  position: relative;
  gap: 6px;
  isolation: isolate;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 212.8px;
  min-height: 81px;

  background: #FFFFFF;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  &.-grow {
    width: 548px; } }

.concepts-database_metrics_element__title {
  padding: 10px;
  font-size: 15px; }

.concepts-database_metrics_element__value {
  margin-top: 16px;
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 28px;
  line-height: 26px;
  color: #00AEEF; }

.concepts-database_metrics_header {
  margin-top: 27px;
  margin-bottom: 8px;
  max-width: 330px;
  font-weight: 700;
  font-size: 18px; }

.concepts-database_four_options_toggle {
  width: 745px;
  min-height: 38px;
  &.-grow {
    width: 548px; }
  & + & {
    margin-left: 8px; } }

.concepts-database_concepts_table_toggle {
  width: 252px;
  min-height: 38px;
  &.-big {
    width: 320px; }
  &.-grow {
    width: 548px; }
  & + & {
    margin-left: 8px; } }

.concepts-database_top2box_toggle {
  margin-left: 74px;
  width: 289px;
  min-height: 38px;
  &.-grow {
    width: 548px; }
  & + & {
    margin-left: 8px; } }

.concepts-database_link {
  text-decoration: underline dotted; }

.concepts-database_info {
  margin-top: 34px;
  max-width: 330px; }

.concepts-database_info_block {
  margin-top: 12px;
  display: flex;
  width: fit-content;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  color: #97999F;
  height: 33px;

  background: #FFFFFF;
  border-radius: 100px; }

.concepts-database_concepts_table {
  max-height: 70vh;
  width: fit-content;
  border: 1px solid #CFDEE1;
  border-radius: 8px;
  .table {
    position: relative;
    font-family: $font-family-base;
    color: $color-gray;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    > tbody > tr:nth-child(even) > td {
      background-color: #E7EEF1; }
    > thead > tr > th {
      font-size: 14px;
      &.-ordered {
        color: #00AEEF; } }
    > tbody > tr > td {
      white-space: nowrap;
      font-size: 14px;
      font-weight: normal;
      color: #1C2960;
      &.-centered {
        text-align: center; }
      &.-clickable {
        cursor: pointer;
        &:hover {
          color: #00AEEF; } } } } }

.concepts-database_table_cell_flex {
  display: flex; }

.concepts-database_checkbox_block {
  display: flex;
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap; }

.concepts-database_checkbox {
  min-width: 220px;
  .checkbox {}
  display: inline-block;
  cursor: pointer;
  margin-top: 18px;

  input {
    position: fixed;
    opacity: 0; } }

.concepts-database_checkbox_label {
  margin-left: 27px; }

.concepts-database_notes {
  resize: none;
  height: 100%;
  min-height: 100%;
  width: 100%;
  border-radius: 50px; }

// footer

.concepts-database_plate-footer {
  display: flex;
  justify-content: flex-start;
  padding: 20px; }

.concepts-database_plate-footer-item {
  margin: 0 8px;
  flex: 0 0 auto;
  &.-space {
    flex: 1 1 auto; }
  &.-separator-left {
    padding-left: 16px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      height: 22px;
      border-left: 1px solid $color-base-gray;
      left: 0;
      top: 50%;
      margin-top: -11px; } } }

.concepts-database_plate-footer-label {
  background-color: $color-lightgray;
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  color: $color-text;
  padding: 10px;
  min-width: 58px;
  text-align: center;
  height: $button-height;
  border-radius: $button-height; }

.concepts-database_badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 13px;
  white-space: nowrap;

  height: 31px;

  background: rgba(32, 190, 232, 0.2);
  border-radius: 17px;
  margin-left: 8px; }

.concepts-database_badge-mark {
  margin-left: 16px;
  width: 2.34px;
  height: 6.59px;
  border: 2px solid #413C58;
  transform: rotate(45deg); }

.concepts-database_project-preview_status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  &.-unpublished {
    background: #F0F6D6;
    color: #90AA2C; }
  &.-in_field {
    background: #E4FAFB;
    color: #00B7BB; }
  &.-paused {
    background: #00B7BB;
    color: #E4FAFB; }
  &.-closed {
    background: #CCEFF9;
    color: #00AFE2; }
  &.-active {
    cursor: default;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid; }
  & + & {
    margin-left: 8px; }

  width: 111px;
  height: 31px;

  background: #E6EFD1;
  border-radius: 124px; }

.concepts-database_sort-link {
  color: inherit;
  position: relative;
  &:hover,
  &, &.-asc, &.-desc {
    color: #00AEEF;
    text-decoration: none; }
  &.-asc, &.-desc {
    &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 9px;
      right: -7px;
      top: 50%;
      margin-top: -4px;
      background: currentColor; }
    &:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 3px;
      right: -8px;
      top: 50%;
      margin-top: 0;
      border-left: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: rotate(-45deg); } }
  &.-asc {
    &:before {
      margin-top: -4px; }
    &:after {
      margin-top: -3px;
      transform: rotate(135deg); } }
  &.-desc {
    &:after {
      top: 58%; } } }

.concepts-database_table-text {
  display: initial;
  color: #1C2960; }

.concepts-database_concept {
  margin-top: 10px;
  width: 814px; }

.concept-database_submit_button {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;

  width: 380px;
  height: 42px;
  left: 246px;
  top: 345px;

  color: #FFF;
  background: #20BEE8;
  border-radius: 24px;
  border-width: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  &.-gray {
    color: #000;
    background: #DDE5E9; }
  & + & {
    margin-left: 8px; } }

.concepts-database_modal_title {
  font-size: 24px;
  font-weight: bold;
  margin: 23px 0 5px;
  flex-grow: 1; }

.concepts-database_modal_subtitle {
  font-size: 18px; }

.concepts-database_pagination-block {
  margin-top: 20px;
  text-align: center; }

.concepts-database_y-container {
 }  //position: relative

.concepts-database_table-loader {
  &.-with-background {
    position: absolute;
    z-index: 1000;
    opacity: 0.5;
    background-color: $color-body;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  &.-with-loader {
    position: relative;
    top: 150px;
    z-index: 10000;
    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 550px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      animation: load5 1.1s infinite ease;
      transform: translateZ(0); } } }

.concepts-database_filters {
  position: relative;
  margin-top: 12px;
  border: 1px solid #CFDEE1;
  border-radius: 8px;
  padding: 12px 12px 20px 12px;

  .logic-operator-between-section, .comparisons_plate, .logic-operator-between-update {
    background-color: $bg-gray; }

  h3 {
    color: $color-primary; }

  .close-icon {
    position: absolute;
    top: 2px;
    right: 8px;
    cursor: pointer;
    svg.icon {
      width: 12px;
      height: 12px; } } }

