.table-wrap {
  &.-viewport {
    overflow: auto;
    max-height: 100vh;

    &.-concept-comparison {
      max-height: calc(100vh - 250px);
      @include breakpoint-lo-height(600px) {
        max-height: 350px; }
      &.-overview {
        max-height: calc(100vh - 265px); } }

    &.-visible-overflow-width-scroll {
      overflow: visible;
      overflow-x: auto; } } }
