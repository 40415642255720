.-help-top {
  position: relative; }
.-help-on {
  &:hover {
    & + .-help {
      display: block; }
    .-help {
      display: block; } } }
.-help {
  display: none;
  position: absolute;
  z-index: 1099;
  left: 0;
  background: rgba(80,81,84,0.97);
  padding: 20px 25px;
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
  font-weight: normal;
  min-width: 290px;
  border-radius: 14px;
  white-space: normal;
  text-align: left;
  &.-margin-top {
    margin-top: 5px; }
  &.-right {
    left: auto;
    right: -9px;
    top: 0;
    margin-top: 30px; } }
