.express-types {
  padding: 30px 50px 50px;
  &.-modal {
    padding: 30px 10px; } }

.express-types_title {
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  margin: 0 auto 10px;
  text-align: center; }

.express-types_list {
  width: 100%;
  margin: 30px auto 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 825px;

  &.-even {
    .express-types_list-item {
      flex: 1 0 50%; } } }

.express-types_list-item {
  padding: 5px;
  flex: 0 0 100%; }

.express-types_list-item-inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row; }

.express-types_plate {
  background: #fff;
  border-radius: 14px;
  position: relative;
  padding: 25px;
  cursor: pointer; }

.express-types_plate-icon {
  color: $color-primary;
  padding-right: 25px;
  width: 90px; }

.express-plate-middle {
  display: flex;
  flex-direction: column; }

.express-types_plate-title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 10px; }

.express-types_plate-descr {
  color: $color-gray;
  font-size: 15px;
  line-height: 1.4;
  flex: 1 1 100%; }

.express-types_plate-buttons {
  font-size: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.express-types_plate-buttons-item {
  & + & {
    margin-left: 20px; } }

.express-types_plate-link {
  white-space: nowrap; }

.modal-dialog.-create-new-project {
  max-width: 1060px;
  &.-force-hide {
    display: none;
    opacity: 0;
    visibility: hidden; }
  .modal-content {
    background-color: $bg-gray; } }
