.sentiments_header {
  margin: 0 0 20px;

  .tile-header {
    padding-top: 2px; } }

.sentiments_content-scrollable {
  max-height: 620px;
  overflow: auto;
  margin-right: -15px;
  padding-right: 15px; }

.sentiments_content {
  .dropdown {
    display: block; } }

.sentiments_content-sidebar {
  svg text {
    cursor: pointer; } }


.sentiments-help {
  display: flex;
  justify-content: flex-end;
  padding: 0 16px; }

