.checkbox {
  display: inline-block;
  cursor: pointer;
  position: relative; // latest versions of Google Chrome interpret the absolute position of the input wrong - add block on below.
  input {
    //don't hide it using "display: none" or "vis: hidden" because error tooltip is not shown in this case
    position: absolute;
    opacity: 0; } }

.checkbox_label {
  padding-left: 30px;
  margin: 0;
  font-weight: 400;
  display: inline-block;
  position: relative;
  user-select: none;
  min-height: 22px;
  cursor: pointer;
  &.-bold {
    font-weight: bold; }
  &.-for-additional-answer, &.-for-non-answer, &.for-any {
    padding: 0;
    margin-top: 6px; }
  &.-disabled:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.1em;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px #97999F;
    margin-top: 1px;
    background: #fff; }
  &.-secondary {
    opacity: 0.6; }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.1em;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $color-primary;
    margin-top: 1px;
    background: #fff; }
  &.-secondary:before {
    box-shadow: inset 0 0 0 1px $color-primary; }
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 4px;
    border-left: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;
    transform: rotate(-45deg);
    position: absolute;
    left: 5px;
    top: 0.25em;
    margin-top: 5px;
    opacity: 0; }
  input:checked + &, &.-checked {
    &:after {
      opacity: 1; } }
  input:disabled + & {
    opacity: 0.6;
    cursor: default; }
  &.-centered {
    &:before {
      top: 50%;
      transform: translateY(-50%); }
    &:after {
      top: calc(50% - 0.4em); } }
  &.-empty {
    padding-left: 16px; }
  &.-grayed {
    &:before {
      box-shadow: inset 0 0 0 2px $color-neutral;
      background: none; }
    &:after {
      border-left: 2px solid $color-neutral;
      border-bottom: 2px solid $color-neutral;
      background: none; } }
  &.-no-before-top {
    &:before {
      top: 0; } } }
