.additional-screener_item {
  padding: 12px 0;
  &.-indent {
    padding-left: 25px; } }

.additional-screener_title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 8px; }

.additional-screener_description {
  font-size: 14px;
  line-height: 1.3; }

.additional-screener_checkbox {
  margin-top: 8px; }

