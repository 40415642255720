.tour-mode {
  .content {
    overflow: hidden; } }

$content-padding: 50px;

.content {
  padding: 0 $content-padding;
  height: 100vh;
  overflow-y: auto;
  &.-loading-wrapper {
    position: relative;
    min-height: 100vh; }
  &.-express {
    padding: 0;
    background: $bg-gray; }
  &.-relative {
    position: relative; }
  &.-grey-background {
    background-color: $bg-gray; }
  &.-no-overflow {
    overflow: hidden; }
  &.-body-scroll {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: flex-start;
    overflow: hidden;
    & > div {
      flex: 0 0 auto; }
    .content_body {
      flex: 1 1 auto;
      overflow-y: auto;
      padding-right: $content-padding;
      margin-right: -$content-padding; } }
  &.-two-third {
    height: 66vh;
    padding: 0; } }

.content_top {
  height: $page-header-height;
  border-bottom: 1px solid $color-line-light;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.-sticky {
    position: sticky;
    top: 0;
    z-index: $z-index-header;
    &.-with_lower_index {
      z-index: $z-index-lower-header; } }
  &.-express {
    padding: 0 50px; }
  &.-white {
    background: #fff;
    box-shadow: 50px 0 0 0 #fff, -50px 0 0 0 #fff;
    border-bottom: 0; }
  &.-gray {
    background: $bg-gray; } }

.content_part {
  padding: 25px 0; }

.content_body {
  padding: 25px 0;
  &.-express {
    padding: 0; }
  &.-relative {
    position: relative; }
  &.-before-loaded {
    position: relative;
    height: 100%; }
  &.-no-padding-top {
    padding-top: 0; } }

.content_description {
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 14px; }
