@mixin  button-reset {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none; }

.slick-slide {
  overflow: hidden; }

.slick-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  top: 100%;
  width: 100%;
  li {
    display: inline-block;
    vertical-align: bottom;
    margin: 5px;
    line-height: 0.5; }
  button {
    @include button-reset;
    text-align: left;
    text-indent: -1000px;
    overflow: hidden;
    position: relative;
    width: 14px;
    height: 14px;
    border-bottom: 1px solid $color-text; }
  .slick-active button {
    border-bottom-width: 3px; } }

.slick-next {
  @include button-reset;
  position: absolute;
  text-align: left;
  text-indent: -1000px;
  overflow: hidden;
  right: -34px;
  width: 24px;
  height: 24px;
  background-image: url('../assets/images/icons/express/arrow-next.svg');
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; }
.slick-prev {
  position: absolute;
  display: none;
  visibility: hidden;
  @include button-reset;
  text-align: left;
  text-indent: -1000px;
  overflow: hidden;
  left: -34px;
  width: 24px;
  height: 24px;
  background-image: url('../assets/images/icons/express/arrow-next.svg');
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  z-index: 2; }

.slick-slide, .slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

