.ui-select-highlight {
  font-weight: bold; }

.ui-select-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important; }

.ui-select-container {
  position: relative;
  user-select: none;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  vertical-align: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none; }

.ui-select-toggle {
  @extend .form-field;
  @extend select.form-field;
  > a.btn,
  > .caret {
    display: none !important; } }

.ui-select-toggle {
  position: relative;
  cursor: default;
  z-index: 1;
  display: block;
  width: 100%;
  outline: none;
  font-size: 14px;
  margin: 0;
  line-height: normal;
  -webkit-appearance: none;
  transition: border-color $transition;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  text-align: left;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMnB4IiBoZWlnaHQ9IjVweCIgdmlld0JveD0iMCAwIDEwIDUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzMC4wMDAwMDAsIC0xNi4wMDAwMDApIiBmaWxsPSIjNTk1QzVDIj4gICAgICAgICAgICA8cG9seWdvbiBpZD0iU2hhcGUiIHBvaW50cz0iMTI5Ljk3NDU0NSAxNiAxMzQuOTU2MzY0IDIxIDEzOS45MzgxODIgMTYiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  &, &:active, &:hover, &:focus {
    background-color: $color-page;
    border: 1px solid $color-border;
    padding: 9px 30px 9px 9px;
    border-radius: 12px;
    box-shadow: none;
    color: $color-text; }
  .ui-select-bootstrap.open & {
    border-color: $color-primary; } }

.ui-select-container:not(.ui-select-multiple) .ui-select-search {
  position: relative;
  cursor: default;
  z-index: 1;
  height: 36px;
  display: block;
  width: 100%;
  outline: none;
  font-size: 14px;
  text-overflow: ellipsis;
  margin: 0;
  line-height: normal;
  -webkit-appearance: none;
  transition: border-color $transition;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  text-align: left;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMnB4IiBoZWlnaHQ9IjVweCIgdmlld0JveD0iMCAwIDEwIDUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzMC4wMDAwMDAsIC0xNi4wMDAwMDApIiBmaWxsPSIjNTk1QzVDIj4gICAgICAgICAgICA8cG9seWdvbiBpZD0iU2hhcGUiIHBvaW50cz0iMTI5Ljk3NDU0NSAxNiAxMzQuOTU2MzY0IDIxIDEzOS45MzgxODIgMTYiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  &, &:active, &:hover, &:focus {
    background-color: $color-page;
    border: 1px solid $color-border;
    padding: 9px 30px 9px 9px;
    border-radius: 4px;
    box-shadow: none;
    color: $color-text; }
  .ui-select-bootstrap.open & {
    border-color: $color-primary; } }

.ui-select-placeholder {
  color: $color-placeholder; }

.ui-select-search-hidden {
  opacity: 0;
  height: 0;
  min-height: 0;
  padding: 0;
  margin: 0;
  border: 0; }

.ui-select-choices {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  background-color: $color-page;
  margin: 1px 0 0;
  padding: 0;
  text-align: left; }

.ui-select-choices ,.ui-select-no-choice {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  &:empty {
    display: none; } }

body > .ui-select-bootstrap.open {
  z-index: 1000; }

.ui-select-multiple .ui-select-search {
  position: relative;
  z-index: 1;
  display: block;
  width: 100% !important;
  font-size: 14px;
  text-overflow: ellipsis;
  padding: 9px 18px;
  background-color: #ffffff;
  margin: 0;
  min-height: 36px;
  border: 1px solid #E5E5E5;
  line-height: normal;
  border-radius: 18px;
  box-shadow: none;
  outline: none;
  color: #595C5C;
  transition: border-color 0.15s ease;
  &, &:focus, &:hover, &:active {
    outline: none;
    text-align: left; }
  &:focus {
    border-color: $color-primary; }
  &::placeholder {
    color: $color-placeholder; }
  &:focus::placeholder {
    color: transparent !important; }
  &[disabled] {
    border-color: $color-disabled;
    color: $color-disabled; }
  &::placeholder {
    color: $color-disabled; } }

.ui-select-search-hidden {
  display: none !important; }

.ui-select-match-item {
  white-space: nowrap;
  margin: 0 10px 10px 0;
  background: none;
  height: 36px;
  border: 1px solid #D7D7D7;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 18px;
  padding: 9px 13px 9px 18px;
  font-size: 14px;
  color: $color-text;
  .close {
    float: none;
    font-size: 21px;
    font-weight: normal;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    margin-left: 4px; }
  &:hover, &:active, &:focus {
    outline: none;
    box-shadow: none;
    color: $color-text;
    background: none;
    border-color: $color-primary;
    .close {
      color: $color-primary;
      opacity: 1; } } }

.ui-select-choices-row>span {
  cursor: pointer;
  display: block;
  padding: 10px;
  clear: both;
  color: $color-text;
  white-space: nowrap;
  font-size: 14px; }

.ui-select-choices-row>span:hover, .ui-select-choices-row>span:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5; }

.ui-select-choices-row.active>span {
  color: $color-page;
  text-decoration: none;
  outline: 0;
  background-color: $color-primary; }

.ui-select-choices-row.disabled>span,
.ui-select-choices-row.active.disabled>span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff; }


/* fix hide/show angular animation */
.ui-select-match.ng-hide-add,
.ui-select-search.ng-hide-add {
  display: none !important; }


/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid > button.ui-select-match {
  border-color: #D44950; }


.ui-select-match-text {
  width: 100%;
  padding-right: 1em;
  float: none !important;
  text-align: left; }

.ui-select-match-text span {
  display: inline-block;
  width: 100%;
  overflow: hidden; }

.ui-select-refreshing {
  display: none !important; }

//multi select with [album and] images (on collage-report page, for example)

.ui-select-container {
  &.-with-images {
    display: inline-block;
    background: #fff;
    border-radius: 18px;
    border: 1px solid #E5E5E5;
    height: 38px;
    transition: border-color 0.15s ease;
    &.open {
      border-color: $color-primary; }
    .ui-select-match-item {
      border: 1px solid #E5E5E5;
      margin: 3px;
      height: 30px; }
    .ui-select-search {
      display: inline-block;
      width: 40px !important;
      padding: 8px;
      border: none;
      background: none;
      &::placeholder {
        color: #595C5C; } }

    .ui-select-match {
      &:empty {
        & + .ui-select-search {
          width: 100% !important;
          padding: 9px 18px; } } } } }


.ui-select-choices {
  &.-with-images {
    width: 375px;
    max-height: 300px;
    padding: 5px 15px;
    list-style: none;
    .ui-select-choices-row {
      display: inline-block;
      vertical-align: top;
      & > span {
        padding: 5px; }
      &.active > span {
        background-color: #fff;
        &:hover, &:focus {
          background-color: #f5f5f5; } } } } }

.ui-select-choices_img-group-name {
  font-size: 14px;
  font-weight: 600;
  color: #A7A7A7;
  padding: 10px 0 5px;
  // for start image list from new line
  width: 320px; }

.ui-select-choices_img-inline {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  height: 70px;
  background-color: #EDEDED;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    opacity: 0;
    visibility: hidden; } }
