.q_wrapper {
  background: #EFF4F5;
  border-radius: 20px;
  &.-margin {
    margin: 10px 0 40px; } }

.q_tabs {
  position: relative;
  margin: 3rem 0;
  height: 14.75rem; }
.q_tabs::before,
.q_tabs::after {
  content: "";
  display: table; }

.q_tabs::after {
  clear: both; }

.q_tab {
  float: left; }

.q_tab-switch {
  display: none; }

.q_tab-label {
  position: relative;
  //border-radius: 5px 5px 0px 0px
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-right: 0.125rem solid #EFF4F5;
  border-left: 0.125rem solid #EFF4F5;
  border-top: 0.125rem solid #EFF4F5;
  color: #000;
  cursor: pointer;
  top: 0;
  transition: all 0.25s; }

.q_tab-label:hover {
  top: -0.25rem;
  transition: top 0.25s; }

.q_tab-content {
  position: absolute;
  z-index: 1;
  left: 0;
  color: #2c3e50;
  opacity: 0;
  transition: all 0.35s; }

.q_tab-switch:checked + .tab-label {
  background: #fff;
  color: #2c3e50;
  border-bottom: 0;
  border-right: 0.125rem solid #fff;
  transition: all 0.35s;
  z-index: 1;
  top: -0.0625rem; }

.q_tab-switch:checked + label + .q_tab-content {
  z-index: 2;
  opacity: 1;
  transition: all 0.35s; }


.tab-panel__header {
  position: relative;
  top: 1px; }

.tab-panel {
  margin-bottom: 20px; }

.tab-panel__content {
  background-color: #EFF4F5;
  //border: 1px solid #9099A2
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  &.-with-left-top-corner {
    border-top-left-radius: 20px; } }

.tab {
  background-color: #fff;
  border: 1px solid #EFF4F5;
  border-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #96858F;
  cursor: pointer;
  display: inline-block;
  padding: 6px 24px;
  position: relative;
  z-index: 0;
  &.-disabled {
    opacity: .4;
    pointer-events: none;
    cursor: not-allowed; } }

//.tab:nth-child(n+2)
//  margin-left: -8px

.tab:hover {
  transition: top 0.25s; }

.tab-label {
  font-size: 14px; }

.tab--selected {
  background-color: #EFF4F5;
  color: #6D7993;
  padding-top: 6px;
  z-index: 1;
  pointer-events: none; }
