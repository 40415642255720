.survey-cloud {
  height: 350px;
  padding-top: 18px;
  margin-top: 0;
  padding-bottom: 1px; }

.survey-cloud_link {
  font-family: $font-family-base;
  display: inline-block;
  height: 1.4em;
  background-color: transparent;
  border-radius: 0;
  color: $color-primary;
  line-height: 1.4;
  font-weight: normal;
  border: dashed 1px $color-primary;
  border-width: 0 0 1px;
  padding: 0;
  outline: none;
  cursor: pointer;
  letter-spacing: .5px;
  text-decoration: none;
  font-size: 12px;
  margin: 0 0 10px; }

.survey-cloud_action {
  text-align: center;
  margin: 15px 0; }

.survey-cloud_button {
  @extend .button.-sm; }

.survey-cloud_existing svg {
  display: block;
  //max-width: 100%
  overflow: visible;

  @include breakpoint-hi($screen-lg) {
    margin: 0 auto;
    transform: none; }

  text {
    color: #919aa9;
    fill: #919aa9; } }

.survey-cloud_existing.-horizontal svg {
  //transform: scale(0.8) translateX(-32%) translateY(20%)
  //transform: scale(0.6) translateX(-40%) translateY(20%)
  transform: scale(0.45) translateX(-68%) translateY(-30%); }

.survey-cloud_existing.-vertical svg {
  //transform: scale(0.6) translateX(-10%) translateY(-52%)
  //transform: scale(1) translateX(-9%) translateY(-15%)
  transform: scale(0.5) translateX(-49%) translateY(-65%); }

// Empty

.survey-cloud_empty {
  text-align: center;
  letter-spacing: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -60px 0 0;

  .tile_subtile.-first & {
    right: 23px; }

  .tile_subtile.-second & {
    left: 23px; } }

.survey-cloud_empty-icon {
  width: 88px;
  height: 48px;
  margin: 0 0 15px; }

.survey-cloud_empty-txt {
  display: block;
  font: normal 20px/1.4 $font-family-base;
  color: #9dafbb;
  margin: 0 0 10px; }

.survey-cloud_orientation-icon {
  width: 13px;
  height: 13px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;

  @include breakpoint-lo($screen-lg - 1px) {
    display: none; } }

.sentiments_content .wordcloud-kind-switcher {
  display: none; }
