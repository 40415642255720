.keywords-control {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: -5px 0;
  padding: 0 15px 25px 0;
  //min-height: 34px
  width: 100%; }

.keywords-control_main {
  flex-grow: 1;
  padding-right: 15px;
  margin: 5px 0; }

.keywords-control_addon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 5px 0; }

.keywords-control_addon-child {
  + .keywords-control_addon-child {
    margin: 0 0 0 20px; } }
