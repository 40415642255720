.express-footer {
  text-align: center;
  padding: 30px 0 40px;
  margin: 0 50px;
  border-top: 1px solid $color-line-light; }

.express-footer_text {
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 15px;
  &.-bold {
    font-weight: bold; } }

.express-footer_button {
  .button {
    min-width: 160px; } }
