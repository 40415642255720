.form-name_image {
  position: relative;
  width: 100px;
  height: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  .-pointer, &.-pointer {
    cursor: pointer; }

  &.-no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px; }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%; }

  .page-loader:before {
    transform: translateZ(0) scale(0.5); } }

.form-names {
  background-color: #fff;
  border-radius: 12px;
  padding: 0 15px 15px 30px;
  margin-top: 20px; }

.form-names_list {
  padding: 0;
  margin: 0;
  list-style: none; }

.form-names_field {
  min-height: 42px;
  border-color: $form-border-color-dark;
  &:focus {
    border-color: $form-border-color-dark; } }

.form-names_item.-new {
  padding: 15px 0 0;
  &.-no-items {
    padding: 40px 0 0; }
  .-name-text .form-field {
    border-radius: 7px; } }


.form-names_item.-ready {
  padding: 0;
  &:not(:first-child) {
    margin-top: -1px; }

  .form-field {
    min-height: 52px; }

  &.-one {
    &:first-child, &:last-child {
      .-name-text .form-field {
        border-radius: 7px; } } }

  &:first-child {
    padding: 40px 0 0;
    .-name-text .form-field {
      border-radius: 7px 7px 0 0; } }

  &.-last-child {
    .-name-text .form-field {
      border-radius: 0 0 7px 7px;
      border-bottom-color: $form-border-color-dark; } }

  .-name-text .form-field {
    border-radius: 0;
    border-bottom-color: transparent; } }

.form-names_grid {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form-names_grid-item {
  flex: 0 0 auto;
  button {
    margin-left: 15px; }
  &.-name-text {
    flex: 1 1 auto; } }

.form-names_group {
  position: relative; }

.form-names_label {
  position: absolute;
  top: -20px;
  font-size: 13px;
  color: #787F93; }


.control-label_tooltip {
  margin-left: 5px;
  margin-top: -2px; }


.form-field.has-error {
  border-color: $color-danger !important; }
