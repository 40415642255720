.verizon-modifier {
  .report-title {
    &.-no-wrap {
      white-space: nowrap; } }

  &.-research {
    &.content {
      background-color: $va-white;
      color: $va-black;
      min-width: 900px;
      font-size: 14px;
      @include font-normal;
      scroll-behavior: smooth;

      .content_top {
        background-color: $va-white;
        position: sticky;
        top: 0;
        // 1px — to hide outer border of focused search input if user start to scroll it
        padding: 0 1px;
        margin: 0 -1px;
        // to show dropdown of this block over below sticky menu
        z-index: $z-index-header + 1; }

      .content_body {
        padding: 0 0 50px;
        &.vcc-scorecard {
          padding: 20px 0 30px; }
        &.-projects {
          padding: 0 0 30px; } }

      .header_title {
        @include font-bold; } }

    h1, h2, h3 {
      @include font-bold; }

    .low-base-size {
      color: $va-red; }
    .note-message {
      color: $va-dark-grey-text; }

    hr {
      border-top-color: $va-brand;
      &.-gray {
        border-top-color: $va-gray-light-2; }
      &.-transparent {
        border-color: transparent; } }

    .form-field {
      &:disabled {
        background-color: $va-white; } }

    .radio-tabs {
      background-color: $va-stone;

      .radio-tabs_label:hover {
        background-color: $va-stone-darker; } }

    .button {
      &.-bordered-white {
        &:hover:not([disabled]) {
          background: $va-stone; } } }

    .comparisons_intro-messages {
      margin-bottom: 5px;
      line-height: 1.5; }
    // comparisons
    .comparisons_intro-grid-item {
      & + .comparisons_intro-grid-item {
        margin-left: 24px; } }

    .comparisons_intro-plate {
      flex-direction: column;
      align-items: flex-start;
      line-height: 1.4;
      min-height: 0;
      min-width: 0;
      text-align: left;
      padding: 10px 14px;
      color: $va-black;
      &.-bordered {
        border: 1px solid $va-gray-light-2; }
      &.-bg-red {
        background-color: $va-brand;
        color: $va-white;
        border: none; }
      &.-bg-stone {
        background-color: $va-stone;
        border: none; }
      &.-colored {
        background-color: $va-brand;
        color: $va-white;
        border: none; }
      &.-center {
        text-align: center;
        align-items: center; } }

    .comparisons_intro-plate-value {
      font-size: 16px;
      @include font-bold;
      &.-big {
        font-size: 24px;
        text-transform: uppercase; } }

    .comparisons_intro-plate-note {
      font-size: 14px;
      @include font-normal; }

    .comparisons_intro-text {
      line-height: 1.3; }

    .comparisons_plate {
      background-color: $va-stone;
      border-radius: 0;
      border: 1px solid transparent;
      padding: 22px 24px;
      &.-bordered {
        background-color: $va-white;
        border-color: $va-stone;
        &.-dark {
          border-color: $va-black; } }
      &.-compact {
        padding: 20px 16px; }
      img {
        max-height: 400px;
        display: block;
        margin: auto; } }


    .comparisons_plate-top {
      margin: -22px -24px 22px;
      padding: 7px 24px;
      font-size: 14px;
      @include font-bold;
      line-height: 1.2;
      &.-center {
        text-align: center; }
      &.-colored {
        background: $va-brand;
        color: white; } }

    .comparisons_plate-title {
      @include font-bold;
      font-size: 16px;
      margin-bottom: 10px;
      text-transform: capitalize;
      &.-md {
        font-size: 20px;
        margin-bottom: 20px; }
      &.-margin-bottom-sm {
        margin-bottom: 5px; }
      &.-margin-bottom-md {
        margin-bottom: 20px; }
      &.-sm {
        font-size: 14px; }
      &.-bigger {
        font-size: 18px; }
      &.-no-transform {
        text-transform: none; }
      &.-bordered {
        border-bottom: 1px solid $va-brand;
        padding-bottom: 20px;
        margin-bottom: 20px;
        min-height: (16px * 1.2) + 20px;
        &.-md {
          padding-bottom: 25px;
          margin-bottom: 30px;
          min-height: (20px * 1.2) + 25px; } }
      &.-colored {
        color: $va-brand; } }

    .stimuli-preview {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .cit-concept {
        max-width: none; } }

    .comparisons_plate-note {
      @include note-style;
      &.-black {
        @include note-black-style; }
      line-height: 1.2;
      margin-bottom: 20px;
      &.-margin-bottom-lg {
        margin-bottom: 40px; }
      &.-center {
        text-align: center; }
      &.-sm {
        font-size: 13px; }
      &.-xs {
        font-size: 12px; }
      &.-xxs {
        font-size: 10px; }
      &:last-child {
        margin-bottom: 0; }
      &.-margin-top {
        margin-top: 20px; }
      &.-small-margin-top {
        margin-top: 10px; }
      &.-tiny-margin-top {
        margin-top: 5px; }
      &.-margin-left {
        margin-left: 14px; } }

    .comparisons_plate-p {
      font-size: 14px;
      line-height: 1.2;
      & + .comparisons_plate-p {
        margin-top: 24px; }
      b, strong {
        @include font-bold; } }

    .comparisons_plate-text {
      font-size: 14px;
      line-height: 1.2;
      p + p {
        margin-top: 10px;
        &.space-md {
          margin-top: 20px; } }
      b, strong {
        @include font-bold; }
      ul, ol {
        display: block;
        margin: 12px 0;
        padding: 0 0 0 1.5em; }
      li {
        padding-left: 0.5em;
        margin: 12px 0; }
      .red {
        color: $va-red; }
      .orange {
        color: $va-orange; }
      .green {
        color: $va-green; } }

    .comparisons_plate-section {
      & + .comparisons_plate-section {
        margin-top: 24px; } }

    .comparisons_plate-section-title {
      @include font-bold;
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 6px; }

    .comparisons_filters {
      margin: 35px 0; }

    .summary-panel-wrapper {
      margin-top: 20px; }

    // report list
    ul.reports-list {
      background-color: $va-white;
      @include font-bold;
      li span a {
        &.-export {
          color: #fff;
          background-color: $va-black;
          text-wrap: nowrap; } } } }

  &.-comparison {
    &.content {
      min-width: 900px;
      font-size: 14px;
      @include font-normal; }

    .radio-tabs {
      background-color: $va-stone;

      .radio-tabs_label:hover {
        background-color: $va-stone-darker; } }

    .button {
      &.-bordered-white {
        &:hover:not([disabled]) {
          background: $va-stone; } } }

    .form-field {
      @include font-bold; }


    .projects_pagination {
      .page-link {
        &:hover, &:active, &:focus {
          background-color: $va-stone; } }
      .page-item.active {
        .page-link {
          &:hover, &:active, &:focus {
            background-color: $va-black; } } } } } }
