.modal-dialog.-min-width {
  min-width: 488px; }

.normal-popup.-long-items {
  .header-grid-item:first-child {
    width: 270px; }
  .header-item:first-child {
    width: 220px; }
  .readonly-title {
    width: 192px; } }

.question-list {
  width: 100%;
  &.-with-401-width {
    width: 100%; } }

a.quotas {
  margin-left: 8px;
  border-bottom: 1px dashed;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.1;
  &:hover {
    border-bottom: 1px dashed;
    text-decoration: none;
    opacity: 0.6; } }

.questions-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 20px; }

.question-item {
  min-width: 240px;
  flex: 1 1 auto;
  width: calc(50% - 30px);
  margin-right: 30px;
  @include horizontal-880 {
    margin-bottom: 10px; }
  h3 {
    margin-bottom: 10px; } }

.quota-error-message {
  width: 100%;
  padding: 5px 20px 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: $color-danger;
  color: #fff;
  &.-with-bottom-margin {
    margin-bottom: 20px; }

  div + div {
    margin-top: 5px; } }

.quota-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  label {
    min-width: 50px;
    display: inline-block;
    margin-right: 10px; }
  input {
    flex-grow: 1; }
  &:last-child {
    margin-bottom: 0; }
  &.-bigger {
    label {
      min-width: 75px; } } }

// Updated
.question-headers {
  margin-top: 24px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &.-with-401-width {
    width: 401px; } }

.header-item {
  &:first-child {
    flex: 0 0 auto;
    width: 170px; }
  &.-no-checkbox {
    max-width: 147px; }
  &:last-child {
    flex: 0 0 auto; }
  &.-middle-item {
    flex: 0 0 auto;
    width: 220px; }
  &.-big {
    width: 270px; } }

.-list-right-margin {
  margin-right: 5px; }

.question-list-item {
  min-height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
  & + & {
    margin-top: 10px; }

  .readonly-title {
    width: 142px;
    margin-right: 5px;
    display: block;
    line-height: 16px;
    flex: 0 0 auto;
    .title-inner-list {
      font-size: 10px; }
    &.-auto-width {
      width: auto;
      margin-right: 0;
      flex: 0 1 auto; }
    &.-bordered {
      padding: 10px;
      margin-right: 35px;
      width: 234px;
      background-color: #F3F6F8;
      border-radius: 20px; } } }

.question-list-checkbox {
  display: block;
  width: 18px;
  min-width: 18px;
  label.checkbox_label {
    width: 18px; } }

.question-list-input {
  min-width: 72px;
  max-width: 140px;
  flex: 0 0 auto;
  &.-middle-item {
    margin-right: 80px; }
  &[disabled] {
    background-color: #EFF3F3;
    color: #EFF3F3;
    border: 1px solid #C6D8DB;
    &.-readonly {
      background-color: transparent;
      border: none;
      color: $color-text; } }
  &.-grid {
    max-width: 200px; } }

.form-question_grid-item.-buttons {
  button {
    margin-left: 0;
    margin-right: 5px;
    &:last-child {
      margin-right: 0; } } }

.form_plate-title.-with-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center; }

.header-grid-item {
  &:first-child {
    width: 270px; } }

.quota-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
