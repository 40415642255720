$tooltip-arrow-width: 8px;

.tooltip {
  position: absolute;
  z-index: $z-index-tooltip;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  opacity: 0;
  visibility: visible;
  &.in {
    opacity: .9; }
  &.top {
    padding: $tooltip-arrow-width 0;
    margin-top: -3px; }
  &.right {
    padding: 0 $tooltip-arrow-width;
    margin-left: 3px; }
  &.bottom {
    padding: $tooltip-arrow-width 0;
    margin-top: 3px; }
  &.left {
    padding: 0 $tooltip-arrow-width;
    margin-left: -3px; }
  &.-dark-baloon {
    padding: 0;
    opacity: 1;
    .tooltip-arrow {
      display: none; }
    .tooltip-inner {
      padding: 7px 15px;
      border-radius: 20px;
      background-color: #505154;
      color: #fff; } } }


.tooltip-inner {
  max-width: 300px;
  padding: 15px 20px;
  color: $color-text;
  text-align: center;
  text-decoration: none;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: $dropdown-shadow;
  img {
    max-width: 250px;
    max-height: 250px; }
  &.-no-max-width {
    max-width: none; } }


.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-top-color: #FFF;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0; }
  &.top-left .tooltip-arrow {
    bottom: 0;
    right: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-top-color: #FFF;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0; }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-top-color: #FFF;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0; }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -$tooltip-arrow-width;
    border-right-color: #FFF;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0; }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -$tooltip-arrow-width;
    border-left-color: #FFF;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width; }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-bottom-color: #FFF;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width; }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-bottom-color: #FFF;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width; }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-bottom-color: #FFF;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width; } }

// Custom tooltip content

.tooltip-inner_content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start; }

.tooltip-inner_icon-wrap {
  flex-grow: 0;
  flex-shrink: 0; }

.tooltip-inner_icon {
  display: inline-block;
  margin-bottom: -4px;
  &.-menu-downloads {
    color: $color-active; } }

.tooltip-inner_text {
  flex-grow: 1;
  padding: 0 15px 0 10px;
  cursor: pointer; }

.tooltip-inner_buttons {
  flex-grow: 0;
  flex-shrink: 0; }

.tooltip-inner_button {
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
  padding: 0;
  margin: 0;
  background: none;
  & + & {
    margin-left: 10px; } }

// tooltip for express (with image)

.tooltip {
  &.-express-tooltip-preview {
    &.-shelf-concept {
      .tooltip-inner img {
        max-width: 100%;
        max-height: 200px;
        width: auto;
        height: auto;
        text-align: center; } }

    .tooltip-arrow {
      display: none; }
    &.in {
      opacity: 1 !important;
      z-index: 9000 !important; }
    .tooltip-inner {
      background-color: $question-type-bg-color;
      max-width: none;
      border-radius: 20px;
      padding: 15px 1px 1px;
      .popup-title {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        text-align: left;
        padding: 0 20px;
        max-width: 300px;
        margin-bottom: 5px;
        &.-no-description {
          margin-bottom: 15px; } }
      .popup-description {
        color: $question-description-color;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        padding: 0 20px;
        max-width: 300px;
        margin-bottom: 15px;
        line-height: 1.6;
        &.-no-title {
          color: #fff; } }
      img {
        max-width: 100%;
        border-radius: 0 0 20px 20px;
        max-height: none;
        word-break: break-all;
        &.-with-max-height-250 {
          max-height: 250px; } } }

    &.-md {
      .popup-title, .popup-description {
        max-width: 382px; } }
    &.-md2 {
      .popup-title, .popup-description {
        max-width: 352px; } } } }
