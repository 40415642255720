.sort-link {
  color: inherit;
  position: relative;
  &:hover, {
    &, &.-asc, &.-desc {
      color: $color-link-hover;
      text-decoration: none; } }
  &.-current {
    color: $color-text; }
  &.-asc, &.-desc {
    color: $color-text;
    &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 6px;
      right: -7px;
      top: 50%;
      margin-top: -4px;
      background: currentColor; }
    &:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 3px;
      right: -8px;
      top: 50%;
      margin-top: 0;
      border-left: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: rotate(-45deg); } }
  &.-asc {
    &:before {
      margin-top: -2px; }
    &:after {
      margin-top: -3px;
      transform: rotate(135deg); } } }

.projects_list_table {
  .sort-link {
    &.-asc {
      &:before {
        margin-top: 0;
        top: auto;
        bottom: 4px; }
      &:after {
        margin-top: 0;
        top: auto;
        bottom: 8px; } }
    &.-desc {
      &:before {
        margin-top: 0;
        top: auto;
        bottom: 4px; }
      &:after {
        margin-top: 0;
        top: auto;
        bottom: 3px; } } } }

