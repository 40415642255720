.drag-drop-element {
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #FFFFFF;
  background: rgba(101, 193, 139, 0.4);
  cursor: grab;
  .drag-drop-element-name {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    text-align: center;
    background-color: #00AEEF;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    line-height: 8px;
    vertical-align: bottom;
    padding: 3px 4px;
    height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; } }
