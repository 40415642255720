$text-color: #051350;
$text-size: 14px;

.review-respondents {
  color: $text-color;
  font-size: $text-size;
  background-color: $bg-gray; }

// filter

.review-respondents_filter {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.review-respondents_filter-list {
  margin: 0 -4px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.review-respondents_filter-item {
  margin: 0 4px; }

.review-respondents_filter-legend {
  margin-left: 8px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.review-respondents_filter-legend-letter {
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  color: $color-danger;
  background-color: #fff;
  height: 18px;
  min-width: 18px;
  text-align: center;
  padding: 2px;
  border-radius: 18px; }

.review-respondents_filter-legend-text {
  color: #413C58;
  font-size: 12px;
  line-height: 1.2;
  margin-left: 5px; }


// respondents list

.review-respondents_list {
  margin: 0;
  padding: 0;
  list-style: none; }

.review-respondents_item {
  & + & {
    margin-top: 8px; } }

.review-respondents_plate {
  border: 1px solid #A9BFC7;
  box-shadow: 0 3px 10px rgba(83, 122, 167, 0.15);
  border-radius: 14px;
  overflow: hidden;
  &.-blocked {
    border-color: $color-danger; }
  &.-reviewed {
    border-color: $color-success-darker; } }

.paste-detected {
  padding: 5px 10px;
  background: $color-success-darker;
  color: #fff;
  text-align: center; }
.review-respondents_plate-body {
  background-color: #fff;
  padding: 16px; }

.review-respondents_openends-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.review-respondents_openends-item {
  & + & {
    margin-top: 25px; } }

.review-respondents_openends-title {
  font-weight: bold;
  font-size: 11px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #A8A6B3; }

.review-respondents_openends-text {
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap; }

// footer

.review-respondents_plate-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 -8px;
  padding: 16px;
  background-color: #E5ECEE; }

.review-respondents_plate-footer-item {
  margin: 0 8px;
  flex: 0 0 auto;
  &.-space {
    flex: 1 1 auto; }
  &.-separator-left {
    padding-left: 16px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      height: 22px;
      border-left: 1px solid $color-base-gray;
      left: 0;
      top: 50%;
      margin-top: -11px; } } }

.review-respondents_plate-footer-label {
  background-color: $color-lightgray;
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  color: $color-text;
  padding: 10px;
  min-width: 58px;
  text-align: center;
  height: $button-height;
  border-radius: $button-height; }


