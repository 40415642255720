.warning-icon {
  position: sticky;
  padding-top: 50px;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
  border-radius: 60px;
  width: 60px;
  height: 60px;
  margin: auto;
  border: 5.33333px solid #A9BFC7; }

.warning-icon-inside {
  position: absolute;
  width: 5.33333px;
  height: 40%;
  left: 45%;
  top: 15%;
  background: #A9BFC7;
  border-radius: 3px; }

.warning-icon-point-inside {
  position: absolute;
  width: 5.33333px;
  height: 11%;
  left: 45%;
  top: 70%;
  background: #A9BFC7;
  border-radius: 3px; }

.warning_title {
  font-size: 26px;
  line-height: 1.4;
  flex-grow: 1;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px; }

.warning-text {
  font-size: 14px;
  line-height: 1.4;
  flex-grow: 1;
  text-align: center;
  &.-red {
    color: red; } }

.warning_footer {
  text-align: center;
  padding-top: 25px; }

.warning_footer-item {
  & + & {
    padding-top: 25px; } }
