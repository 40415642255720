.link {
  cursor: pointer;
  color: $color-link;
  text-decoration: none;
  outline: none;
  padding: 0;
  border: 0;
  background: none;
  &:hover,
  &:focus {
    color: $color-link-hover;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    &.-no-hover {
      color: $color-link; } }
  &.-bold {
    font-weight: bold; }
  &.-gray {
    color: $color-link-gray;
    &:hover,
    &:focus {
      color: $color-text; } }
  &.-delete {
    color: $color-danger; }
  &.-decorated {
    text-decoration: underline;
    color: $color-link-hover; }
  &.-normal-weight {
    font-weight: normal; }
  &.-text {
    color: $color-text;
    text-decoration: none;
    &:hover {
      color: $color-link; } } }

