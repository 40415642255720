.filters-results {
  margin-top: 10px; }

.filter-item {
  & + & {
    margin-top: 3px; } }

.control-panel {
  position: absolute;
  right: 7px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center; }

.colored {
  font-weight: bold;
  &.-and {
    color: #9CCC54 !important; }
  &.-or {
    color: #FF6900 !important; } }

.logic-operator-between-section {
  font-size: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 58px; }
.logic-operator-between-update {
  position: relative;
  background-color: $color-secondary;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  padding: 7px 7px;
  border-radius: 96px;
  &:before, &:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 7px;
    background: $color-secondary; }
  &:before {
    top: -7px; }
  &:after {
    bottom: -7px; }

  .logic-operator-switcher {
    margin-right: 5px; } }

.logic-operator-switcher {
  background: $bg-chosen-multi-item;
  border-radius: 80px;
  padding: 3px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  &.-reverse-colors {
    background-color: #fff;
    span {
      &.-active {
        background: $bg-chosen-multi-item; } } }
  &.not-switcher {
    margin-left: 10px;
    margin-right: 0;
    font-size: 13px;
    min-width: 114px;
    & + span {
      margin-left: 10px; }
    span {
      color: $color-primary;
      &.-active {
        color: $color-text; }
      &:first-child {
        min-width: 50px;
        justify-content: center; } } }
  span {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 1px 6px;
    cursor: pointer;
    &.-active {
      background: #fff;
      border-radius: 70px; } } }

.logic-operator-between-plus {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 0;
  overflow: visible; }

.cross-to-add-circle {
  position: relative;
  top: 0;
  background: #fff;
  border-radius: 96px;
  width: 27px;
  height: 27px;
  border: 3px solid $color-secondary;
  z-index: 2; }

.cross-to-add {
  position: relative;
  font-size: 1px;
  width: 22px;
  height: 22px;
  &:before, &:after {
    position: absolute;
    display: block;
    content: '';
    top: calc(50% - 2px);
    left: calc(50% - 8px);
    width: 15px;
    height: 3px;
    background-color: #B0D238;
    border-radius: 96px; }
  &:after {
    transform: rotate(-90deg); } }

.remove-section {
  cursor: pointer;
  padding: 9px;
  border-radius: 100%;
  background-color: $bg-chosen-multi-item;
  &.-reverse-colors {
    background-color: #fff; }
  .cross-to-remove {
    position: relative;
    font-size: 1px;
    width: 12px;
    height: 12px;
    &:before, &:after {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      left: 50%;
      width: 15px;
      height: 3px;
      background-color: #FF6900;
      margin-left: -7px;
      margin-top: -1px; }
    &:before {
      transform: rotate(45deg); }
    &:after {
      transform: rotate(-45deg); } } }
