.radio {
  display: inline-block;
  cursor: pointer;
  input {
    //don't hide it using "display: none" or "vis: hidden" because error tooltip is not shown in this case
    // and it's need for right tabulation
    position: absolute;
    opacity: 0; } }

.radio_label {
  padding-left: 30px;
  margin: 0;
  font-weight: 400;
  display: inline-block;
  position: relative;
  user-select: none;
  min-height: 22px;
  cursor: pointer;
  &.-no-text {
    padding-left: 22px;
    vertical-align: middle; }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    box-shadow: inset 0 0 0 2px $color-primary;
    background: #fff; }
  &.-centered {
    &:before {
      top: 50%;
      transform: translateY(-50%); } }

  input:checked + & {
    &:before {
      box-shadow: inset 0 0 0 8px $color-primary; } }

  input:disabled + & {
    opacity: 0.3;
    cursor: default; } }

.radio_label_hidden-text, .checkbox_label_hidden-text {
  //don't hide it using "display: none" or "vis: hidden" because screen reader doesn't see it in this case
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0; }
