.demographics, .demographics.-tile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .form_grid-item {
    float: none !important; }
  .-country {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .form_plate-title {
      margin-bottom: 10px; } } }
