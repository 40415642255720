.express-choose {
  padding: 15px 20px; }

.express-choose_title {
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  margin: 0 auto 10px;
  text-align: center; }

.express-choose_list {
  margin: 35px -10px 0;
  display: flex;
  align-items: stretch;
  justify-content: center; }

.express-choose_list-item {
  margin: 0 10px;
  color: inherit !important; }

.express-choose_list-item-inner {
  height: 100%; }

.express-choose_radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0; }

.express-choose_item {
  max-width: 280px;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(83, 122, 167, 0.15);
  border-radius: 14px;
  padding: 170px 35px 35px 35px;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.15s linear, background-color 0.15s linear;
  &:hover {
    box-shadow: 0px 3px 10px rgba(83, 122, 167, 0.25);

    background-color: $color-primary;
    box-shadow: none;
    color: #fff;
    .express-choose_item-descr, .express-choose_item-icon {
      color: #fff; } } }

.express-choose_item-icon {
  position: absolute;
  color: $color-primary;
  top: 45px;
  left: 0;
  right: 0;
  margin: auto; }

.express-choose_item-title {
  font-size: 26px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 10px; }

.express-choose_item-descr {
  color: $color-gray;
  font-size: 15px;
  line-height: 1.4;
  text-align: left; }

.espress-choose_footer {
  margin-top: 35px;
  text-align: center;
  .button {
    min-width: 140px; } }

