.-max-screener-and-custom-metrics-width {
  max-width: 750px; }

.form {
  display: block;
  & + & {
    margin-top: 30px; } }

.form_group {
  &.-relative {
    position: relative; }
  &.has-error {
    border: 1px solid red;
    border-radius: 20px; } }

.form_section {
  &.-margin-5-between + &.-margin-5-between {
    margin-top: 5px; }
  & + & {
    margin-top: 30px;
    &.-sm-space {
      margin-top: 10px; } }
  &.-padding-bottom {
    padding-bottom: 20px; }
  &.-combined {
    padding-bottom: 10px;
    border-bottom: 1px solid #CFDEE1; }
  &.-follow-up {
    padding-bottom: 10px;
    & + & {
      border-top: 1px solid #CFDEE1; } }
  &.-section-relative {
    position: relative; }
  &.-multi-options {
    margin-top: 20px; }
  &.-claims-body {
    max-width: 900px; }
  &.-names-body {
    max-width: 615px; }
  &.-flavor-body {
    &.-no-top-margin {
      margin-top: 0; }
    max-width: 700px; }
  &.-package-body {
    max-width: 900px; }
  &.-flexible {
    display: flex; }
  &.-restrict-width {
    max-width: 700px; }
  &.-hide {
    display: none; } }

.form_section-title {
  margin-bottom: 10px;
  &.-no-margin {
    margin-bottom: 0; }
  &.-grid {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  &.-grid-between {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &.-custom-controls {
    align-items: end;
    .form_section-title {
      margin-bottom: 7px; }
    .-mr {
      margin-right: 8px; } } }

.left-part, .right-part {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form_section-title-block {
  flex: 0 0 auto; }

.form_section-title-item {
  flex: 0 0 auto;
  min-width: 0;
  &.-text {
    flex: 0 1 auto;
    & > h3 {
      @include truncate; } }
  &.-custom-metrics {
    flex: 0 1 auto; }
  &.-custom-v_cat-metrics {
    flex: 0 1 30%; }
  & + & {
    margin-left: 8px; }
  &.-metric-info {
    position: absolute;
    top: 14px;
    right: -24px; }
  &.-grid {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  &.-third {
    width: 33%; }
  &.-disabled {
    color: #97999F; }
  &.-order-button {
    margin-bottom: 12px; }
  &.-bordered {
    border-top: 1px solid #747776;
    margin-top: 20px;
    padding-top: 20px; } }

.form_section-hint-over {
  font-size: 14px;
  margin-bottom: 22px; }

.form_section-text {
  font-size: 15px;
  line-height: 1.6;
  &.-width-md {
    max-width: 500px; }
  &.-margin-bottom {
    margin-bottom: 10px; }
  &.-margin-left {
    margin-left: 10px; }
  & + & {
    margin-top: 20px; }
  & + &.-no-top-margin {
    margin-top: 0; }
  &.-with-top-margin {
    margin-top: 20px; }
  &.-center {
    text-align: center; }
  &.-inline {
    display: inline; }
  &.-bold {
    font-weight: bold; } }

.form_section-tabs {
  margin: 20px 0;
  &.-max-50vw {
    max-width: calc(50vw); }
  &.-no-bottom-margin {
    margin-bottom: 0; }
  &.-sm-margin {
    margin: 8px 0; } }

.form_section-line {
  & + & {
    margin-top: 20px; } }

.form_group-line {
  & + & {
    margin-top: 15px; } }

.form_grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -11px;
  &.-no-top-margin {
    margin-top: 0; }
  &.-cols-2 {
    .form_grid-item {
      flex-basis: 50%;
      width: 50%; } }
  &.-plates {
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
  &.-tile {
    display: block;
    overflow: hidden;
    .form_grid-item {
      float: left; } }
  &.-right {
    float: right; }
  &.-padding-left {
    padding-left: 40px; }
  &.-v-middle {
    align-items: center; }
  &.-spaces-t-b {
    margin-top: 20px;
    margin-bottom: 20px; } }

.form_grid-item {
  flex: 0 0 auto;
  padding: 12px;
  &.-width-1-2 {
    flex-basis: 50%;
    width: 50%; }
  &.-width-1-3 {
    flex-basis: 33%;
    width: 33%; }
  &.-width-2-3 {
    flex-basis: 66%;
    width: 66%; }
  &.-width-2-5 {
    flex-basis: 40%;
    width: 40%; }
  &.-width-full {
    flex-basis: 100%;
    width: 100%; }
  &.-grow {
    flex: 1 1 auto; }
  &.-right {
    text-align: right; }
  &.-two-icons {
    width: 116px; }
  &.-relative {
    position: relative;
    overflow: visible; }
  &.-multi-elements {
    flex: 1 1 auto; }
  &.-select-options {
    position: absolute;
    width: 100%;
    background-color: $bg-chosen-form-gray;
    z-index: 100;
    top: 48px;
    box-shadow: 0 0 10px -1px rgba(0,0,0,0.2);
    border-radius: 5px; }
  &.-no-top-padding {
    padding-top: 0; }
  &.-no-padding {
    padding: 0; }
  &.-not-available {
    .form_plate {
      background-color: $color-danger;
      padding-top: 35px; }
    .delete {
      color: #fff; }
    .not-available-question-label {
      position: absolute;
      color: #fff;
      left: 50%;
      transform: translate(-50%, 0);
      border: 1px solid #fff;
      border-top: 0 solid;
      padding: 4px 10px 5px 10px;
      border-radius: 0 0 6px 6px;
      line-height: 1;
      min-width: 322px;
      display: block;
      text-align: center; }
    .form_plate-title {
      color: #fff; }
    .quotas {
      color: #fff; } }
  &.-border-bottom {
    border-bottom: 1px solid $color-line-light; }
  &.has-error {
    border: 1px solid $color-danger;
    border-radius: 8px; } }

.form_grid-item-inner {
  &.has-error {
    border: 1px solid $color-danger;
    border-radius: 8px; } }

.form_plate {
  height: 100%;
  border: 1px solid $form-border-color;
  border-radius: $form-border-radius;
  padding: 20px; }

.form_plate-title {
  color: $color-base-gray;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px; }

.form_concept-list {
  margin: -8px; }

.form_concept-item {
  margin: 8px;
  display: inline-block;
  vertical-align: top; }

.form_metrics {
  font-size: 15px;
  max-width: 400px;
  &.-md {
    max-width: 570px; }
  &.-inner {
    // 30px - space for checkbox
    padding-left: 30px;
    padding-right: 40px;
    max-width: none; }
  & + & {
    margin-top: 15px; }
  &.wider {
    max-width: 550px; } }


.form_metrics-item {
  padding: 10px 0;
  position: relative;
  & + & {
    border-top: 1px solid $color-line-light; }
  &.-input {
    padding-left: 15px; }
  &.-no-border {
    border-top: 0;
    padding-top: 0; }
  &.-compact {
    border-top: 0;
    padding-top: 0;
    &:first-child {
      padding-top: 8px; } }
  &.-inner {
    margin-left: 18px; }
  &.-border-bottom {
    border-bottom: 1px solid $color-line-light; }
  .-border-bottom {
    padding-bottom: 14px;
    border-bottom: 1px solid $color-line-light; }
  .toggle {
    display: inline; } }

.form_metrics-hint {
  font-size: 14px;
  &.-align-checkbox {
    padding-left: 30px; } }

.form_section-list {
  margin: 0;
  padding: 0;
  list-style: none;
  &.-no-bullet {
    li {
      &:before {
        content: none; } } }
  li {
    &:before {
      content: "—";
      margin-right: 5px; }
    & + li {
      margin-top: 3px; } } }

.form-other-input {
  max-width: 300px;
  margin: 0 0 10px; }
.form_inline-select {
  position: relative;
  display: inline-block;
  &.-none {
    width: 53px; }
  &.-asc {
    width: 85px; }
  &.-desc {
    width: 92px; }
  &.-all {
    width: 40px; }
  &.-specific, &.-random {
    width: 140px; }
  &.-reduce-width {
    // make space to arrow less
    select {
      padding-right: 9px; } }
  &.-no-arrow {
    &:after {
      content: none; } }
  select {
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    color: $color-link;
    cursor: pointer;
    font-weight: bold;
    padding: 0 17px 0 5px;
    position: relative;
    z-index: 2; }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-link;
    border-bottom: none;
    right: 5px;
    top: 50%;
    z-index: 1; }

  &.-inline-n-concepts {
    margin-right: 6px;
    &:after {
      right: -6px; }
    &.-more-letters {
      margin-right: 13px;
      &:after {
        right: -12px; } }
    select {
      padding-right: 0; } } }

.form_plate.-breaks {
  .checkbox-multi {
    padding-top: 17px; }
  .checkbox-multi + .checkbox-multi {
    padding: 34px 0 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 17px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $form-border-color; } }

  .form_plate-title {
    margin-bottom: 0; }
  .checkbox_label {
    cursor: pointer; } }

.checkbox-multi + .checkbox-solo {
  padding: 34px 0 10px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 17px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $form-border-color; } }

.checkbox-solo {
  line-height: 2;
  label {
    cursor: pointer;
    &:after {
      top: 0.3em; } } }

.custom-metric-questions {
  margin-top: 20px;
  margin-bottom: 40px;
  .form-items-list {
    margin-bottom: 15px; } }


.colored-title {
  color: $color-primary;
  text-transform: uppercase; }

.colored-header {
  color: $color-primary; }

.form-group.has-error label {
  border: 1px solid $color-danger; }
