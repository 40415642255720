.optional-demographics {
  margin-top: 30px;
  margin-bottom: 30px;
  .delete {
    color: $color-danger;
    position: absolute;
    right: 26px;
    top: 19px; } }

.form-block-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center; }

.form-title-with-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;
  h3 {
    margin: 0; }
  a {
    margin-left: 10px;
    font-size: 15px; } }
