
.concept-checker-list {
  padding: 0;
  margin: 0;
  list-style: none; }


.concept-checker-list_item {
  padding: 10px 0;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  background: #fff;
  & + & {
    margin-top: -1px; }
  &:first-child {
    //when drag - show border-top
    &:not(.ui-sortable-helper) {
      border-top: none; } }
  &:last-child {
    //when drag - show border-top
    &:not(.ui-sortable-helper) {
      border-bottom: none; } } }

.concept-checker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  &.-header {
    padding: 10px 19px 10px 0;
    background-color: $color-secondary;
    border-color: $color-secondary;
    align-items: flex-start;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: calc(100% + 20px);
      height: 1px;
      left: -10px;
      bottom: 0;
      background-color: $color-flavor-label; } } }

.concept-checker_item {
  flex: 0 0 auto;
  &.-grow {
    flex: 1 1 auto; }
  & + & {
    margin-left: 20px; } }

.concept-checker_title {
  font-size: 15px;
  word-break: break-word; }

.concept-checker_move {
  cursor: pointer;
  color: #BBD1D6; }
