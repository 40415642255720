.ui-notification {
  position: fixed;
  z-index: 9999;
  width: 300px;
  transition: all ease $transition;
  color: $color-page;
  border-radius: 4px;
  background-color: $color-primary;
  padding: 5px; }

.ui-notification.clickable {
  cursor: pointer; }

.ui-notification.clickable:hover {
  opacity: .7; }

.ui-notification.killed {
  transition: opacity ease 1s;
  opacity: 0; }

.ui-notification > h3 {
  font-size: 15px;
  font-weight: bold;
  display: block;
  margin: 10px 10px 0 10px;
  padding: 0 0 10px 0;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, .3); }

.ui-notification a {
  color: $color-page;
  text-decoration: underline; }

.ui-notification > .message {
  font-size: 15px;
  line-height: 1.4;
  padding: 12px 40px 12px 10px;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: currentColor;
    right: 21px;
    top: 21px;
    transform: rotate(45deg); }
  &:before {
    width: 2px;
    height: 12px;
    margin: -6px -1px 0 0; }
  &:after {
    width: 12px;
    height: 2px;
    margin: -1px -6px 0 0; } }

.ui-notification.warning {
  background: $color-warning; }

.ui-notification.error {
  background: $color-danger; }

.ui-notification.success {
  background: $color-success; }

.ui-notification.info {
  background: #5bc0de; }
