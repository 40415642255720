// tags-input directive, migrated from concept_confirmit/blocks/tags
// E.g. in filter on /dashboard/projects page and in Tags input dropdown there

.tags-container {
  margin: -4px 0 0;
  display: inline-block;
  vertical-align: top;
  //text-align: centertags
  max-width: 100%;
  position: relative;
  padding-right: 70px; }

.tags-container_close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    width: 10px;
    height: 2px;
    background-color: #768193;
    margin: -1px 0 0 -5px; }
  &:before {
    transform: rotate(45deg); }
  &:after {
    transform: rotate(-45deg); }

  &:hover {
    background-color: #768193;
    &:before,
    &:after {
      background-color: #fff; } } }

.tags-container_dropdown {
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  transition: all 0.15s ease;
  position: absolute;
  top: -14px;
  margin: 0 -17px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $dropdown-shadow;
  padding: 15px 46px 15px 15px;

  .open > & {
    visibility: visible;
    opacity: 1; } }

.tag-view {
  display: inline-block;
  padding: 4px 15px;
  border-radius: 15px;
  background-color: rgba(255,255,255,.6);
  color: $color-text;
  font-size: 13px;
  margin: 4px 4px 0 0;
  box-shadow: inset 0 0 0 1px #D7D7D7; }

.tag-action {
  display: inline-block;
  cursor: pointer;
  background: none;
  padding: 4px;
  color: $color-primary;
  font-size: 14px;
  font-weight: bold;
  margin: 4px 4px 0;
  font-family: $font-family-base;
  border: none;
  outline: none;
  //position: absolute
  //top: 0
  //right: 0

  &:hover {
    color: #2a6496;
    text-decoration: underline; } }

filter-tags-input {
  display: inline-block;
  vertical-align: middle; }

tags-input {
  display: block;
  outline: 0;

  .host {
    outline: 0;
    position: relative;
    min-width: 140px; }

  .tags {
    overflow: hidden;
    word-wrap: break-word;
    cursor: text;
    outline: 0;

    &.focused {
      outline: 0; }

    .tag-list {
      margin: -1px 0 9px 2px;
      padding: 0;
      list-style-type: none;

      &:empty {
        margin: 0; } }

    .tag-item {
      display: inline-block;
      vertical-align: top;
      padding: 5px 28px 5px 15px;
      border-radius: 15px;
      background-color: transparent;
      color: $color-text;
      font-size: 14px;
      font-weight: normal;
      margin: 4px 4px 0 0;
      box-shadow: inset 0 0 0 1px #D7D7D7;
      position: relative;
      overflow: hidden;

      &:before,
      &:after {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        right: 9px;
        width: 10px;
        height: 2px;
        background-color: #c5c6c6;
        margin: -1px 0 0 -5px; }
      &:before {
        transform: rotate(45deg); }
      &:after {
        transform: rotate(-45deg); }

      &:hover {
        background-color: $color-primary;
        box-shadow: none;
        color: #fff;
        &:before,
        &:after {
          background-color: #fff; } }

      .remove-button {
        position: absolute;
        display: block;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        outline: none;
        padding: 0;
        border: none;
        cursor: pointer;
        overflow: hidden;
        text-indent: -999em;
        border-radius: 15px;
        background-color: transparent;
        opacity: 0; } }

    .input {
      //@extend .form-field
      //@extend .form-field.-inline
      min-width: 150px;
      min-height: 32px;
      font-family: $font-family-base;
      font-size: 14px;
      border: 0;
      outline: 0;
      margin: 0;
      padding: 0 0 0 10px;
      float: left;
      width: 100%;

      &.invalid-tag {
        color: #e4494e; }

      &::-ms-clear {
        display: none; }

      &::placeholder {
        color: #768193; } } }

  &[disabled] {

    .host:focus {
      outline: 0; }

    .tags {
      background-color: #eee;
      cursor: default;

      .tag-item {
        opacity: .65;

        .remove-button {
          cursor: default;

          &:active {
            color: #585858; } } }

      .input {
        background-color: #eee;
        cursor: default; } } }

  &:not(.-filter) {
    .tags {
      .input {
        @extend .form-field;
        border-radius: 18px;
        width: 100% !important; } } }

  &.-filter {

    .tags {
      padding: 2px 2px;
      min-height: $form-field-height;
      background: #fff;
      border: 1px solid $color-border;
      border-radius: 18px;
      transition: border-color 0.3s, color 0.3s, background 0.3s, background-color 0.3s;

      &:after {
        content: '';
        display: table;
        clear: left; }

      &.focused {
        border-color: $color-primary; }

      .tag-list {
        display: inline;

        &:empty {
          + .input {
            padding-left: 15px; } } }

      .tag-item {
        float: left;
        margin: 2px 2px; }

      .input {
        color: $color-text;
        float: left;
        margin: 0;
        padding: 0 15px 0 5px;
        background: none;
        width: auto; } }

    &.-translucent {
      .tags {
        background-color: rgba(255, 255, 255, .7);
        border-color: transparent;

        &.focused {
          border-color: $color-primary; } }

      .tag-item {
        background-color: #fff;

        &:hover {
          background-color: $color-primary; } } }

    &.-no-border {
      .tags {
        border-color: transparent; } } }

  .autocomplete {
    margin-top: 5px;
    position: absolute;
    padding: 0;
    z-index: 999;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: $dropdown-shadow;

    .suggestion-list {
      @extend .selector-list; }

    .suggestion-item {

      em {
        font-style: normal;
        font-weight: bold; }

      &:hover,
      &.selected {
        color: #fff;
        background-color: $list-highlight-bg; } } } }
