.summary-editor {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: flex-start;
  height: 100%;
  .form-label {
    margin-bottom: 7px; }
  .form-field {
    @include font-normal;
    &.has-error {
      border: 1px solid red; } }
  textarea.form-field {
    min-height: 84px;
    max-height: 200px; } }

.modal-dialog.-summary-editor {
  height: 100%;
  overflow: hidden;
  max-width: none;
  padding: 20px 25px;
  .modal-content {
    height: 100%;
    overflow: hidden;
    border: 1px solid $va-black;
    box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 0; }
  .normal-popup {
    height: 100%; }
  &.w50p {
    height: 50%; } }

.modal-dialog.-visibility-groups {
  padding: 20px 25px;
  .modal-content {
    border: 1px solid $va-black;
    box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 0; }
  .normal-popup {
    height: 100%; } }

.modal-backdrop.-summary-editor, .modal-backdrop.-visibility-groups {
  background: none; }

.summary-editor_header {
  flex: 0 0 auto;
  padding-bottom: 16px; }

.summary-editor_body {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;
  padding-left: 2px;
  margin-left: -2px; }

.summary-editor_title {
  font-size: 18px;
  @include font-bold;
  color: $va-coral;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 1px solid $va-coral;
  &.express {
    color: $va-black;
    border-bottom: 1px solid $va-gray-medium; } }

.summary-editor_tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: normal;
  margin: -2px;
  padding: 0;
  list-style: none; }

.summary-editor_tab {
  margin: 2px;
  flex: 0 0 auto; }

.summary-editor_tab-button {
  @include reset-button-style;
  background-color: $va-stone;
  color: $va-black;
  @include font-bold;
  font-size: 15px;
  line-height: 1.2;
  padding: 16px;
  height: 52px;
  min-width: 52px;
  &.-active {
    background-color: $va-coral;
    color: $va-white; }
  &.-add {
    background-color: $va-black;
    color: $va-white;
    text-align: center;
    font-size: 17px; } }

.summary-editor_form {
  padding: 20px 24px;
  border: 1px solid $va-stone;
  &.express {
    border: 1px solid $va-gray-medium;
    border-radius: 10px; } }

.summary-editor_form-line {
  & + & {
    margin-top: 20px; } }

.summary-editor_form-grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: -12px; }

.summary-editor_form-group {
  margin-top: 5px;
  margin-bottom: 20px; }

.summary-editor_form-grid-item {
  flex: 1 1 100%;
  padding: 12px; }

.summary-editor_feedback {
  margin-top: 16px; }

.summary-editor_feedback-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px; }

.summary-editor_feedback-title {
  font-size: 15px;
  @include font-bold; }

.summary-editor_feedback-nums {
  font-size: 15px;
  @include font-normal; }




.summary-editor_feedback-plate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $va-stone;
  color: $va-black;
  padding: 16px;
  gap: 10px 20px;
  margin-bottom: 2px;
  &.-bordered {
    background-color: transparent;
    border: 1px solid $va-stone; } }

.summary-editor_feedback-plate-item {
  flex: 1 1 auto;
  &.-buttons {
    flex: 0 0 auto; } }

.summary-editor_feedback-plate-title {
  font-size: 14px;
  line-height: 1.2; }

.summary-editor_form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 8px;
  &.-right {
    justify-content: flex-end; }
  .button + .button {
    margin-left: 0; } }

.summary-editor_feedback-select {
  margin-top: 14px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $va-stone; }

.summary-editor_feedback-select-list {
  padding: 0;
  margin: 0;
  list-style: none; }

.summary-editor_feedback-select-field {
  margin-bottom: 14px; }

.summary-editor_footer {
  flex: 0 0 auto;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid $va-coral;
  &.-flex {
    display: flex;
    justify-content: space-between; }
  &.express {
    padding-top: 0;
    border-top: none; } }

.summary-editor_footer-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  &.-right {
    justify-content: flex-end; }
  .button + .button {
    margin-left: 0; } }

.form_grid-item.-with-select {
  position: relative;
  .icon {
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 24px;
    right: 54px;
    z-index: 999;
    color: red;
    cursor: pointer; } }

