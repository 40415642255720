$text-color: #051350;
$text-size: 14px;

.concept-tags {
  color: $text-color;
  font-size: $text-size;
  background-color: $bg-gray; }

.concept-tags_header {
  min-height: 88px;
  width: 814px;
  border-bottom: 1px solid $color-line-light;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: $bg-gray;
  padding-bottom: 10px;
  z-index: 100; }

.concept-tags_header-title {
  margin-top: 27px;
  max-width: 330px;
  &.-no-max-width {
    max-width: none; } }

.concept-tags_header-button {
  margin-top: 27px;
  &.copy {
    display: inline-block;
    margin: 0 0 0 10px;
    font-size: 10px;
    padding: 6px;
    height: auto; } }

.concept-tags_content {
  padding-left: 50px;
  height: 100vh;
  overflow-y: auto;
  &.-with-bottom-padding {
    padding-bottom: 80px; }
  &.-with-right-limits {
    padding-right: 50px;
    max-width: 100%;
    min-width: 1104px; } }

.concept-tags_filter {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.concept-tags_filter-list {
  margin: 0 -4px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.concept-tags_filter-item {
  margin: 0 4px; }

.concept-tags_filter-legend {
  margin-top: 30px;
  margin-left: 8px;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 13px; }

.concept-tags_filter-legend-letter {
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  color: $color-danger;
  background-color: #fff;
  height: 18px;
  min-width: 18px;
  text-align: center;
  padding: 2px;
  border-radius: 18px; }

.concept-tags_filter-legend-text {
  color: #413C58;
  font-size: 12px;
  line-height: 1.2;
  margin-left: 5px; }

.concept-tags_submit_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;

  width: 546px;
  height: 42px;
  left: 246px;
  top: 345px;

  color: #FFF;
  background: #20BEE8;
  border-radius: 24px;
  border-width: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  &.-disabled {
    cursor: initial;
    color: #000;
    background-color: #DDE5E9; }
  &.-gray {
    color: #000;
    background: #DDE5E9; }
  &.-separate {
    margin-top: 29px; } }

.concept-tags_list {
  margin: 0;
  padding: 0;
  list-style: none; }

.concept-tags_item {
  & + & {
    margin-top: 8px; } }

.concept-tags_plate {
  border: 1px solid #A9BFC7;
  box-shadow: 0 3px 10px rgba(83, 122, 167, 0.15);
  border-radius: 14px;
  margin-top: 10px;
  width: 814px;
  min-height: 553px;
  &.-blocked {
    border-color: $color-danger; }
  &.-reviewed {
    border-color: $color-success-darker; }
  &.-bulk {
    min-height: 400px; } }

.concept-tags_loader {
  position: relative;
  top: 20%;
  left: 50%; }

.paste-detected {
  padding: 5px 10px;
  background: $color-success-darker;
  color: #fff;
  text-align: center; }

.concept-tags_plate-header {
  border-radius: 14px 14px 0 0;
  display: flex;
  background-color: #f3f6f8;
  padding: 20px;
  height: 127px; }

.concept-tags_bulk-header {
  margin: 20px auto 7px 20px;
  display: flex;
  width: 88px;
  height: 17px;
  left: 19px;
  top: 15px;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;

  color: #20BEE8; }

.concept-tags_bulk_section {
  margin-top: 50px;
  padding-top: 28px;
  border-top: solid 1px #A9BFC7; }

.concept-tags_plate-body {
  background-color: #f3f6f8;
  padding: 30px;
  border-top: solid 1px #A9BFC7;
  &.-with_bottom_radius {
    border-radius: 0 0 14px 14px; } }


.concept-tags_prop-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.concept-tags_prop-item {
  display: flex;
  & + & {
    margin-top: 8px; } }

.concept-tags_prop-title {
  font-size: 15px;
  line-height: 1.2;
  color: black;
  align-self: center;
  min-width: 218px;
  &.-concept_title {
    font-size: 24px;
    font-weight: 700;
    margin-left: 27px; }
  &.-footer {
    font-weight: 700;
    align-self: auto;
    margin-top: 18px; } }


.concept-tags_concept-image {
  align-self: center; }

.concept-tags_prop-text {
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap; }

.concept-tags_select_container {
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap;
  width: 270px;
  min-height: 38px;
  &.-grow {
    flex: 1 1 auto; }
  & + & {
    margin-left: 8px; } }

.concept-tags_checkbox_block {
  display: flex;
  color: $text-color;
  font-size: $text-size;
  line-height: 1.5;
  white-space: pre-wrap; }

.concept-tags_checkbox {
  min-width: 220px;
  .checkbox {}
  display: inline-block;
  cursor: pointer;
  margin-top: 18px;

  input {
    position: fixed;
    opacity: 0; } }

.concept-tags_checkbox_label {
  margin-left: 27px; }

.concept-tags_notes {
  resize: none;
  height: 100%;
  min-height: 100%;
  width: 100%;
  border-radius: 50px; }

// footer

.concept-tags_plate-footer {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  border-top: solid 1px #A9BFC7; }

.concept-tags_plate-footer-item {
  margin: 0 8px;
  flex: 0 0 auto;
  &.-space {
    flex: 1 1 auto; }
  &.-separator-left {
    padding-left: 16px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      height: 22px;
      border-left: 1px solid $color-base-gray;
      left: 0;
      top: 50%;
      margin-top: -11px; } } }

.concept-tags_plate-footer-label {
  background-color: $color-lightgray;
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  color: $color-text;
  padding: 10px;
  min-width: 58px;
  text-align: center;
  height: $button-height;
  border-radius: $button-height; }

.concept-tags_badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 13px;
  white-space: nowrap;

  height: 31px;

  background: rgba(32, 190, 232, 0.2);
  border-radius: 17px;
  margin-left: 8px; }

.concept-tags_badge-mark {
  margin-left: 16px;
  width: 2.34px;
  height: 6.59px;
  border: 2px solid #413C58;
  transform: rotate(45deg); }

.concept-tags_project-preview_status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  &.-unpublished {
    background: #F0F6D6;
    color: #90AA2C; }
  &.-in_field {
    background: #E4FAFB;
    color: #00B7BB; }
  &.-paused {
    background: #00B7BB;
    color: #E4FAFB; }
  &.-closed {
    background: #CCEFF9;
    color: #00AFE2; }
  &.-active {
    cursor: default;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid; }
  & + & {
    margin-left: 8px; }

  width: 111px;
  height: 31px;

  background: #E6EFD1;
  border-radius: 124px; }
