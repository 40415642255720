.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  user-select: none;
  text-align: left;
  width: 100% !important;
  &.-low-index {
    z-index: 1; } }

.chosen-container .chosen-choices, .chosen-container .chosen-single {
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-chosen-active;
    border-bottom: none;
    right: 18px;
    top: 44%; }

  &.-no-dropdown {
    cursor: default;
    z-index: 2;
    padding-right: 140px;
    &:after {
      content: none; } } }

.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  z-index: 1010;
  width: 100%;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100% 100%);
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  background-color: $color-page;
  margin-bottom: 20px; }

.chosen-container.chosen-with-drop .chosen-drop {
  clip: auto;
  clip-path: none; }

.chosen-container a {
  cursor: pointer; }

.chosen-container .search-choice .group-name, .chosen-container .chosen-single .group-name {
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #999999; }

.chosen-container .search-choice .group-name:after, .chosen-container .chosen-single .group-name:after {
  content: ":";
  padding-left: 2px;
  vertical-align: top; }

//.chosen-container-single .chosen-single
//  text-decoration: none
//  position: relative
//  cursor: default
//  z-index: 1
//  display: block
//  width: 100%
//  outline: none
//  font-size: 14px
//  //padding: 10px 30px 10px 9px
//  padding: 9px 30px 9px 9px
//  height: 34px
//  background-color: $color-page
//  margin: 0
//  border: 1px solid $color-border
//  border-radius: 4px
//  box-shadow: none
//  color: $color-text
//  -webkit-appearance: none
//  transition: border-color $transition
//  line-height: 1

//.summary-markup_form .chosen-single span
//  height: 15px


.chosen-container-single .chosen-default {
  color: $color-placeholder; }

.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 10px;
  white-space: nowrap; }

.chosen-container-single .chosen-search input[type="text"] {
  @extend .form-field;
  width: 100%; }

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100% 100%); }

.chosen-container .chosen-results {
  color: $color-text;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  max-height: 240px;
  -webkit-overflow-scrolling: touch; }

.chosen-container .chosen-results li {
  display: none;
  margin: 0;
  padding: 10px;
  list-style: none;
  word-wrap: break-word;
  -webkit-touch-callout: none; }

.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer; }

.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
  &:hover {
    color: #ccc;
    background: $color-page; } }

.chosen-container .chosen-results li.highlighted, .chosen-container .chosen-results li:hover {
  color: #262626;
  background-color: #f5f5f5; }

.chosen-container .chosen-results li.no-results {
  color: #777;
  display: list-item;
  background: #f4f4f4; }

.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default; }

.chosen-container .chosen-results li.group-option {
  padding-left: 15px; }

.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline; }

.chosen-container-active .chosen-single {
  border-color: $color-primary; }

.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default; }

.chosen-disabled .chosen-single {
  cursor: default; }

.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default; }

.chosen-container-multi {
  .search-field {
    min-width: 80%;
    &.-full {
      min-width: 100%; } } }

.chosen-container-multi .chosen-choices, .chosen-container-single .chosen-single {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: text;
  display: block;
  outline: none;
  font-size: 14px;
  background-color: #ffffff;
  min-height: 38px;
  border: 1px solid $color-border;
  line-height: normal;
  border-radius: 18px;
  box-shadow: none;
  color: $color-chosen-active;
  transition: border-color 0.15s ease; }

.chosen-container-single .chosen-single {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  &.-default-cursor {
    cursor: default; }
  &.-disabled {
    opacity: .4; } }

.chosen-container-active .chosen-choices {
  border-color: $color-primary; }

.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none; }

.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap; }

.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  margin-left: 10px;
  //padding: 5px 18px
  height: 36px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none;
  border-radius: 0;
  width: 100% !important; }

.chosen-container-multi .chosen-choices li.search-field .default {
  color: #999; }

.chosen-container-multi .chosen-choices li.search-choice {
  position: relative;
  margin: 3px;
  height: 30px;
  line-height: 30px;
  padding: 0 30px 0 15px;
  border-radius: 15px;
  background-color: $bg-chosen-multi-item;
  background-clip: padding-box;
  //box-shadow: inset 0 0 0 1px $color-border
  color: $color-text;
  cursor: default;
  overflow: hidden;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  align-content: center;
  &.colored {
    padding: 0;
    background: #fff; }
  &.-title {
    background: #fff;
    padding: 0;
    margin: 3px 7px 3px 10px;
    display: flex;
    border-radius: 0;
    align-items: center;
    align-content: center;
    max-width: 50%;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1;
      &.-big-one {
        font-size: 7px; }
      &:first-child {
        font-weight: bold; } } }
  &.-between {
    background: #fff;
    padding: 0;
    margin: 3px 7px 3px 10px; }
  &.-input {
    background: #fff;
    padding: 0;
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
      width: 50px;
      text-align: center;
      height: 28px;
      border-radius: 70px;
      border: 1px solid $bg-chosen-multi-item;
      line-height: 1;
      &.-invalid {
        border-color: $color-danger;
        &:focus {
          border-color: $color-danger; } }
      &:focus {
        outline: none;
        border-color: #00AFE2; } } }

  &.-at-least-item {
    padding: 0 12px;
    cursor: pointer;
    &.-active, &:hover {
      background: #A9C3CA; } }

  .tag {
    &.-big-one {
      line-height: 1.1;
      font-size: 10px; } } }

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 100%;
  font-size: 1px;
  &:before, &:after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    width: 12px;
    height: 3px;
    background-color: $color-chosen-cross;
    margin: -1px 0 0 -5px; }
  &:before {
    transform: rotate(45deg); }
  &:after {
    transform: rotate(-45deg); } }

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  &:before, &:after {
    background-color: $color-primary; } }

.chosen-container-multi .chosen-choices li.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #ccc;
  background-color: #e4e4e4;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: linear-gradient(to bottom, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  color: #666; }

.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4; }

.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
  &:before, &:after {
    background-color: $color-primary; } }

.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0 25px 0; }

.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default; }

.chosen-container-multi .chosen-results li.group-result {
  color: $color-chosen-group;
  font-size: 11px;
  padding: 10px 0; }

.chosen-container-multi .chosen-results {
  background: $bg-chosen-form-gray; }

.chosen-container-multi .chosen-results li.group-option {
  padding: 0 0 10px;
  &.result-selected {
    color: $color-chosen-selected-item; }
  &.active-result {
    color: $color-text; } }

.chosen-container-multi.chosen-container-active .chosen-choices {
  border-color: $color-border; }

.chosen-container-multi .chosen-drop, .chosen-container-multi .chosen-drop .chosen-results {
  border-radius: 10px; }

.chosen-container-multi .chosen-drop .chosen-results {
  .group-option + .group-result {
    border-top: 1px solid $color-line-light; } }

.chosen-container-multi .chosen-results li.highlighted {
  background-color: $color-secondary; }
