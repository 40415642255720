.express-intro {
  padding: 45px 50px 25px; }

.express-intro_text {
  text-align: center;
  margin: 0 0 30px; }

.express-intro_title {
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  &.-ml {
    margin: 0 auto 19px; } }

.express-intro_hint {
  font-size: 15px;
  color: #6f7588;
  &.-ml {
    max-width: 670px;
    margin: 0 auto; } }

.express-intro_list {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px -20px 0;
  padding: 0;
  list-style: none; }

.express-intro_list-item {
  flex: 0 0 33%;
  max-width: 300px;
  &:not(:first-child) {
    border-left: 1px solid $color-line-light; } }

.express-intro_item {
  text-align: center;
  overflow: hidden; }

.express-intro_item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 35px auto;
  color: #fff;
  background: $color-gray;
  box-shadow: 0 0 0 35px rgba($color-gray, 0.1);
  &.-color-1 {
    background: #65C18B;
    box-shadow: 0 0 0 35px rgba(#65C18B, 0.1); }
  &.-color-2 {
    background: #8FCA61;
    box-shadow: 0 0 0 35px rgba(#8FCA61, 0.1); }
  &.-color-3 {
    background: #00B7BB;
    box-shadow: 0 0 0 35px rgba(#00B7BB, 0.1); } }

.express-intro_item-text {
  font-size: 15px;
  line-height: 1.4;
  width: 140px;
  margin: auto;
  padding-top: 15px; }
