.keywords-list {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;

  .modal-body & {
    margin-bottom: 25px; } }

.keywords-list_item {
  padding: 0;
  margin: 4px 0; }
