.summary-panel {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  &.-no-flex {
    display: block; }

  .ai-summary-inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .ai-bottom-note {
    font-size: 10px;
    margin-top: 25px; }

  h3 {
    margin-bottom: 8px; }

  .buttons {
    margin-top: 8px; }

  .-scheme {
    flex-grow: 0;
    flex-shrink: 0; }

  .middle {
    align-self: stretch;
    flex-basis: 100%;
    margin-left: 20px; }

  .run-button {
    align-self: center; }

  .report-checkbox {
    align-self: flex-start;
    min-width: 130px; }

  .text-grayed {
    margin-left: 4px;
    font-size: 13px;
    color: $color-gray; }

  .link {
    font-size: 13px;
    font-weight: bold;
    .icon {
      margin-bottom: -4px;
      margin-right: 4px; } }

  .link + .link {
    margin-left: 24px; }

  .checkbox_label {
    cursor: pointer;
    color: $color-text-gray;
    font-weight: bold;
    &:before {
      background: none; } }

  .-green {
    color: #65C18B; }
  .link.-green:hover {
    color: #75D18B; }
  &.-mt-20 {
    margin-top: 20px; }

  .pre {
    white-space: pre-line;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none; }
      li + li {
        margin-top: 10px; } } } }
