.edit-info-item-external {
  border: 1px solid $color-line-light;
  padding: 25px;
  max-width: 800px;
  border-radius: 10px;
  h3 {
    margin-bottom: 19px; }
  &.-error {
    border-color: $color-danger; } }

.info-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
.edit-info-tag + .edit-info-tag {
  margin-left: 8px; }
.edit-info-tag {
  font-size: 14px;
  padding: 8px 16px;
  background-color: $bg-dark-grey;
  border-radius: 22px;
  .-key + .-value {
    margin-left: 10px; }
  div {
    display: inline-block;
    &.-value {
      font-weight: bold;
      color: $color-primary; } } }

.project-preview_status {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  &.-unpublished {
    background: #F0F6D6;
    color: #90AA2C; }
  &.-in_field {
    background: #E4FAFB;
    color: #00B7BB; }
  &.-paused {
    background: #00B7BB;
    color: #E4FAFB; }
  &.-closed {
    background: #CCEFF9;
    color: #00AFE2; }
  &.-active {
    cursor: default;
    height: 38px;
    border: 1px solid;
    min-width: 115px;
    padding: 8px 16px;
    border-radius: 22px; } }

.survey-flow-button + .survey-flow-button {
  margin-left: 8px; }

button.survey-flow-button {
  min-width: 90px;
  padding: 5px 16px;
  height: 38px;
  &.-secondary {
    background-color: $bg-button-light-grey;
    border: 1px solid transparent; }
  &.-stop {
    background-color: #FF6900;
    &:hover {
      opacity: 0.8; } } }

.edit-survey-flow {
  .radio-tabs_item {
    input {
      left: -1px;
      top: -1px; } }

  .input-with-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end; }
  .input-item {
    input {
      margin-left: 5px; } }
  .input-item-note {
    font-size: 11px;
    color: #1F2542;
    font-weight: bold;
    opacity: 0.4;
    margin-bottom: 9px; }
  .update-buttons {
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &.-no-margin {
      margin: 0; } }
  .info-panel-note {
    font-size: 14px;
    line-height: 18px; }
  .info-panel-note + .active-status {
    margin-top: 23px; }
  .active-status {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .project-preview_status + .update-buttons {
    margin-left: 16px; }
  .form_section + .form_section {
    margin-top: 25px; } }

.quota-table {
  th, td {
    padding: 5px;
    &:first-child {
      padding-left: 0;
      width: 150px; }
    &:last-child {
      padding-right: 0; } }
  th + th, td + td {
    padding-left: 10px; }
  th {
    text-align: left; }
  input {
    &.form-field {
      width: 60px;
      padding: 3px 7px;
      min-height: auto; } } }

