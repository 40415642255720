.verizon-branded-stripe {
  @include font-verizon-text;
  font-size: 12px;
  line-height: 1.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 8px 8px 8px;
  position: relative;
  top: 20px;
  .logo {
    margin-right: 8px;
    svg {
      width: 60px;
      height: 25px; }
    img {
      height: 18px;
      width: auto; } } }

