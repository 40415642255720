.vcc_filter_row {
  display: flex;
  & + & {
    margin-top: 10px; } }

.vcc_filter_buttons {
  margin-top: 20px; }


.filters-modal-place {
  position: relative; }



.filters-modal {
  @include font-normal;
  font-size: 14px;
  position: absolute;
  top: 100%;
  margin-top: 16px;
  right: 0;
  width: 490px;
  z-index: 1500;
  animation: moveFromRight 0.15s;
  animation-fill-mode: both;
  h2 {
    font-size: 26px;
    @include font-bold;
    margin: 0 0 5px; } }

.filters-modal_plate {
  background-color: $va-white;
  border: 1px solid $va-gray-light-2;
  border-radius: 8px;
  padding: 18px 20px 20px;
  margin-bottom: 24px;
  position: relative;
  height: auto;
  max-height: calc(100vh - 180px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 2; }

.filters-modal_close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    vertical-align: middle;
    width: 16px;
    height: 16px; } }

.filters-modal_plate-top {
  flex: 0 0 auto;
  margin-bottom: 16px;
  h2 {
    font-size: 15px;
    line-height: 1.3;
    @include font-bold;
    text-transform: uppercase;
    margin: 0; } }

.filters-modal_plate-body {
  flex: 1 1 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 1px 15px 1px 1px;
  margin: -1px -15px -1px -1px; }

.filters-modal_row {
  & + & {
    margin-top: 24px; } }

.filters-modal_grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 -8px;
  &.-right-align {
    justify-content: flex-end; } }

.filters-modal_grid-item {
  padding: 0 8px;
  flex: 1 1 100%;
  &.-auto {
    flex: 0 0 auto; } }

.form-label {
  @include font-bold;
  font-size: 15px;
  color: $va-black; }

.form-group_field {
  &.-flex {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

.form-group_field-item {
  flex: 1 1 100%;
  &.-divider {
    flex: 0 0 auto;
    width: 8px;
    height: 2px;
    margin: 0 8px;
    background-color: $va-black; } }

.filters-modal_plate-bottom {
  flex: 0 0 auto;
  margin-top: 16px; }







