.normal-popup {
  position: relative;
  &.position-initial {
    position: initial; }
  .close {
    cursor: pointer;
    position: absolute;
    right: -23px;
    top: -12px;
    width: 22px;
    height: 22px;
    opacity: 0.3;
    z-index: 2;
    @include mobile {
      right: -10px;
      top: -10px; }
    &:hover {
      opacity: 1; }
    &:before, &:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 22px;
      width: 2px;
      background-color: #333; }
    &:before {
      transform: rotate(45deg); }
    &:after {
      transform: rotate(-45deg); } }
  &.h100 {
    height: 100%; }

  .normal-popup-title {
    margin-bottom: 15px;
    margin-top: 0; }
  .normal-popup-buttons {
    margin-top: 40px; }
  .nearby-popup-buttons {
    margin-top: 10px; } }

.modal-dialog.-fit-window {
  max-height: calc(100vh);
  transform: none !important;
  transition: none !important;

  .normal-popup {
    max-height: calc(100vh - 115px);
    display: flex;
    flex-direction: column; }

  .question-list-header.-row {
    overflow: hidden;
    h3 {
      margin: 0; }
    margin-bottom: 10px;
    min-height: 18px;
    &.-with-top-margin {
      margin-top: 20px; } }

  .question-list, .question-list-header {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &.-row {
      flex-direction: row;

      .question-item {
        min-width: 157px;
        max-width: 267px; }

      input {
        width: 70px;
        flex: none; } } } }

.normal-popop-description {
  &.-with-bottom-margin {
    margin-bottom: 20px; } }
