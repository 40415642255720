.form-package_image {
  position: relative;
  width: 90px;
  height: 90px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 10px;
  //overflow: hidden
  .-pointer, &.-pointer {
    cursor: pointer; }

  &.-no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px; }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%; } }

.form-package_image_delete {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background: #F2F2F3;
  border-radius: 0 0 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  button.link {
    color: #A9BFC8;
    margin: 0; } }

.form-packages {
  background-color: #fff;
  border-radius: 12px;
  padding: 0 15px 15px 30px;
  margin-top: 10px; }

.form-packages-no-records {
  background-color: #fff;
  border-radius: 12px;
  padding: 15px 15px 15px 30px;
  margin-top: 10px;
  color: $color-text-nav;
  font-weight: bold; }

.form-packages_num {
  font-weight: bold;
  margin-top: -30px;
  min-width: 19px;
  text-align: right;
  margin-right: 5px; }

.form-packages_list {
  padding: 0;
  margin: 0;
  list-style: none; }

.form-packages_item.-new {
  padding: 15px 0 0;
  &.-no-items {
    padding: 50px 0 0; }
  .form-packages_num {
    opacity: 0; }
  .-package-text textarea {
    border-radius: 7px 0 0 7px; }
  .-package-label textarea {
    border-radius: 0 7px 7px 0; } }

.form-packages_item.-ready {
  padding: 0;
  &:not(:first-child) {
    margin-top: -1px; }

  margin-bottom: 10px;

  &.-no-margin {
    margin-bottom: 0; }
  &.-one {
    &:first-child, &:last-child {
      .-package-text textarea {
        border-radius: 7px 0 0 7px; }
      .-package-label textarea {
        border-radius: 0 7px 7px 0; } } }

  &:first-child {
    padding: 50px 0 0;
    .-package-text {
      textarea {
        border-radius: 7px 0 0 0;
        &.-solo {
          border-radius: 7px 7px 0 0; } } }
    .-package-label {
      textarea {
        border-radius: 0 7px 0 0; } } }

  &.-last-child {
    margin-bottom: 0;
    .-package-text {
      textarea {
        border-radius: 0 0 0 7px;
        border-bottom-color: $form-border-color;
        &.-solo {
          border-radius: 0 0 7px 7px; } } }
    .-package-label {
      textarea {
        border-radius: 0 0 7px 0;
        border-bottom-color: $form-border-color; } } }

  .-package-text textarea {
    border-radius: 0;
    border-bottom-color: transparent; }
  .-package-label textarea {
    border-radius: 0;
    border-bottom-color: transparent; } }

.form-packages_grid {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.form-packages_grid-item {
  flex: 0 0 auto;
  button {
    margin-left: 15px; }
  &.-package-text {
    flex: 1 1 auto; }
  &.-package-label {
    flex: 0 0 25%;
    textarea {
      border-left: none; } }
  textarea:focus {
    border-color: $form-border-color; } }

.form-packages_group {
  position: relative;

  input.form-field {
    margin-left: 15px;
    width: calc(100% - 15px);
    border-radius: 10px; } }

.control-label_tooltip {
  margin-left: 5px;
  margin-top: -2px; }

textarea.form-packages_textarea {
  resize: none;
  height: 100px;
  min-height: 60px;
  padding: 12px; }

textarea.form-packages_textarea[disabled] {
  border-color: $color-disabled !important;
  background-color: $color-border-disabled; }

textarea.has-error {
  border-color: $color-danger !important; }

.shelf-text + .shelf-text {
  padding-top: 10px; }
.shelf-text {
  ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
    text-indent: -24px;
    padding-left: 24px;
    &.-margin-bottom-10 {
      margin-bottom: 10px; }
    li {
      list-style: none;
      position: relative;
      &:before {
        content: '\2014\A0';
        margin-right: 5px; } } } }

.form-image_label {
  position: absolute;
  font-size: 13px;
  color: $color-flavor-label;
  top: -30px;
  margin-top: -6px; }

.form.-bigger-font {
  font-size: 1rem; }
