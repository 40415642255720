.draft-editor-custom {
  &.middle-height {
    height: 110px;
    max-height: 110px; }
  &.more-than-middle-height {
    height: 200px;
    max-height: 200px; }
  &.biggest-height {
    height: 500px;
    max-height: 500px; }
  &.wysiwyg-textarea {
    text-align: center;
    padding: 10px;
    div {
      line-height: normal; }
    ul, ol {
      line-height: 1.1;
      div {
        line-height: 1.1; } } }
  ul, ol {
    display: inline-block;
    margin: 10px 0;
    padding: 0 0 0 22px;
    li {
      margin-left: 0 !important;
      text-align: left !important; } }
  .public-DraftStyleDefault-block {
    margin: 0; }
  li + li {
    margin-top: 5px; }
  .public-DraftEditor-content {
    text-align: left; }
  .public-DraftStyleDefault-orderedListItem {
    &.public-DraftStyleDefault-listLTR {
      &:before {
        left: -30px; } } } }

.rdw-editor-toolbar {
  margin-bottom: 0;
  border: 1px solid $form-border-color;
  position: relative;
  top: 1px;
  border-radius: 14px 14px 0 0;
  padding: 10px 10px 5px; }

.rdw-image-alignment-options-popup {
  display: none; }

.rdw-image-alignment {
  display: flex;
  justify-content: left !important; }

.draft-editor-custom {
  border: 1px solid $form-border-color; }

.DraftEditor-root {
  padding: 5px;
  height: calc(100% - 1px);
  margin-top: 0; }

.public-DraftEditorPlaceholder-root {
  top: 5px; }

.rdw-fontsize-wrapper {
  .rdw-dropdown-selectedtext {
    span {
      text-align: center;
      width: 100%;
      padding-right: 16px; } }
  .rdw-fontsize-dropdown {
    min-width: 80px; }
  .rdw-dropdown-carettoopen {
    top: 45%; }
  .rdw-dropdown-carettoclose {
    top: 42%; } }

.rdw-colorpicker-wrapper {
  .rdw-colorpicker-modal-style-label-active {
    border-bottom: 3px solid #D52B1E; }
  .rdw-colorpicker-modal-options {
    overflow: auto; } }
