.fancybox-bg {
  background: #D6DCE3;
  .fancybox-is-open & {
    opacity: 0.95; } }

.fancybox-caption {
  background: none;
  color: $color-text;
  top: 0;
  padding: 25px 80px 0 80px;
  bottom: auto;
  margin: 0;
  font-size: 26px;
  line-height: 38px;
  font-weight: bold;
  z-index: 100;
  @include mobile {
    font-size: 20px; } }

.fancybox-caption__body {
  @include truncate; }

.fancybox-button {
  background: none;
  &:hover {
    color: $color-text; } }

.fancybox-toolbar {
  top: 23px;
  right: 23px; }

.fancybox-navigation {
  .fancybox-button {
    width: 50px;
    height: 50px;
    padding: 0;
    color: $color-text;
    &:disabled {
      color: $color-text;
      opacity: 0.3; }
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      border-left: 2px solid currentColor;
      border-bottom: 2px solid currentColor; } }

  .fancybox-button--arrow_left {
    right: 100%;
    left: auto;
    margin-right: 15px;
    &:before {
      transform: rotate(45deg); }
    @include breakpoint-lo(900px) {
      margin-right: 0; }
    @include mobile {
      margin-right: -15px; } }

  .fancybox-button--arrow_right {
    left: 100%;
    margin-left: 15px;
    &:before {
      transform: rotate(-135deg); }
    @include breakpoint-lo(900px) {
      margin-left: 0; }
    @include mobile {
      margin-left: -15px; } } }

.fancybox-inner {
  width: calc(100vw - 160px);
  height: calc(100vh - 160px);
  max-width: 1200px;
  max-height: 1000px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(83, 122, 167, 0.15);
  border-radius: 20px;
  @include breakpoint-lo(900px) {
    width: 85vw;
    height: 80vh; } }


.fancybox-stage {
  top: 80px; }

.fancybox-slide {
  padding: 0 45px 45px 45px; }

//state zoom
.fancybox-is-zoomable.fancybox-can-pan {
  .fancybox-stage {
    overflow: visible;
    z-index: 100000; }
  .fancybox-slide {
    overflow: visible; } }


