.hide,
.-hidden {
  display: none; }

.-capitalize {
  text-transform: capitalize; }

.padding-5 {
  padding: 5px; }

//  todo @UX dev-only, please remove
.-inline-4 {
  display: inline-block;
  width: 4%; }
.-inline-10 {
  display: inline-block;
  width: 10%; }
.-plate {
  background: white;
  border-radius: 5px;
  border: 1px solid lightgrey;
  margin: 4px;
  padding: 4px; }
.-gray {
  color: lightgrey; }
.pull-right {
  float: right; }

.-p-relative {
  position: relative; }

//.font-medium
//  font-weight: 500
//
//.color-warning
//  color: $color-warning
//
//.color-success
//  color: $color-success
//
//.color-danger
//  color: $color-danger
//
//.color-label
//  color: $color-label
//
//.color-no-tool
//  color: $color-no-tool
//
//.color-production,
//.color-p
//  color: $color-production
//
//.color-test,
//.color-t
//  color: $color-test
//
//.-outset-side
//  margin-left: -$padding-side
//  margin-right: -$padding-side
//
//.-outset-side-big
//  margin-left: -$padding-side-big
//  margin-right: -$padding-side-big
//
//.-align-left
//  text-align: left
//
//.-align-right
//  text-align: right
//
//.-align-center
//  text-align: center

//.-txt-overflow
//  +truncate
//  display: block
//  max-width: 100%
//
//.-pull-left,
//.pull-left
//  float: left
//
//.-pull-right,
//.pull-right
//  float: right
//
//.-no-shrink
//  flex-shrink: 0
