// special styles for some tour steps in swipe
// special position of hint

.tour-hint {
  &.-bubble-1 {
    margin-left: -20px;
    margin-top: 40px;
    .tour-hint_content {
      min-width: 470px; } }

  &.-tour-swipe-2 {
    margin-top: -15px;
    .tour-hint_content {
      min-width: 270px; } }

  &.-tour-swipe-5 {
    margin-top: 30px;
    margin-left: -5px; }

  &.-tour-swipe-7 {
    margin-top: 15px;
    &:before {
      left: 50px; } }

  &.-tour-swipe-8 {
    margin-top: 15px;
    margin-left: -20px; }

  &.-tour-swipe-9 {
    margin-top: 10px;
    margin-left: 15px; }

  &.-tour-swipe-10 {
    margin-left: -15px; }

  &.-tour-swipe-11 {
    margin-top: -14px; }

  &.-tour-swipe-12 {
    margin-top: 15px;
    &:before {
      right: 75px; } }

  &.-tour-swipe-13, &.-tour-swipe-14 {
    &:before {
      left: 70px; } } }

//styles to show anchors for tour in swipe

.tour-swipe-anchor-active {
  position: relative;
  z-index: $z-index-anchor;
  pointer-events: none;

  // special styles for controls than we want to show in tour

  &.tour-swipe-1 {
    background: #fff;
    box-shadow: 0 0 0px 20px #fff;
    border-radius: 0 0 1px 1px; }

  // save pseudo focus state on chosen control and open state for dropdown after click outside
  &.tour-swipe-2.chosen-container {
    .chosen-choices {
      border-color: $color-primary; }
    .chosen-drop {
      clip: auto;
      clip-path: none; } }

  //open dropdown
  &.tour-swipe-3 {
    @include dropdown-show; }

  &.tour-swipe-4 {
    background: #E9F8FD;
    border-radius: 10px; }

  // in modal (box-shadow as backdrop)
  &.tour-swipe-5 {
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-swipe-6 {
    border-radius: 10px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-swipe-7, &.tour-swipe-8, &.tour-swipe-12 {
    box-shadow: 0 0 0 2px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-swipe-9, &.tour-swipe-10 {
    box-shadow: 0 0 0 1000em $bg-backdrop; }

  &.tour-swipe-11 {
    border-radius: 30px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  //open dropdown
  &.tour-swipe-15 {
    @include dropdown-show; } }



// for cases when parent is highlighted but arrow show on children
// in modal (box-shadow as backdrop)

.tour-swipe-anchor-parent-active {
  pointer-events: none;
  z-index: $z-index-anchor;

  // very special case (need to show 2 blocks from different parents)
  &.tour-swipe-1-parent {
    z-index: initial;
    pointer-events: auto;
    .content_top {
      pointer-events: none;
      z-index: $z-index-anchor;
      background: #fff;
      position: relative;
      box-shadow: 0 20px 0 20px #fff;
      border-radius: 1px 1px 0 0;
      margin-top: 10px;
      padding-bottom: 10px;
      height: calc(#{$page-header-height} - 10px); } }

  &.tour-swipe-13-parent, &.tour-swipe-14-parent {
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop;
    border-radius: 20px 20px 0 0;
    position: relative; }
  &.tour-swipe-15-parent {
    box-shadow: 0 0 0 1000em $bg-backdrop;
    border-radius: 20px; } }


th.-with-concept.tour-swipe-anchor-parent-active {
  & {
    background: none;
    z-index: $z-index-anchor; } }
