.field-gray-bg {
  margin-top: 16px;
  background-color: $bg-gray;
  border: 1px solid $form-border-color;
  box-shadow: 0px 2px 10px rgba(80, 81, 84, 0.1);
  border-radius: $form-border-radius;
  padding: 15px;

  h5 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 0.9em; }

  .smaller-text {
    font-size: 0.9em; }

  .checkbox-multi {
    margin-top: 16px; } }


.-ml-projects {
  .projects_item-inner {
    position: relative;
    a.delete {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fd787a; }
    .info-tooltip.-star {
      position: absolute;
      right: 0;
      top: 20px;
      .-i-star {
        position: absolute;
        right: 20px;
        top: 0px; } } }
  .projects_list_table {
    a.delete {
      color: #fd787a; } } }

