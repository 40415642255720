@keyframes moveFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }


