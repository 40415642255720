.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.icon {
  display: inline-block;
  fill: currentColor;
  &.-i-compare {
    color: $va-grey-selected;
    cursor: pointer;
    width: 17px;
    height: 17px;
    &.-selected {
      color: #000; } } }
