.modal-dialog.-centered-form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  .modal-content {
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
    min-height: 240px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }

  h2 {
    margin: 8px 0 16px 0;
    font-size: 18px; }
  .copy-form {
    margin-bottom: 16px;
    .label {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px; } }
  .error {
    color: $color-danger;
    font-size: 12px; }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button + button {
      margin-left: 10px; }
    button {
      height: 44px;
      width: 140px;
      &.-cancel {
        background: $color-base-gray; } } } }

