.regions {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  .zone {
    position: absolute;
    border: 1px dashed $color-primary;
    pointer-events: none;
    z-index: 20; }

  .region {
    position: absolute;
    border: 1px dashed $color-primary;
    border-radius: 4px;
    pointer-events: none;
    box-shadow: inset 0 0 0 2px #fff;
    z-index: 20;
    //background: rgba($color-success, .4)
    background: rgba(41, 165, 255, .2); }

  .close-region {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    z-index: 40;
    margin-left: 2px;
    cursor: pointer;
    font-size: 0;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: #fff; }

    .icon {
      position: relative; } }

  .selection {
    position: absolute;
    border: 1px dashed #aaa;
    border-radius: 4px;
    pointer-events: none;
    box-shadow: inset 0 0 0 2px #fff;
    z-index: 30;
    background: rgba($color-success, .4); } }
