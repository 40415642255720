.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2px;
  user-select: none; }

.rzslider[disabled] {
  cursor: not-allowed; }

.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3; }

.rzslider[disabled] .rz-draggable {
  cursor: not-allowed; }

.rzslider[disabled] .rz-selection {
  background: #8b91a2; }

.rzslider[disabled] .rz-tick {
  cursor: not-allowed; }

.rzslider[disabled] .rz-tick.rz-selected {
  background: #8b91a2; }

.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  &.rz-model-value {
    top: -30px;
    font-size: 12px; } }

.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0; }

.rzslider .rz-bar-wrapper {
  left: 0;
  right: 0;
  z-index: 1;
  height: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: -10px 0 0; }

.rzslider .rz-draggable {
  cursor: move; }

.rzslider .rz-bar {
  left: 5px;
  right: 5px;
  z-index: 1;
  height: 3px;
  margin-top: -1px;
  background-color: #D3D3D3;
  border-radius: 3px; }


.rzslider .rz-selection {
  z-index: 2;
  background: $color-primary;
  border-radius: 2px;
  right: 11px; }

.rzslider .rz-pointer {
  outline: none;
  display: block;
  width: 19px;
  height: 19px;
  position: absolute;
  top: -9px;
  left: 0;
  margin-left: 0px;
  border-radius: 50%;
  background-color: $color-primary;
  z-index: 2;
  cursor: grab;
  &:active {
    cursor: grabbing; } }

.rzslider .rz-pointer.rz-active {
  z-index: 4; }

.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: -11px;
  z-index: 1;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none; }

.rzslider .rz-tick {
  position: absolute;
  top: -8px;
  left: 4px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 7px;
  border: 2px solid $color-primary;
  background: #fff; }

.rzslider .rz-tick.rz-selected {
  background-color: $color-primary; }

.rzslider .rz-tick-value {
  position: absolute;
  top: 50px;
  width: 20px;
  margin-left: -10px;
  text-align: center;
  font-size: 18px;
  color: $color-base-gray;
  @media all and (-ms-high-contrast: none) {
    margin-left: -7px; } }

.rzslider .rz-limit {
  font-size: 12px;
  top: 8px;
  margin-left: 11px;
  &.rz-ceil {
    left: auto !important;
    right: 0;
    margin-right: 11px; } }

.summary-markup_reactions_content, .summary-markup_reactions_image {
  .rzslider .rz-limit {
    top: 12px; }
  .rzslider {
    span.rz-model-value {
      top: 12px; } } }

.summary-markup_reactions_image {
  .rzslider {
    span.rz-model-value {
      top: 14px; } } }
