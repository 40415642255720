.file-uploader {
  position: relative; }

.file-uploader_input {
  //opacity: 0
  position: absolute;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  z-index: -1; }

.file-uploader_control {
  position: relative;
  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-size: 20px; }
  .uppy-Informer {
    display: none; } }
