// special styles for some tour steps in swipe
// special position of hint

.tour-hint {
  &.-tour-naming-2 {
    margin-top: -15px;
    .tour-hint_content {
      min-width: 320px;
      text-align: center; } }

  &.-tour-naming-5 {
    margin-top: 30px;
    margin-left: -5px;
    .tour-hint_content {
      min-width: 350px; } }

  &.-tour-naming-7 {
    margin-top: 12px;
    margin-left: 15px; }

  &.-tour-naming-8 {
    margin-left: -15px; }

  &.-tour-naming-9 {
    margin-top: -14px; }

  &.-tour-naming-12 {
    margin-top: 15px;
    &:before {
      right: 75px; } }

  &.-tour-naming-13 {
    &:before {
      left: 70px; } } }

//styles to show anchors for tour in swipe

.tour-naming-anchor-active {
  position: relative;
  z-index: $z-index-anchor;
  pointer-events: none;

  // special styles for controls than we want to show in tour

  &.tour-naming-1 {
    background: #fff;
    box-shadow: 0 0 0px 20px #fff;
    border-radius: 0 0 1px 1px; }

  // save pseudo focus state on chosen control and open state for dropdown after click outside
  &.tour-naming-2.chosen-container {
    .chosen-choices {
      border-color: $color-primary; }
    .chosen-drop {
      clip: auto;
      clip-path: none; } }

  //open dropdown
  &.tour-naming-3 {
    @include dropdown-show; }

  &.tour-naming-4 {
    border-radius: 10px;
    background: #fff;
    box-shadow: inset 0 0 0 5px #E9F8FD;
    & ~ .concept-preview_link-wrapper {
      //hide link 'view report'
      opacity: 0; } }

  // in modal (box-shadow as backdrop)
  &.tour-naming-5 {
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-naming-6 {
    border-radius: 10px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-naming-12 {
    box-shadow: 0 0 0 2px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-naming-7, &.tour-naming-8 {
    box-shadow: 0 0 0 1000em $bg-backdrop; }

  &.tour-naming-9 {
    border-radius: 30px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  //open dropdown
  &.tour-naming-10 {
    @include dropdown-show; } }


// for cases when parent is highlighted but arrow show on children
// in modal (box-shadow as backdrop)

.tour-naming-anchor-parent-active {
  pointer-events: none;
  z-index: $z-index-anchor;

  // very special case (need to show 2 blocks from different parents)
  &.tour-naming-1-parent {
    z-index: initial;
    pointer-events: auto;
    .content_top {
      pointer-events: none;
      z-index: $z-index-anchor;
      background: #fff;
      position: relative;
      box-shadow: 0 20px 0 20px #fff;
      border-radius: 1px 1px 0 0;
      margin-top: 10px;
      padding-bottom: 10px;
      height: calc(#{$page-header-height} - 10px); } }

  &.tour-naming-10-parent {
    box-shadow: 0 0 0 1000em $bg-backdrop;
    border-radius: 20px; } }
