.alertify-notifier {
  &.ajs-bottom {
    top: 10px !important; } }

.ajs-message {
  text-shadow: none !important;
  border: none !important;
  border-radius: 4px !important;
  text-align: left !important;
  opacity: 0 !important;
  transition: right .6s 0s ease, opacity .6s 0s ease !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

  &.ajs-visible {
    opacity: 1 !important; }

  &.ajs-success {
    background: $color-success !important; }

  &.ajs-error {
    background: $color-danger !important; } }
