.survey-reactions-interface_toggle {
  margin: 0 0 30px;
  text-align: center;
  &.right {
    text-align: right; }
  &.-abs {
    position: absolute;
    right: 50px;
    top: 0; } }

.survey-reactions-interface_image-wrapper {
  position: relative; }

.survey-reactions-interface_image-canvas-wrapper {
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid $color-border;
  //max-width: 600px
  text-align: center;
  img, canvas {
    max-width: 100%; }
  canvas {
    height: 100%; } }

.survey-reactions-interface_image-canvas-inner {
  position: relative;
  display: inline-block; }

.survey-reactions-interface_image {
  vertical-align: top;
  max-width: 100%; }
//border: 1px solid $main-light-gray

.survey-reactions-interface_canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2; }
//cursor: pointer
