.form-upload {
  &.-plate {
    background: #EFF4F5;
    border-radius: 14px;
    padding: 20px; }
  &.-in-progress {
    background-color: #daf2f9; }
  &.-error {
    color: #ffffff;
    background-color: #FF6900; } }

.form-upload_button {
  min-width: 150px; }

.form-upload_grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative; }

.form-upload_grid-item {
  flex: 0 1 auto;
  &.-buttons {
    flex: 0 0 130px; }
  & + & {
    margin-left: 20px; } }


.form-upload_preview-single-image {
  max-width: 700px;
  min-width: 100px;
  height: 265px;
  line-height: 262px;
  border-radius: 14px;
  background: $bg-gray;
  position: relative;
  overflow: hidden;
  text-align: center;
  .uppy-ProgressBar {
    display: none; }
  img {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    max-width: 100%;
    max-height: 100%; } }

.form-upload_button-block {
  & + & {
    margin-top: 10px; } }
