$va-font-bold: "NHaasGroteskDisplay75", sans-serif;
$va-font-normal: "NHaasGroteskDisplay55", sans-serif;
$va-font-thin: "NHaasGroteskDisplay45", sans-serif;
$va-font-text: "NHaasGroteskDisplay55", sans-serif;
$color-va-red-brand: #D52B1E;

$va-border: #747776;
$va-gray-light: #f6f6f6;
$va-gray-light-2: #d8dada;
$va-gray-light-3: #c9cbcb;
$va-gray-placeholder: #BFBFBF;
$va-gray-medium: #ddd;
$va-gray-dark: #808080;
$va-gray-darker: #413C58;
$va-gray-text: #A7A7A7;
$va-dark-grey-text: #6F7171;
$va-black: #000;
$va-black-hover: #4a4a4a;
$va-white: #fff;
$va-stone: #F3EDE0;
$va-stone-darker: #e4d7bc;
$va-coral: #FF3C2D;
$va-red: #F50A23;
$va-yellow: #F5FF1E;
$va-orange: #FF8027;
$va-green: #00B845;
$va-blue: #0067C1;
$va-grey-selected: #97999F;
$va-grey-border: #C6D8DB;

$va-brand: $va-coral;

$va-sticky-zi: 100;

@mixin  font-bold {
  font-family: $va-font-bold;
  font-weight: normal; }

@mixin  font-normal {
  font-family: $va-font-normal;
  font-weight: normal; }

@mixin  note-style {
  font-size: 14px;
  color: $va-dark-grey-text;
  @include font-normal; }

@mixin  note-black-style {
  font-size: 14px;
  color: $va-black;
  @include font-normal; }

