.express-steps {
  padding: 15px 20px 30px; }

.express-steps_title {
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  margin: 0 auto 15px;
  text-align: center; }

.express-steps_subtitle {
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  margin: 0 auto 20px;
  text-align: center;
  color: #b5c8cf; }

.express-steps_list {
  margin: 30px auto 0;
  max-width: 700px; }

.express-steps_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 0;
  &:not(:last-child) {
    border-bottom: 1px solid $color-line-lighter; }
  &.-no-flex {
    display: block; }
  &.-no-bottom-line {
    border-bottom: none;
    padding-bottom: 10px; } }

.express-steps_col {
  flex: 0 1 auto;
  & + & {
    margin-left: 20px; } }

.express-steps_item-text {
  position: relative;
  padding-right: 30px;
  padding-left: 25px;
  font-size: 15px;
  line-height: 1.4;
  font-weight: bold;
  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    left: 0;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: $color-primary; }
  p {
    margin: 0;
    &.-descr {
      font-weight: normal;
      color: #797B8C; }
    & + p {
      margin-top: 15px; } } }

.express-steps_item-img {
  background-color: $bg-gray;
  width: 343px;
  border-radius: 14px;
  overflow: hidden;
  &.-narrow {
    width: 340px; }
  &.-with-padding {
    padding: 5px; }
  &.-white {
    background-color: #fff; }
  img {
    width: 100%;
    height: auto; }
  & + & {
    margin-top: 12px; }
  &.-border {
    border: 1px solid $color-line-lighter; } }

.express-steps_item-header {
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0; }

.express-steps_item-description {
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  max-width: 380px;
  margin: 0 auto 15px; }


.express-steps_inner-col {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0; }

.express-steps_header-wrapper {
  padding-bottom: 10px;
  border-bottom: 1px solid $color-line-lighter; }
