.toggle {
  .form-switch {
    background-color: $form-border-color !important; }
  &.-manage {
    min-height: 23px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    &.-quota {
      margin-left: 8px; }
    &.-five-point-scale, &.-dedicated-page {
      margin-left: 0; }
    span {
      font-weight: normal;
      font-size: 14px;
      &.edit-options-targeting {
        display: flex;
        flex-wrap: wrap;
        margin-left: 8px;
        align-content: center;
        align-items: center;
        a {
          margin-left: 0;
          margin-right: 4px; } } } } }

.form-switch {
  user-select: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  position: relative;
  width: 30px;
  height: 10px;
  border-radius: 7px;
  background-color: #E5EAEB !important;
  transition: background-color $transition;
  font-size: 0;
  line-height: 0;
  color: transparent;
  cursor: pointer;
  margin-top: -4px;
  margin-bottom: -3px;
  &.-disabled {
    cursor: default; }
  &:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #A2AEBB;
    position: absolute;
    top: -4px;
    left: 0px;
    transition: left $transition, background-color $transition; }
  input:checked + & {
    //background-color: #A8DBFF
    &:before {
      left: 12px;
      background-color: $color-primary;
 }      //box-shadow: inset 0 0 0 6px $color-primary, 0 0 1px rgba(0,0,0,0.2)
    &.-success {
      //background-color: currentColor
      &:before {
        background-color: $color-success;
 } }        //box-shadow: inset 0 0 0 6px currentColor, 0 0 1px rgba(0,0,0,0.2)
    &.-danger {
      //background-color: currentColor
      &:before {
        background-color: $color-red;
 } }        //box-shadow: inset 0 0 0 6px currentColor, 0 0 1px rgba(0,0,0,0.2)
    &.-warning {
      //background-color: currentColor
      &:before {
        background-color: $color-warning;
 } }        //box-shadow: inset 0 0 0 6px currentColor, 0 0 1px rgba(0,0,0,0.2)
    &.-disabled {
      //background-color: currentColor
      &:before {
        background-color: #A2AEBB; } } }
  input.ng-hide + & {
    display: none; } }

.form-switch-group-container {
  display: flex;
 }  //padding: 2px 1px

.form-switch-group {
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  user-select: none;
  //box-shadow: inset 0 0 0 1px $color-border
  padding: 10px 0;
  font-size: 13px;
  font-weight: bold;
  color: #787F93 !important;
  line-height: 1;
  //flex-grow: 1
  & + & {
    margin-left: 20px; }
  //&:not(:last-child)
  //margin-right: -1px

  //&:first-child
  //  border-radius: 4px 0 0 4px
  //&:last-child
  //  border-radius: 0 4px 4px 0

  //&.-success
  //color: currentColor
  //&.-danger
  //color: currentColor
  //&.-warning
  //color: currentColor
  &.-no-border {
    box-shadow: none; } }

.form-switch-text {
  user-select: none;
  &.-lg {
    font-size: 16px; }
  &.-pointer {
    cursor: pointer; } }
