@keyframes page-loader-fadeout {
  0% {
    opacity: 1; }

  100% {
    opacity: 0;
    visibility: hidden; } }

// https://projects.lukehaas.me/css-loaders/
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $color-primary, 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2), 2.5em 0em 0 0em rgba(41, 165, 255, 0.2), 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.2), 0em 2.5em 0 0em rgba(41, 165, 255, 0.2), -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.2), -2.6em 0em 0 0em rgba(41, 165, 255, 0.5), -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.7); }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.7), 1.8em -1.8em 0 0em $color-primary, 2.5em 0em 0 0em rgba(41, 165, 255, 0.2), 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.2), 0em 2.5em 0 0em rgba(41, 165, 255, 0.2), -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.2), -2.6em 0em 0 0em rgba(41, 165, 255, 0.2), -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.5); }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.5), 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.7), 2.5em 0em 0 0em $color-primary, 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.2), 0em 2.5em 0 0em rgba(41, 165, 255, 0.2), -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.2), -2.6em 0em 0 0em rgba(41, 165, 255, 0.2), -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2); }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.2), 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.5), 2.5em 0em 0 0em rgba(41, 165, 255, 0.7), 1.75em 1.75em 0 0em $color-primary, 0em 2.5em 0 0em rgba(41, 165, 255, 0.2), -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.2), -2.6em 0em 0 0em rgba(41, 165, 255, 0.2), -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2); }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.2), 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2), 2.5em 0em 0 0em rgba(41, 165, 255, 0.5), 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.7), 0em 2.5em 0 0em $color-primary, -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.2), -2.6em 0em 0 0em rgba(41, 165, 255, 0.2), -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2); }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.2), 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2), 2.5em 0em 0 0em rgba(41, 165, 255, 0.2), 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.5), 0em 2.5em 0 0em rgba(41, 165, 255, 0.7), -1.8em 1.8em 0 0em $color-primary, -2.6em 0em 0 0em rgba(41, 165, 255, 0.2), -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2); }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.2), 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2), 2.5em 0em 0 0em rgba(41, 165, 255, 0.2), 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.2), 0em 2.5em 0 0em rgba(41, 165, 255, 0.5), -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.7), -2.6em 0em 0 0em $color-primary, -1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2); }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(41, 165, 255, 0.2), 1.8em -1.8em 0 0em rgba(41, 165, 255, 0.2), 2.5em 0em 0 0em rgba(41, 165, 255, 0.2), 1.75em 1.75em 0 0em rgba(41, 165, 255, 0.2), 0em 2.5em 0 0em rgba(41, 165, 255, 0.2), -1.8em 1.8em 0 0em rgba(41, 165, 255, 0.5), -2.6em 0em 0 0em rgba(41, 165, 255, 0.7), -1.8em -1.8em 0 0em $color-primary; } }

.page-loader {
  position: fixed;
  z-index: 9999;
  background-color: $color-body;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.-loaded {
    animation-duration: .5s;
    animation-name: page-loader-fadeout;
    animation-fill-mode: forwards;
    animation-delay: .5s; }

  &.-inner-loader {
    position: absolute;
    opacity: 0.5;
    z-index: 1000; }

  &.-transparent {
    background-color: transparent; }

  &.-fit-half-height {
    height: calc(50vh); }

  &.-to-front {
    z-index: 2000; }

  &.-fixed {
    position: fixed;
    top: 0;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    height: 100%;
    z-index: 2000; }

  &.-gray {
    filter: grayscale(1); }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -7px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    animation: load5 1.1s infinite ease;
    transform: translateZ(0); } }

.page-inner-loader {
  position: fixed;
  z-index: 9999;
  background-color: $color-body;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  //+mobile
  //top: 54px

  &.-loaded {
    animation-duration: .5s;
    animation-name: page-loader-fadeout;
    animation-fill-mode: forwards;
    animation-delay: .5s; }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -7px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    animation: load5 1.1s infinite ease;
    transform: translateZ(0); } }

.page-zero-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  //+mobile
  //top: 54px

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    margin-left: -7px;
    margin-top: -7px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    animation: load5 1.1s infinite ease;
    transform: translateZ(0); }

  img.zoom-area_image {
    opacity: 0; } }
