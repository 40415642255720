.answer-block {
  display: block;
  &.-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center; } }

.answer-block_img {
  flex: 0 0 auto;
  width: 40px;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
    height: auto; } }

.answer-block_txt {
  display: block;
  font: bold 14px/1.4 $font-family-base;
  color: $color-gray;
  flex: 1 1 auto;
  @include break-word;

  .answer-block.-sm & {
    font-size: 13px;
    color: $color-text; } }

.answer-block_author {
  display: block;
  font: normal 13px/1.4 $font-family-base;
  margin: 0;
  color: $color-gray;

  .answer-block.-sm & {
    font-size: 14px;
    color: $color-label; } }
