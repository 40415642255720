.words-counter {
  color: $color-danger; }

.words-counter_icon {
  vertical-align: middle; }

.words-counter_text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 10px;
  font-size: 14px;
  font-weight: 400; }
