.express-image {
  position: relative;
  height: 100px;
  width: auto;
  border-radius: 8px;
  border: 1px solid $form-border-color;

  & + & {
    margin-top: 8px; }

  .thumb {
    position: absolute;
    width: 98px;
    height: 98px;
    margin: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 8px; }

  .header-text {
    font-size: 16px;
    position: absolute;
    left: 110px;
    right: 70px;
    top: 50%;
    transform: translateY(-50%); }

  .buttons {
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%); } }
