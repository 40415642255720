.express-inline-dropdown {
  position: absolute;
  z-index: 100;
  max-width: 300px;
  min-width: 100px;
  max-height: 400px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  overflow-y: auto;
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px; }
  li {
    list-style: none;
    margin: 0;
    padding: 0; }
  a, span {
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    color: $color-text;
    display: block;
    width: 100%; }
  span {
    color: $color-gray;
    cursor: default; }
  a:hover {
    color: $color-text;
    text-decoration: none;
    background-color: rgba(91, 127, 228, 0.1); }
  p {
    color: $color-text;
    padding: 10px; } }

.base-inline-dropdown-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: $color-link;
  margin-right: 12px;
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-link;
    border-bottom: none;
    right: -12px;
    top: 50%;
    z-index: 1; } }
.base-inline-dropdown-select {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  width: calc(100% + 12px); }
