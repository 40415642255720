.program-health {
  .scores {
    max-height: 600px;
    &.-loading {
      opacity: 0.25; }
    width: 100%;
    text {
      &.bold {
        font-family: $font-verizon-bold; }
      &.gray {
        fill: $va-dark-grey-text; } }
    circle.gray {
      fill: $va-gray-text; }
    circle.red-ring {
      stroke: $va-red;
      fill: white;
      cursor: pointer; }
    line, polyline {
      &.gray {
        stroke: $va-gray-text; }
      &.red {
        stroke: $va-red; } }
    rect.period-bg {
      fill: $va-stone; } }
  .pg_blocks {
    &.-no-hint {
      .pg-block {
        .value-part {
          height: 60%; } } } }
  .pg-block {
    &.-page-views {
      .comparisons_plate-title {
        flex: 1 0 auto; }
      .value-part {
        flex: 0 0 auto;
        height: 70%;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px; } } } }
