.project-demo-share-buttons.-list + .sharing-buttons {
  margin-top: 10px; }
.sharing-buttons + .project-demo-share-buttons.-cards {
  margin-left: 5px; }

.project-demo-share-buttons {
  &.-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center; }

  button {
    padding: 5px;
    margin: 0;
    height: auto;
    font-size: 9px;
    border-radius: 4px;
    border: 1px solid $color-primary;
    background-color: transparent;
    color: $color-primary;
    &.-on {
      background-color: $color-primary;
      color: #fff; } } }
