.header-with-controls {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &.-in-page-container {
    margin: -10px 0 5px; }
  &.-description {
    font-size: 13px; } }

.header-with-controls_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.-align-bottom {
    align-items: flex-end; } }

.header-with-controls_addon {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.header-with-controls_title {
  margin-right: 20px; }

// for tabs 'thought bubble' & 'reasons for prefs' in naming research
.header-with-controls_custom-header {
  max-width: 500px;
  h3 {
    margin: 0 0 15px;
    font-size: 18px; }
  p {
    font-size: 15px;
    line-height: 1.3;
    & + p {
      margin-top: 5px; } } }
