.verizon-modifier {
  &.-research {
    .react-select__control {
      border-color: $va-border;
      background-color: $va-white;
      font-size: 15px;
      @include font-bold;
      border-radius: 38px; }

    .react-select__control--is-focused {
      border-color: $va-black;
      box-shadow: 0 0 0 1px $va-black; }

    .react-select__control--is-disabled {
      background-color: $va-white;
      opacity: 0.6;
      .react-select__value-container {
        opacity: 0.6; } }

    .react-select__value-container {
      font-size: inherit;
      padding: 2px 8px;
      color: $va-black; }

    .react-select__placeholder {
      color: $va-black; }

    .react-select__option {
      font-size: 15px;
      line-height: 1.1;
      color: $va-black;
      @include font-bold;
      padding: 10px 12px; }
    .react-select__option--is-focused {
      background-color: $va-stone; }
    .react-select__option--is-selected {
      background-color: $va-stone-darker; }


    .react-select__multi-value {
      background-color: $va-brand;
      color: $va-white;
      border-radius: 20px;
      margin: 2px; }

    .react-select__multi-value__label {
      color: inherit;
      padding: 0 5px;
      font-size: 12px;
      @include font-bold; }

    .react-select__multi-value__remove {
      color: $va-white;
      svg {
        width: 16px;
        height: 16px; }

      &:hover {
        background-color: transparent; } }

    .react-select__input-container {
      font-size: 12px;
      padding: 0 4px;
      margin: 0; }

    .react-select__menu {
      z-index: 200;
      box-shadow: none;
      margin: 6px 0; }

    .react-select__menu-list {
      border: 1px solid $va-gray-light-2;
      padding: 0; } } }
