.react-select-wrapper {
  &.-countries-dropdown {
    flex: 1 1 auto;
    .react-select__placeholder {
      margin-left: 8px; } }
  &.-clients-dropdown {
    flex: 1 1 auto;
    padding-left: 20px;
    .react-select__placeholder {
      margin-left: 8px; } }
  .react-select__multi-value__remove {
    border-radius: 0 12px 12px 0; } }
