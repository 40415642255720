.form_section-custom-metric-settings {
  display: flex;
  flex-direction: row;
  h3 {
    margin-bottom: 10px; }
  .form_section-title-item + .form_section-title-item {
    margin-left: 25px;
    max-width: 50%; }
  .form_section-title-item {
    display: flex;
    flex-direction: column;
    height: auto;
    .form_section-text {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center; } } }

.form_section-custom-metric-settings.-p35-p60 {
  .form_section-title-item:first-child {
    max-width: 35%; }
  .form_section-title-item + .form_section-title-item {
    margin-left: 25px;
    max-width: 60%; } }
