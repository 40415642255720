// Breakpoints

$screen-md: 1100px;
$screen-lg: 1280px;
$screen-xl: 1440px; // MBP13" = 1280; MBP15" = 1440; WSXGA = 1680; FHD = 1920; iMac = 2560

$break-medium-fonts-projects: 1233px;
$break-small-fonts-projects: 1140px;
$break-mobile: 768px;
$break-mobile-inter: 400px;
$break-mobile-between-small-and-inter: 345px;
$break-640: 640px;
$break-mobile-450: 450px;
$break-mobile-470: 470px;
$break-mobile-small: 320px;
$break-intermediate: 850px;

$break-vertical: 720px;

$break-menu-size: 920px;
$break-menu-size-2: 670px;
$break-menu-static: 550px;
$break-kids-question: 500px;

// Fonts
$font-family-base: Arial, sans-serif;
$font-size-base: 0.875rem;
$line-height-base: 1.6;
$font-family-title: Arial, sans-serif;

// Verizon Fonts
$font-verizon-bold: "NHaasGroteskDisplay75", sans-serif;
$font-verizon-normal: "NHaasGroteskDisplay55", sans-serif;
$font-verizon-thin: "NHaasGroteskDisplay45", sans-serif;
$font-verizon-title: $font-verizon-thin;
$font-verizon-text: $font-verizon-normal;
$font-verizon-text-alt: "NHaasGroteskTXPro", sans-serif;

// Colors
$color-text: #1F2542;
$color-body: #FFFFFF;
$color-primary: #20BEE8;
$color-text-gray: #797c8e;
$color-text-nav: #858193;
$color-text-secondary: #4C5168;

$color-primary-hover: #14B2DC;

$color-secondary: #EFF4F5;
$item-color-secondary: #ffffff;
$color-secondary-hover: #DBE6E9;

$color-page: #ffffff;
$color-active: #FDCB0F;
$color-gray: #8D8D8D;
$color-label: #A7A7A7;
$color-grayer: #6F7171;
$color-bar-gray: #D8DADA;
////$color-selected-respondent-id: #c7c7c7
$color-placeholder: #BFBFBF;
$color-border: #E5E5E5;
$color-lightgray: #f8f8f9;
$color-line: #898797;
$color-line-light: #CFDEE1;
$color-line-lighter: #e5eaeb;
$color-line-groups: #E7ECF1;
$color-payment-form-label: #A5A8B3;

$color-default: #A9BFC7;
//
$color-success: #65C18B;
$color-success-darker: #3CBCA1;
//$color-page-border: #393939
//$color-page-fill: #e3f0f1
$color-danger: #FD787A;
$color-warning: #FDA40F;
$color-neutral: #B2C0C2;
//
$color-top_20: #65C18B;
$color-top_40: #99CC57;
$color-middle_20: #FFAF14;
$color-bottom_40: #FDA878;
$color-bottom_20: #ED1C24;
$color-orange: #FC7128;
$color-red: #ED1C24;
//$color-banner: #514a4b
//$color-line: #e6ebed
//
$color-menu-active: #66B6FF;
//$color-menu-active-bg: #0F9AFF
//
$color-link: $color-primary; //#20BEE8
$color-link-hover: $color-primary-hover;
$color-link-gray: #A9BFC8;

$color-disabled: #d9dce0;
$color-border-disabled: #edf2f9;
//
//$color-no-tool: $color-placeholder
//$color-production: $color-success
//$color-test: $color-active

$color-options-description: #403C56;
$color-swipe-config: #AEC1C9;
$question-type-bg-color: #505054;
$question-description-color: #B9B8BB;
$color-chosen-group: #A8A5B2;
$color-chosen-selected-item: #A8A5B2;
$color-chosen-active: #595C5C;
$color-chosen-cross: #BFCFD5;
$color-base-gray: #97999F;
$color-flavor-label: #787F93;
$color-survey-light-grey: #E4EBEE;
$color-survey-dark-grey: #C4C4C4;
$color-survey-error: #FF6900;
$color-survey-zoom-note: #868294;
$color-survey-cart-text-color: #413C58;
$color-survey-budget: #A09DAB;

$va-green: #00B845;

//bg
$bg-gray: #F3F6F8;
$bg-chosen-form-gray: #F6F8F9;
$bg-chosen-multi-item: #F0F3F5;
$bg-concept-hover: #ECF9FC;
$bg-button-preview: #F5F5F6;
$bg-comparison-metric-title: #F2F5F7;
$bg-dark-grey: #DAE7EB;
$bg-button-light-grey: #E7EEF1;

//
//// Dropdowns

$min-width-dropdown: 180px;
$padding-side-dropdown: 20px;

$dropdown-light-bg: #fff;
$dropdown-light-border: #fff;
$dropdown-light-white-bg: #fff;
$list-highlight-bg: $color-primary;
$color-selector-list: $color-text;

$dropdown-shadow: 0 2px 20px rgba(#000, .15);

$dropdown-ppt-color: #BA281D;
$dropdown-xlsx-color: #377043;
$dropdown-google-color: #224567;

// Forms

$form-border-color: #C6D8DB;
$form-border-color-dark: #A9C3CA;
$form-border-radius: 14px;
$form-field-height: 38px;

// Button
$button-height: 38px;

// Sizes

$width-body: 1000px;
$page-header-height: 88px;

$sidebar-width: 200px;

//$width-sidebar: 80px
//$width-project-sidebar-collapsed: 14px
//$width-project-sidebar-expanded: 300px
//$height-masthead: 46px
//
//$width-container: $screen-xl - $width-sidebar
//
$x-padding: 20px;
//$padding-side: $x-padding
//$padding-side-big: 40px
//
$tile-notes-height: 60px;
//
// Misc

$overview-col-width: 100px;
$overview-col-height: 80px;

$transition: 0.15s ease;

$z-index-tooltip: 1060;
$z-index-header: 1061;
$z-index-lower-header: 1030;

$z-index-modal-back: 1034;
$z-index-modal: 1035;
$z-index-modal-dialog: 1050;
