.tile-header,
.tile-header h4 {
  font: bold 15px/1.4 $font-family-base; }

.tile-header h4 {
  margin: 0; }

.tile-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: -5px 0;
  padding: 0 0 10px;
  border-bottom: solid 1px $color-border;
  min-height: 34px;
  width: 100%;

  &.-no-border {
    border-bottom: none; }

  &.-nowrap-in-standalone {
    flex-wrap: nowrap; }

  &.-subtile-header {
    .-first & {
      margin-right: -23px;
      padding-right: 23px; }
    .-second & {
      .-double & {
        margin-left: -23px;
        padding-left: 23px; }
      .-triple & {
        margin-left: -23px;
        padding-left: 23px;
        margin-right: -23px;
        padding-right: 23px; } }
    .-third & {
      .-triple & {
        margin-left: -23px;
        padding-left: 23px; } } } }

.tile-header_subtitle {
  font-weight: 400;
  font-style: italic; }

.tile-header_main {
  flex-grow: 1;
  padding-right: 15px;
  margin: 5px 0; }

.tile-header_addon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 5px 0;
  //override for class in template
  .icon-link.-config {
    width: 38px;
    height: 38px;
    background: $color-secondary;
    color: $color-primary;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    &:hover,
    &:focus,
    &:active {
      color: $color-link-hover; } } }


.tile-header_addon-child {
  + .tile-header_addon-child {
    margin: 0 0 0 20px; } }

.tile-header {
  //override for classes in template
  .button.-export {
    text-align: left;
    text-indent: -1000px;
    overflow: hidden;
    width: 38px;
    height: 38px;
    padding: 0;
    background-color: $color-secondary;
    background-repeat: no-repeat;
    background-position: 50% 48%;
    background-size: 12px 15px;
    background-image: url('../assets/images/icons/i-export.svg'); } }




