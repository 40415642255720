.reason-open-end {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $color-line-light; }
.reason-open-end-details {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.15s ease-out;
  &.-expand {
    margin-top: 20px;
    grid-template-rows: 1fr; } }
.reason-open-end-details-inner {
  overflow: hidden; }
.-wysiwig-no-overflow {
  .reason-open-end-details-inner {
    overflow: visible; } }

.modal {
  &.-scorecard {
    .scorecard-reason-image {
      max-width: 100px;
      max-height: 100px; } } }
