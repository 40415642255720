.projects_list_table {
  background: #fff;
  border-radius: 10px;
  padding: 0;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.15);
  th {
    color: $color-base-gray; }
  td {
    color: $color-text; }
  th, td {
    text-align: left;
    font-size: 12px;
    padding: 14px 10px;
    &:first-child {
      padding-left: 20px; }
    &:last-child {
      padding-right: 20px; }
    @include break-medium-fonts-projects {
      font-size: 10px; }
    @include break-small-fonts-projects {
      font-size: 8px; } }

  .body-row {
    &:hover {
      background-color: #F7F9F9; }
    &:nth-child(odd) {
      background-color: #F7F9F9; }
    &.-copying {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.1; } } }

  .project-title-header,
  .project-test-header {
    width: 20%; }

  .project-title,
  .project-test {
    em {
      font-weight: bold;
      margin-left: 5px;
      font-style: normal;
      color: $color-gray; } }

  .project-actions {
    text-align: center;
    .dropdown {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center; }
    .dropdown_toggle {
      height: 20px;
      width: 20px;
      padding: 3px; }
    .dropdown_inset {
      width: 200px;
      ul {
        background-color: $bg-chosen-form-gray;
        margin: 0;
        width: 100%;
        padding: 14px 25px;
        li {
          list-style: none;
          margin: 0 0 7px 0;
          display: flex;
          flex-direction: row;
          padding: 0;
          a {
            color: $color-text;
            text-decoration: none;
            &:hover {
              color: $color-text;
              text-decoration: underline; } }

          &:last-child {
            margin-bottom: 0; }
          &.-red {
            a {
              color: $color-danger; } } } } } } }
