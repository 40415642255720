.sentiment {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: solid 1px $color-border;
  border-radius: 10px;
  padding: 15px 20px;
  &.-pinned {
    background: $color-secondary;
    border-color: $color-secondary; } }

.sentiment_playback {
  width: 30px;
  flex-grow: 0; }

.sentiment_content {
  width: calc(100% - 70px);
  padding: 0 15px;
  &.-grow {
    width: 100%;
    flex: 1 1 auto; } }

.admin_control {
  width: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right; }

.sentiment_control {
  width: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right; }

.sentiment_status {
  width: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right; }

.sentiment_status-warning {
  display: inline-block;
  position: relative;

  &.-filter-warning {
    top: -2px; } }
