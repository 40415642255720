.survey-wrapper {
  display: inline-block; }
.cit-concept {
  border: 1px solid #A7A7A7;
  border-radius: 8px;
  padding: 24px;
  width: 600px;
  font-size: 14px;
  font-family: Arial Regular, Arial, sans-serif;
  &.-width-650 {
    width: 650px; }
  h2 {
    margin: 0 0 24px 0;
    font-size: 18px;
    font-weight: normal; }
  p {
    margin: 0 0 5px 0;
    min-height: 5px; }
  ul, ol {
    margin: 0 0 12px 0;
    padding: 0 0 0 20px; }
  .cit-idea {
    margin: 0 0 24px 0;
    padding: 0; }
  .cit-steps-label {
    margin: 0 0 12px 0;
    font-size: 16px;
    font-weight: bold;
    &.-other {
      font-weight: normal; } }
  .cit-process {
    li + li {
      margin-top: 12px; }
    &.-image {
      text-align: center; }
    img {
      max-width: 100%;
      max-height: 600px; } }
  .cit-additional-image {
    margin-top: 15px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 600px; } }
  .cit-additional-image-limited {
    margin-top: 15px;
    text-align: center;
    max-height: 450px;
    img {
      max-width: 100%;
      max-height: 600px; } } }
