.filters-error-message-popup {
  position: absolute;
  z-index: 1063;
  background-color: #fff;
  vertical-align: bottom;
  padding: 20px;
  border: 1px solid $color-danger;
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center; }
