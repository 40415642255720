.report-plate_section {
  & + &, &.-first {
    margin-top: 40px; } }

.report-plate_row {
  display: flex;
  align-items: normal;
  justify-content: space-between;
  margin: -20px; }

.report-plate_col {
  flex: 1 1 100%;
  padding: 20px;
  min-width: 0; }

.report-plate {
  padding: 20px 24px;
  background-color: #F6F6F7;
  border: 1px solid #EFF4F5;
  border-radius: 20px;
  .report-plate_col > & {
    height: 100%; }
  &.-no-padding {
    padding: 0; } }






