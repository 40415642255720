.tiles_row {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 -8px;
  &.-wordcloud {
    position: relative;
    .loading-back {
      position: absolute;
      background: #fff;
      opacity: 0.9;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .loading {
      left: 50%;
      top: 50%;
      height: 24px;
      padding: 0;
      &:before {
        left: -27px; } } } }

.tiles_col {
  position: relative;
  flex: 0 1 50%;
  display: flex;
  flex-flow: column nowrap; }

.tiles_title {
  margin: 20px 0; }
