.button {
  display: inline-block;
  vertical-align: middle;
  font-family: $font-family-base;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  padding: 12px 20px;
  border-radius: $button-height;
  position: relative;
  margin: 0;
  line-height: 1;
  border: none;
  font-size: 14px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: $color-primary;
  color: #fff;
  text-decoration: none;
  outline: none;
  height: $button-height;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    color: #fff;
    background-color: $color-primary-hover; }

  &.-secondary {
    color: $color-text;
    background-color: $color-secondary;
    &:hover,
    &:active,
    &:focus {
      color: $color-text;
      background-color: $color-secondary-hover; } }
  &.-with-border {
    border: 1px solid; }
  &.-copy-url {
    border: 1px solid;
    padding: 7px 16px;
    height: auto; }

  &.-next {
    width: 140px;
    height: 44px; }
  &.-can-wrap {
    white-space: normal; }
  &.-nav-secondary {
    background-color: $color-secondary; }
  &.-white-back {
    background: #fff;
    &.-blue {
      color: $color-primary;
      font-size: 12px;
      height: 28px;
      padding-top: 8px; } }
  &.-dark-grey {
    background-color: #C9D7DA;
    color: #fff !important; }
  &.-darkest-grey {
    background-color: #97999F;
    color: #fff !important;
    &:hover, &:focus, &:active {
      background-color: #87898F; } }
  &.-block {
    display: block;
    width: 100%; }
  &.-fraud-block {
    display: block;
    padding: 14px;
    width: 272px;
    height: 44px;
    margin: 12px auto !important; }

  &.-sm {
    font-size: 10px;
    padding: 10px 10px;
    height: auto; }

  &.-circle {
    padding-left: 0;
    padding-right: 0;
    //for more accurate icon vertical-aligning
    padding-top: 12px;
    width: $button-height; }

  &.-single {
    margin-left: 10px; }

  & + & {
    margin-left: 15px; }

  & + &.-unchecked,
  & + &.-checked {
    margin-left: 5px; }

  &.-block + &.-block {
    margin-top: 12px;
    margin-left: 0; }

  &.-gray-color {
    color: #C5CBD1;
    &:hover, &:focus, &:active {
      color: #a0a5ab; } }

  &.-delete {
    background: none;
    color: $color-danger;
    &:hover, &:focus, &:active {
      background: none;
      color: #DD6511; } }

  &.-delete-color {
    color: $color-danger; }

  &.-disabled,
  &:disabled {
    &, &:hover, &:focus, &:active {
      pointer-events: none;
      cursor: default;
      transform: translate3d(0,0,0);
      opacity: .4;

      &.-gray-when-disabled {
        background: $color-text;
        opacity: .5; } } }
  &.-not-clickable {
    pointer-events: none;
    cursor: default; }

  &.-warning {
    color: #fff;
    background-color: $color-danger;
    &.-lighter {
      box-shadow: inset 0 0 0 2px $color-danger;
      background-color: rgba($color-danger, 0.8); }
    &.-bordered {
      color: $color-danger;
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $color-danger;
      &:hover {
        color: #fff;
        background-color: $color-danger; }
      &:active,
      &:focus {
        color: #fff;
        background-color: darken($color-danger, 10%);
        box-shadow: none; } }
    .button_icon-letter {
      color: $color-danger; } }

  &.-success {
    color: #fff;
    background-color: $color-success-darker;
    &.-bordered {
      color: $color-success-darker;
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $color-success-darker;
      &:hover {
        color: #fff;
        background-color: $color-success-darker; }
      &:active,
      &:focus {
        color: #fff;
        background-color: darken($color-success-darker, 10%);
        box-shadow: none; } } }

  &.-full-width {
    width: 100%; }

  &.-height-44 {
    min-height: 44px;
    height: auto;
    max-width: 300px; }
  &.-allow-wrap {
    line-height: 1.3;
    white-space: normal; }

  &.-customize {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    height: 24px;
    margin-top: 8px;
    text-align: center; }

  &.-transparent {
    visibility: hidden;
    cursor: none; } }

.image-preview {
  font-family: $font-family-base;
  background-color: $bg-button-preview;
  color: $color-primary-hover;
  height: 30px;
  width: 100px;
  border: none;
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  &.-in-naming-popup {
    background: rgba(#fff, 0.8); } }

button.button {
  //for more accurate text vertical-aligning
  padding-top: 10px; }

.button_text,
.button_icon {
  display: inline-block;
  vertical-align: middle; }

.button_text + .button_icon,
.button_icon + .button_text {
  margin: 0 0 0 8px; }

.button_icon {
  &.-delete {
    color: $color-danger;
    //&:hover, &:focus, &:active
 } }    //color: #DD6511


.button_text {
  .button.-no-text & {
    display: none; } }

.button.-dropdown {
  padding-right: 35px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    border-top: 4px solid #fff;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    top: 50%;
    margin-top: -1px;
    right: 20px; } }

.-gl-button {
  display: inline-block;
  vertical-align: middle;
  font-family: $font-family-base;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  padding: 12px 20px;
  border-radius: $button-height;
  position: relative;
  margin: 0;
  line-height: 1;
  border: none;
  font-size: 14px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: $color-primary;
  color: #fff;
  text-decoration: none;
  outline: none;
  height: $button-height;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    color: #fff;
    background-color: $color-primary-hover; }

  &.-left {
    &:after {
      content: '';
      position: absolute;
      border-left: 1px solid white;
      right: 1px;
      height: 40%; }
    border-radius: 20px 0px 0px 20px; }

  &.-right {
    border-radius: 0px 20px 20px 0px; }

  &.-both {
    border-radius: 20px; } }

.button_icon-letter {
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  background-color: #fff;
  height: 18px;
  min-width: 18px;
  text-align: center;
  padding: 2px;
  border-radius: 18px;
  margin-left: 8px;
  color: $color-text; }

