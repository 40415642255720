.tile {
  display: block;
  position: relative;
  padding: 8px;
  margin: 0;
  width: 100%;
  min-height: 120px;

  &.-with-notes {
    padding-bottom: $tile-notes-height; }

  .tiles_col & {
    height: 100%; }

  // IE11 is not good with flex-basis, so using height/width instead
  &.-advocacy-indicators {
    height: 45%; }

  &.-pain-indicators {
    height: 55%; }

  &.-sentiments-side {
    width: 260px;
    flex: 0 0 auto;

    @include breakpoint-hi($screen-lg) {
      width: 370px; } }

  &.-sentiments-main {
    width: 100%;
    flex: 1 1 auto; }

  &.-double {
    flex-grow: 2;

    &.-with-borders {
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 83px;
        bottom: 23px;
        width: 1px;
        background: $color-border; }

      &.-with-notes {
        &:before {
          bottom: 23px + $tile-notes-height; } } } }

  &.-triple {
    flex-grow: 3;

    &.-with-borders {

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: calc(33.3333% + 7px);
        top: 83px;
        bottom: 23px;
        width: 1px;
        background: $color-border; }

      &:after {
        left: auto;
        right: calc(33.3333% + 7px); }

      &.-with-notes {
        &:before,
        &:after {
          bottom: 23px + $tile-notes-height; } } } }

  &.-no-min-height {
    min-height: auto; } }

.tile_inset {
  height: 100%;
  background: #fff;
  padding: 20px;
  overflow: hidden;
  @include clearfix;

  &.-with-border {
    border: solid 1px $color-border;
    border-radius: 10px; } }

.tile_subtile {
  float: left;
  width: 50%;
  height: 100%;
  position: relative;

  .-double & {
    width: 50%;
    &.-first {
      padding-right: 23px; }
    &.-second {
      padding-left: 23px; } }

  .-triple & {
    width: 33.33%;
    &.-first {
      padding-right: 23px; }
    &.-second {
      padding: 0 23px; }
    &.-third {
      padding-left: 23px; } } }

.tile_notes {
  padding: 0; }

.tile_concept {
  text-align: center; }

.tile_concept-img {
  width: auto;
  height: auto;
  max-width: 100%; }
