.form_section.-black-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.black-border {
  position: relative;
  border: 1px solid black;
  border-radius: 14px;
  height: 250px;
  margin: 10px;
  overflow: hidden;

  &.has-error {
    border: 1px solid red; }

  &.-first {
    width: 100%; }

  &.-half {
    width: 47%; }

  .-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 100%;
      max-height: 248px; } }

  .upload-text-main {
    font-size: 15px;
    color: $va-gray-darker; }

  .upload-text-secondary {
    font-size: 12px;
    color: $va-gray-darker; }

  .delete-btn {
    padding: 8px 11px 8px 12px;
    border-radius:  0 0 0 12px;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    .icon {
      color: $va-brand; } } }
