.form_section {
  &.-payments-tab {
    .form_section-tabs {
      margin-top: 25px; }
    .form_section-text.-label {
      margin-bottom: 15px; } }
  &.-credits-form {
    .form-pay_price-grid-item {
      &:before {
        content: none; } } }
  &.-target-tab {
    margin: 15px 0 35px;
    .form_section-tabs {
      margin: 0; }
    .form_section-text.-label {
      margin-bottom: 5px; }
    &.-no-bottom-margin {
      margin-bottom: 0; } }
  &.-separate-message {
    margin-top: 5px; } }

.form-pay_container {
  max-width: 400px; }

.form-pay_hint {
  font-size: 13px; }

.form-pay_size-select {
  min-width: 180px; }

.form-pay_price-grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -23px;
  &.-grow-with-max {
    flex: 1 1 400px; } }

.form-pay_price-grid-item {
  flex: 0 0 auto;
  padding: 0 23px;
  position: relative;
  & + & {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      height: $form-field-height;
      left: 0;
      width: 1px;
      background: #DEE5E6; } } }

.selected-number-message {
  font-size: 10px;
  color: $color-payment-form-label;
  font-weight: bold;
  text-align: left;
  margin-top: 5px;
  &.-width-500 {
    width: 500px; }
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0 0 0 10px; } } }
.input-item-note-payment {
  font-size: 11px;
  color: $color-payment-form-label;
  font-weight: bold;
  margin-bottom: 5px; }
