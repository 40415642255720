.-executive-view {
  h3.fs18 {
    font-size: 18px !important; }

  table.data-table {
    width: 100%;
    border-collapse: collapse;
    font-family: $font-verizon-bold;
    border-bottom: 1px solid $va-gray-light-2;

    th {
      text-align: center; }
    th:nth-child(1) {
      width: 40%; }
    td:nth-child(2) {
      text-align: center; }
    td:nth-child(3) {
      text-align: center; }

    tr.-border-bottom {
      border-bottom: 1px solid $va-gray-light-2; } }

  .pie {
    max-width: 300px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    text {
      fill: black;
      font-weight: 600;
      &.pie-text.white {
        fill: white; }
      &.pie-text.black {
        fill: black; } }
    circle, path {
      &.green {
        fill: $va-green; }
      &.red {
        fill: $va-red; }
      &.orange {
        fill: $va-orange; } } }

  table.executive-numbers {
    width: 100%;
    margin: 3px;
    border-collapse: collapse;
    font-family: $font-verizon-normal;
    border: 2px solid $va-red;
    tr.titles {
      font-size: 18px; }
    tr.red-line {
      background-color: $va-red;
      color: white; }
    tr.sand-line {
      background-color: $va-stone; }
    td, th {
      padding: 2px 4px 2px 4px;
      height: 48px;
      width: 9%;
      &.red-right {
        border-right: 2px solid $va-red; } }
    th:nth-child(1) {
      width: 12%; }
    th:nth-child(5) {
      width: 7%; }
    th:nth-child(11) {
      width: 10%; }
    tbody {
      td:not(:first-child) {
        text-align: center; }
      td {
        border-bottom: 1px solid $va-grey-border; } }
    span.note {
      font-family: $font-verizon-thin;
      font-weight: 400; }
    span.big {
      font-size: 22px; } }

  .scores {
    text {
      &.bold {
        font-family: $font-verizon-bold; }
      &.gray {
        fill: $va-dark-grey-text; } }
    circle.gray {
      fill: $va-gray-text; }
    circle.red-ring {
      stroke: $va-red;
      fill: white;
      cursor: pointer; }
    line {
      &.gray {
        stroke: $va-gray-text; }
      &.red {
        stroke: $va-red; } }
    rect.period-bg {
      fill: $va-stone; } }

  &.verizon-modifier.-research {
    .react-select__multi-value {
      background-color: black; } }

  &.verizon-modifier {
    .button.-secondary {
      background-color: $va-stone; } }

  .modal-content {
    border: 1px solid black;
    border-radius: 0;

    .summary-editor_body {
      overflow-y: clip; } }

  .square {
    width: 34px;
    height: 30px;
    display: inline-block;
    padding-top: 11px;
    padding-bottom: 24px;
    color: white;
    margin: 0 5px 0 5px;
    text-align: center;
    cursor: pointer;
    .icon {
      height: 13px; }
    &.red {
      background-color: $va-red; }
    &.green {
      background-color: $va-green; }
    &.-disabled {
      opacity: 0.25; } }

  span.red {
    color: $va-red; } }

.program-health {
  .reset-link {
    margin-left: 10px;
    color: $color-grayer; } }

