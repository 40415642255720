.loader {
  user-select: none;

  &.-loading-blink {
    animation: loading-blink 1s infinite; } }

@keyframes loading-blink {
  0%, 100% {
    opacity: .4; }
  50% {
    opacity: 1; } }
