.bar-table {
  width: 100%;

  tr {
    td:nth-of-type(1) {
      width: 33%; }
    td:nth-of-type(2) {
      width: 10%;
      text-align: right;
      font-family: $font-verizon-bold;
      padding-right: 4px; }
    td:nth-of-type(3) {
      width: 57%;
      position: relative;
      .bar-100 {
        position: absolute;
        width: 100%;
        height: 10px;
        background-color: $bg-gray;
        top: calc(50% - 5px);
        border-radius: 5px; }
      .bar-red {
        position: absolute;
        height: 10px;
        background-color: $color-red;
        top: calc(50% - 5px);
        border-radius: 5px; } } } }

