// Selector list styles (used in dropdowns)

.selector-list {
  list-style: none;
  margin: 0;
  padding: 5px 10px;
  &:empty {
    padding: 0; }
  li {
    margin: 7px 0;
    text-decoration: none;
    color: $color-text;
    &:first-child {
      margin-top: 5px; }
    &:last-child {
      margin-bottom: 5px; } } }








