h1 {
  font-family: $font-family-title;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.2;
  margin: 0; }

h3 {
  font-family: $font-family-title;
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.2; }

p {
  margin: 0;
  & + & {
    margin-top: 15px; } }
