$z-index-back: $z-index-modal - 2;  // 1033
$z-index-anchor: $z-index-back + 60;   // 1034
$z-index-baloon: $z-index-back + 61;  //1035

$bg-backdrop: rgba(#D6DCE3, 0.7);
$bg-baloon: #fff;
$arrow-size: 10px;
$margin-baloon: 25px;
$margin-arrow-x: 60px;
$margin-arrow-y: 20px;

@mixin arrow($direction: top) {
  border-color: transparent;
  border-style: solid;
  border-width: $arrow-size;
  @if $direction == top {
    border-bottom-color: $bg-baloon; }
  @else if $direction == bottom {
    border-top-color: $bg-baloon; }
  @else if $direction == left {
    border-right-color: $bg-baloon; }
  @else if $direction == right {
    border-left-color: $bg-baloon; } }


.tour-hint {
  width: auto;
  position: fixed;
  text-align: left;
  font-weight: normal;
  z-index: $z-index-baloon;

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0; }

  // ---  bottom blocks ---

  &.-bottom-left {
    margin-top: $margin-baloon;
    &:before {
      @include arrow(top);
      left: $margin-arrow-x;
      top: -2*$arrow-size; } }

  &.-bottom-center {
    margin-top: $margin-baloon;
    &:before {
      @include arrow(top);
      left: 50%;
      margin-left: -$arrow-size;
      top: -2*$arrow-size; }
    .tour-hint_content {
      transform: translate(-50%, 0); } }

  &.-bottom-right {
    margin-top: $margin-baloon;
    &:before {
      @include arrow(top);
      right: $margin-arrow-x;
      top: -2*$arrow-size; }
    .tour-hint_content {
      transform: translate(-100%, 0); } }

  // ---  top blocks ---

  &.-top-left {
    margin-top: -$margin-baloon;
    &:before {
      @include arrow(bottom);
      top: 0;
      left: $margin-arrow-x; }
    .tour-hint_content {
      transform: translate(0, -100%); } }

  &.-top-center {
    margin-top: -$margin-baloon;
    &:before {
      @include arrow(bottom);
      top: 0;
      left: 50%;
      margin-left: -$arrow-size; }
    .tour-hint_content {
      transform: translate(-50%, -100%); } }

  &.-top-center-middle {
    margin-top: -$margin-baloon;
    &:before {
      @include arrow(bottom);
      top: 0;
      left: 0;
      margin-left: -$arrow-size; }
    .tour-hint_content {
      transform: translate(-50%, -100%); } }

  &.-top-right {
    margin-top: -$margin-baloon;
    &:before {
      @include arrow(bottom);
      top: 0;
      right: $margin-arrow-x; }
    .tour-hint_content {
      transform: translate(-100%, -100%); } }

  // ---  left blocks ---

  &.-left-top {
    margin-left: -$margin-baloon;
    &:before {
      @include arrow(right);
      top: $margin-arrow-y;
      left: 0; }
    .tour-hint_content {
      transform: translate(-100%, 0); } }

  &.-left-middle {
    margin-left: -$margin-baloon;
    &:before {
      @include arrow(right);
      top: 50%;
      margin-top: -$arrow-size;
      left: 0; }
    .tour-hint_content {
      transform: translate(-100%, -50%); } }

  &.-left-bottom {
    margin-left: -$margin-baloon;
    &:before {
      @include arrow(right);
      bottom: $margin-arrow-y;
      left: 0; }
    .tour-hint_content {
      transform: translate(-100%, -100%); } }

  // ---  right blocks ---

  &.-right-top {
    margin-left: $margin-baloon;
    &:before {
      @include arrow(left);
      top: $margin-arrow-y;
      left: -2*$arrow-size; }
    .tour-hint_content {
      transform: translate(0, 0); } }

  &.-right-middle {
    margin-left: $margin-baloon;
    &:before {
      @include arrow(left);
      top: 50%;
      margin-top: -$arrow-size;
      left: -2*$arrow-size; }
    .tour-hint_content {
      transform: translate(0, -50%); } }

  &.-right-bottom {
    margin-left: $margin-baloon;
    &:before {
      @include arrow(left);
      bottom: $margin-arrow-y;
      left: -2*$arrow-size; }
    .tour-hint_content {
      transform: translate(0, -100%); } } }

.tour-hint_content {
  padding: 25px 30px 28px;
  box-shadow: 0px 8px 16px rgba(65, 60, 88, 0.25);
  background-color: #ffffff;
  border-radius: 14px;
  max-width: 450px;
  min-width: 330px;
  position: absolute;
  left: 0;
  top: 0; }

.tour-hint_text {
  color: $color-text;
  font-size: 15px;
  line-height: 1.4; }

.icon.tour-hint_icon-gear {
  color: $color-primary;
  width: 14px;
  height: 14px;
  vertical-align: middle; }

.tour-hint_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; }

.tour-hint_button {
  font-weight: normal;
  min-width: 95px;
  &.-finish {
    min-width: 150px; }
  & + & {
    margin-left: 10px; } }

.tour-hint_backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-index-back;
  background: $bg-backdrop; }


.tour-mode.modal.fade.in {
  pointer-events: none;
  overflow: hidden;
  .modal_body {
    overflow: visible !important; }
  .hide-in-tour-modal {
    display: none; } }

.table-comparison tr.tour-anchor-parent-active {
  td {
    background: #fff;
    position: relative;
    z-index: $z-index-anchor !important; } }

.tour-anchor-parent-active {
  pointer-events: none;
  z-index: $z-index-anchor;

  &.tour-parent-header {
    z-index: initial;
    pointer-events: auto;
    .content_top {
      pointer-events: none;
      z-index: $z-index-anchor;
      background: #fff;
      position: relative;
      box-shadow: 0 20px 0 20px #fff;
      border-radius: 1px 1px 0 0;
      margin-top: 10px;
      padding-bottom: 10px;
      height: calc(#{$page-header-height} - 10px); } }

  &.markups_grid {
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop;
    border-radius: 20px 20px 0 0;
    position: relative; }

  &.tile_inset {
    box-shadow: 0 0 0 1000em $bg-backdrop;
    position: relative; } }

.table-comparison {
  th {
    &.tour-anchor-parent-active {
      position: relative;
      z-index: 9999;
      background: none; } } }

.tour-anchor-active {
  position: relative;
  z-index: $z-index-anchor;
  pointer-events: none;

  &.tour-header {
    background: #fff;
    box-shadow: 0 0 0 20px #fff;
    border-radius: 0 0 1px 1px; }

  &.dropdown_container {
    position: absolute;
    z-index: 9999 !important;
    .dropdown_inset {
      box-shadow: 0 0 0 0 #fff, 0 0 0 1000em $bg-backdrop; } }

  &.-concept-card {
    background: #E9F8FD;
    border-radius: 10px;
    &.-naming {
      background: #fff;
      border-radius: 10px;
      box-shadow: inset 0 0 0 5px #e9f8fd; } }

  &.concept-report_header-item {
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.concept-report_tables, &.full-modal-table {
    border-radius: 10px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.radio-tabs {
    box-shadow: 0 0 0 2px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tile_inset {
    box-shadow: 0 0 0 1000em $bg-backdrop; }

  &.switch-sentiments {
    border-radius: 30px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  // TURF
  &.comparisons_plate-item.-width-1-3 {
    border-radius: 30px;
    box-shadow: 0 0 0 0 #fff, 0 0 0 1000em $bg-backdrop; }

  &.table.table-comparison {
    border-radius: 20px;
    box-shadow: 0 0 0 0 #fff, 0 0 0 1000em $bg-backdrop; }

  &.-highlight {
    box-shadow: 0 0 0 10px #fff;
    border-radius: 10px;
    td {
      background: #fff; } }

  &.header-with-controls_title, &.report-plate_col {
    background: #fff;
    box-shadow: 0 0 0 12px #fff;
    border-radius: 10px; }

  &.attr-ratings_graph:after {
    background: #fff;
    box-shadow: 0 0 0 12px #fff;
    border-radius: 10px;
    position: absolute;
    content: '';
    width: 70%;
    height: 80%;
    top: 40%;
    left: 40%;
    z-index: -1; } }
