.verizon-modifier {
  &.-comparison {
    .comparisons_title {
      @include font-bold;
      font-size: 26px;
      margin: 0 0 2px; }

    .comparisons_notes {
      font-size: 14px; }

    .comparisons_filters {
      margin: 15px 0 1px; }

    .comparisons-table_header-image {
      max-width: 246px;
      height: 120px;
      overflow: hidden;
      text-align: center;
      display: inline-block;
      border: 1px solid #D8DADA;
      border-radius: 6px;
      &.-minimize {
        height: 40px; }
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: contain; } }

    .comparisons-table_header-links {
      font-size: 12px;
      margin-top: 5px; }

    .comparisons-table_header-link {
      line-height: 1;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      & + .comparisons-table_header-link {
        margin-top: 2px; } } }

  .wrapper-for-huge-symbol {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .huge-symbol-arrow-right {
    width: 32px;
    height: 41px;
    position: relative;
    &:before, &:after {
      position: absolute;
      content: '';
      width: 3px;
      height: 25px;
      background-color: $va-black;
      right: 13px; }
    &:before {
      transform: rotate(45deg);
      bottom: 0; }
    &:after {
      transform: rotate(-45deg);
      top: 0; } }

  .huge-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .number {
      font-family: $font-verizon-bold;
      font-size: 32px; }
    .text {
      font-size: 18px; } } }


