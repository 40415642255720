.keyword {
  font-family: $font-family-base;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: solid 1px $color-border;

  &:first-child {
    border-top: solid 1px $color-border; } }

.keyword_checker {
  width: 40px;
  margin: 0 0 -2px;
  padding: 2px 0 0;
  flex-grow: 0; }

.keyword_control {
  width: 40px;
  flex-grow: 0; }

.keyword_content {
  flex-grow: 1;
  padding: 0; }

.keyword_static {
  &.-clickable {
    cursor: pointer; } }

.keyword_icon {
  color: #cecfcf;
  vertical-align: middle;
  margin: 0 10px 0 0; }

.keyword_text {
  display: inline;
  vertical-align: middle; }

.keyword_edit {
  margin: -6px 0 -7px;
  display: flex;
  flex-flow: row nowrap; }

.keyword_edit-inplace {
  flex-grow: 1; }

.keyword_edit-control {
  padding: 0 0 0 8px; }

.keyword_info {
  position: relative;
  width: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  color: $color-gray;
  text-align: right;
  padding: 0 10px; }

.keyword_merged-control {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 16px 10px 16px;
  background: #fff;
  min-width: 210px;
  display: none;

  .keyword:hover & {
    display: block; } }

.keyword_merged-buttons {
  white-space: nowrap;

  > .icon-link + .icon-link {
    margin-left: 30px; } }
