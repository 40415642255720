.tile-notes {
  display: flex;
  flex-flow: row nowrap;
  min-height: $tile-notes-height;
  margin: 4px 0;
  padding: 0 8px;
  font-size: 13px;
  line-height: 1.3;
  color: $color-gray;

  &.-no-height {
    min-height: 0; }

  &.-tab-notes {
    padding: 0;
    margin: 10px 0 4px; } }

.tile-notes_l {
  width: 100%; }

.tile-notes_r {
  min-width: 20%;
  padding: 0 0 0 20px;
  text-align: right; }

.tile-notes_no-wrap {
  white-space: nowrap;
  overflow-wrap: break-word; }
