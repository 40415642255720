.concept-preview_thumb {
  border-radius: 10px;
  width: 100px;
  height: 100px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #EFF4F5;
  overflow: hidden;
  img {
    display: none; }
  &.-sm {
    width: 60px;
    height: 60px; }
  &.-less-rounded {
    border-radius: 5px; }
  &.-dark {
    background-color: #E0E6EB; } }

.concept-preview_naming {
  border-radius: 6px;
  border: 1px solid $bg-comparison-metric-title;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  overflow: hidden; }

.concept-preview_naming-inner {
  font-size: 10px;
  font-weight: normal;
  line-height: 1.3;
  color: #000; }

.concept-preview_link {
  font-size: 11px; }

.-concept-card {
  position: relative;
  &:hover + .concept-preview_full {
    opacity: 1;
    visibility: visible; } }

.concept-preview_full {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  width: 300px;
  height: 300px;
  margin: 0;
  padding: 0;
  z-index: 1020;
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    &.-naming {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-style: solid;
      border-color: transparent;
      border-width: 20px 0;
      padding: 0 5px;
      overflow: hidden; }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      max-height: calc(100% - 85px);
      margin: auto; } }

  &:hover {
    opacity: 1 !important;
    visibility: visible !important; }
  .button-place {
    bottom: 10px;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center; }
  &.in {
    position: relative;
    opacity: 1 !important;
    visibility: visible !important; } }

.concept-preview_full-naming-text {
  font-size: 32px;
  line-height: 1.05;
  color: #000;
  width: 100%; }

.concept-preview_pic-big {
  height: auto;
  max-width: 550px;
  max-height: calc(100vh - 400px);
  width: auto;
  @include breakpoint-lo-height(500px) {
    max-height: 100px; } }

.th-text-popup-content {
  .th-text-popup-arrow {
    display: none; } }

.th-image-popup-content {
  .th-image-popup-arrow {
    display: none; } }
