.status-circle {
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  border-radius: 100%;
  background: currentColor;
  text-align: center;
  &.-color-below {
    color: $color-red; }
  &.-color-above {
    color: $color-success; }
  &.-color-par {
    color: $color-neutral; }
  &.-color-top_20 {
    color: $color-top_20; }
  &.-color-top_40 {
    color: $color-top_40; }
  &.-color-middle_20 {
    color: $color-middle_20; }
  &.-color-bottom_40 {
    color: $color-bottom_40; }
  &.-color-bottom_20 {
    color: $color-bottom_20; }
  &.-color-green {
    color: $color-success; }
  &.-color-blue {
    color: $color-primary; }
  &.-color-none {
    color: transparent;
    .status-circle_text {
      color: $color-text;
      font-weight: normal; }

    &.-with-letters {
      width: auto;
      height: auto;
      max-width: 108px;
      line-height: normal;
      .status-circle_text {
        font-weight: bold;
        word-wrap: break-word; } } }

  &.-small {
    width: 18px;
    height: 18px; } }

.status-circle_text {
  color: #fff;
  font-size: 13px;
  letter-spacing: -1px;
  font-weight: bold; }

.with-number {
  margin-left: 5px;
  &.-in-scorecard {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  &.-each {
    margin-left: 0;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: -10px; } }

