.button-plate_group {
  margin: -4px; }

.button-plate {
  background-color: #E7EEF1;
  color: $color-flavor-label;
  border-radius: 4px;
  height: 125px;
  width: 125px;
  margin: 4px;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background-color: #ffffff;
    border-color: $color-primary; }
  &.-checked {
    background-color: #ffffff;
    border-color: $color-primary; }
  &.-size-x025 {
    font-size: 16px; }
  &.-size-x05 {
    font-size: 20px; }
  &.-size-x2 {
    font-size: 28px; }
  &.-size-x4 {
    font-size: 32px; } }
