// special styles for some tour steps in claim
// special position of hint

.tour-hint {
  &.-tour-claim-1 {
    margin-left: -20px;
    margin-top: 40px;
    .tour-hint_content {
      min-width: 470px; } }

  &.-tour-claim-2 {
    margin-top: -15px;
    .tour-hint_content {
      min-width: 270px; } }

  &.-tour-claim-4 {
    margin-left: 134px;
    &:before {
      margin-left: -145px; } }

  &.-tour-claim-5 {
    margin-left: -5px; }

  &.-tour-claim-6 {
    margin-top: 0;
    margin-left: 0; }

  &.-tour-claim-7 {
    margin-top: -15px; }

  &.-tour-claim-9, &.-tour-claim-14 {
    margin-top: -14px; }

  &.-tour-claim-10 {
    margin-top: 15px;
    &:before {
      left: 50px; } }

  &.-tour-claim-11 {
    margin-top: 15px; }

  &.-tour-claim-12 {
    margin-left: 15px; }

  &.-tour-claim-15 {
    margin-top: 15px;
    &:before {
      right: 50px; } }

  &.-tour-claim-16, &.-tour-claim-17 {
    &:before {
      left: 70px; } } }

//styles to show anchors for tour in claim

.tour-claim-anchor-active {
  position: relative;
  z-index: $z-index-anchor;
  pointer-events: none;

  // special styles for controls than we want to show in tour

  &.tour-claim-1 {
    background: #fff;
    box-shadow: 0 0 0px 20px #fff;
    border-radius: 0 0 1px 1px; }

  // save pseudo focus state on chosen control and open state for dropdown after click outside
  &.tour-claim-2.chosen-container {
    .chosen-choices {
      border-color: $color-primary; }
    .chosen-drop {
      clip: auto;
      clip-path: none; } }

  //open dropdown
  &.tour-claim-3 {
    @include dropdown-show; }

  &.tour-claim-7, &.tour-claim-12 {
    box-shadow: 0 0 0 1000em $bg-backdrop; }

  &.tour-claim-8, &.tour-claim-13 {
    border-radius: 30px;
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop; }

  &.tour-claim-9, &.tour-claim-10, &.tour-claim-14 {
    box-shadow: 0 0 0 1000em $bg-backdrop; }

  //open dropdown
  &.tour-claim-17 {
    @include dropdown-show; } }


// for cases when parent is highlighted but arrow show on children
// in modal (box-shadow as backdrop)

.tour-claim-anchor-parent-active {
  pointer-events: none;
  z-index: $z-index-anchor;

  // very special case (need to show 2 blocks from different parents)
  &.tour-claim-1-parent {
    z-index: initial;
    pointer-events: auto;
    .content_top {
      pointer-events: none;
      z-index: $z-index-anchor;
      background: #fff;
      position: relative;
      box-shadow: 0 20px 0 20px #fff;
      border-radius: 1px 1px 0 0;
      margin-top: 10px;
      padding-bottom: 10px;
      height: calc(#{$page-header-height} - 10px); } }

  &.tour-claim-5-parent, &.tour-claim-6-parent, &.tour-claim-11-parent {
    box-shadow: 0 0 0 1000em $bg-backdrop;
    position: relative; }
  &.tour-claim-15-parent, &.tour-claim-16-parent {
    box-shadow: 0 0 0 10px #fff, 0 0 0 1000em $bg-backdrop;
    border-radius: 20px 20px 0 0;
    position: relative; }
  &.tour-claim-17-parent {
    box-shadow: 0 0 0 1000em $bg-backdrop;
    border-radius: 20px; } }

.table-comparison tr.tour-claim-anchor-parent-active {
  td {
    background: #fff;
    position: relative;
    z-index: $z-index-anchor !important; } }
