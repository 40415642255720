.checkbox-multi {
  margin: -3px;
  &.-read-only {
    opacity: 0.5; } }

.checkbox-multi_item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 3px;
  input {
    //don't hide it using "display: none" or "vis: hidden" because error tooltip is not shown in this case
    position: absolute;
    opacity: 0; }
  &.-disabled {
    label {
      background-color: #FCFCFD;
      color: #d9dce0;
      cursor: default; } } }

.checkbox-multi_label {
  display: block;
  color: $color-text;
  background-color: $item-color-secondary;
  padding: 10px 20px;
  height: 30px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  &:hover,
  &:active,
  &:focus {
    color: $color-text;
    background-color: $color-secondary-hover; }
  input:checked + & {
    color: #fff;
    background-color: $color-primary; }
  user-select: none; }
