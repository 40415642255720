.custom-answers-sorting {
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0 0 15px 0;
  h3 {
    margin: 0 15px 0 0; }
  .comparisons_tabs {
    margin: 0;
    .radio-tabs {
      height: auto; }
    .radio-tabs_item {
      height: auto; }
    .radio-tabs_label {
      min-width: 75px;
      padding: 5px 14px; } } }
.auto-sort-settings {
  padding: 15px 0;
  p + p {
    margin-top: 10px; } }
.concept-auto-sort {
  display: flex;
  flex-wrap: wrap;
  white-space: break-spaces; }
