.processing-spinner {
  display: inline-block;
  width: 18px;
  height: 18px; }

.processing-spinner_icon {
  animation: processing-spinner 2s infinite linear; }

@keyframes processing-spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
