.markups_plate {
  border: 1px solid #E5EAEB;
  border-top: 0;
  border-radius: 0 0 14px 14px;
  padding: 35px 20px 20px;
  margin-top: -15px; }

.markups_plate-grid {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.markups_plate-item {
  flex: 0 0 auto; }

.markups_grid {
  display: flex;
  justify-content: space-between;
  margin-top: 40px; }

.markups_grid-item {
  flex: 1 1 50%;
  & + & {
    margin-left: 50px; } }




.markups_opacity {
  float: right;
  max-width: 220px;
  width: 50%;
  margin: -45px 0 0;
  display: flex;
  align-items: center;
  h3 {
    font-size: 13px;
    color: #787F93;
    flex: 0;
    margin: 0 10px 0 0; }
  .rzslider {
    flex: 1;
    margin: auto 0;
    .rz-limit {
      display: none; } } }


.markups_comments {
  margin-top: 30px; }

