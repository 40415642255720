.concept-report {
  min-width: 850px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start; }

.concept-report_header {
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 25px;
  flex: 0 0 auto; }

.concept-report_header-item {
  //flex: 0 0 auto
  & + & {
    margin-left: 20px; } }

.concept-report_header-subtitle {
  color: $color-primary;
  font-size: 12px;
  line-height: 1.2;
  font-weight: bold; }

.concept-report_header-title-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.concept-report_header-title {
  margin-bottom: 0;
  line-height: 1.2;
  max-width: 80%; }

.concept-report_header-count {
  margin-left: 10px;
  color: #00B7BB;
  background: #E4FAFB;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 11px;
  font-weight: bold; }

.concept-report_header-switch {
  color: $color-link;
  font-weight: bold;
  margin-left: 15px;
  cursor: pointer; }

.-switcher-icon {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -3px; }

.concept-report_header-count-icon {
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 2px; }

.concept-report_body {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  &.-scrollable {
    max-height: none; } }

.concept-report_tables {
  margin-top: 25px;
  margin-bottom: 45px; }

.concept-report_content {
  margin-top: 25px;
  margin-bottom: 45px; }

.concept-report_tab {
  position: relative;
  padding: 40px 0 10px;
  //for loading space
  min-height: 300px;
  &.-markup {
    padding-top: 0; }
  & + & {
    margin-top: 25px; } }

.concept-report_section {
  & + & {
    margin-top: 40px; } }

.switch-sentiments {
  margin-left: 10px; }

.express-language-control {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  h4 {
    margin-right: 20px; }
  .language-switcher {
    height: 30px;
    margin-right: 5px; }
  .radio-tabs_label {
    height: 100%;
    padding: 6px 20px; } }
