.color-picker-expanded {
  position: relative; }
.color-picker-positioning {
  position: absolute;
  left: -107px;
  top: 2px;
  z-index: 3; }

.custom-color-picker-template {
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  border: 1px solid $form-border-color;
  padding: 15px;
  max-width: 210px;
  background-color: #fff;
  &.-react-way {
    min-width: 250px;
    max-width: none; }

  h4 {
    margin: 0 0 10px 0; }

  input[type="text"] {
    position: relative;
    z-index: 1;
    display: inline-block;
    outline: none;
    font-size: 14px;
    padding: 5px;
    background-color: #ffffff;
    margin: 0;
    border: 1px solid $form-border-color;
    border-radius: 6px;
    box-shadow: none;
    color: $color-text;
    width: 100px;
    max-height: 25px;
    margin-right: 10px; }

  .color-box {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    border-radius: 6px;
    cursor: pointer; }

  .color-values {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 15px; }

  .color-buttons {
    button {
      height: 25px;
      line-height: 0.2; }
    button:last-child {
      margin-left: 0; }
    &.-react {
      display: flex;
      flex-direction: row;
      justify-content: space-between; } }

  .react-colorful {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    .react-colorful__pointer {
      width: 14px;
      height: 14px; } }

  .kind-selector {
    display: flex;
    flex-direction: row;
    h4 {
      width: 50%;
      text-align: center;
      color: $form-border-color-dark;
      cursor: pointer;
      &.-active {
        color: $color-text;
        cursor: default; }
      &:first-child {
        padding-right: 10px;
        border-right: 1px solid $form-border-color-dark; }
      &:last-child {
        padding-left: 10px; } } }
  input.manual-color-set {
    margin-bottom: 10px; } }
