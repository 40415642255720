.bar-with-marks {
  font-family: $font-verizon-bold;
  width: 100%;
  height: 80px;

  .bar {
    position: relative;
    background-color: $color-label;
    width: calc(100% - 28px);
    height: 22px;
    border-radius: 4px;
    margin-top: 60px;
    margin-bottom: 26px;
    margin-left: 14px;
    margin-right: 14px; }

  .main-value {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: -54px;
    transform: translate(-50%);
    width: 150px;
    text-align: center;
    &.green {
      color: $va-green; }
    &.red {
      color: $color-red; }
    &.black {
      color: black; } }

  .min-label {
    position: absolute;
    left: 0;
    bottom: -26px;
    font-size: 18px;
    font-weight: bold; }

  .max-label {
    position: absolute;
    right: 0;
    bottom: -26px;
    font-size: 18px;
    font-weight: bold; }

  .main-mark {
    position: absolute;
    width: 1px;
    height: 34px;
    bottom: 0;
    background-color: black; }

  .average-mark {
    position: absolute;
    width: 1px;
    height: 41px;
    bottom: -19px;
    background-image: linear-gradient(0deg, transparent 50%, $color-text-secondary 50%);
    background-size: 6px 6px; }

  .average-value {
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    color: $color-grayer;
    bottom: -40px;
    transform: translate(-50%);
    white-space: nowrap; } }
