.screener-hidden-fields + .form-question {
  margin-top: 30px; }

.destroyed-question {
  display: none; }
.destroyed-question + .form-question {
  margin-top: 30px; }
.form-question {
  box-shadow: 0 2px 10px rgba(80, 81, 84, 0.1);
  border-radius: $form-border-radius;
  padding: 35px 40px;
  @include breakpoint-lo($screen-md) {
    padding: 4.5% 5%; }
  & + & {
    margin-top: 30px; }
  &.-screening {
    border: 1px solid $form-border-color;
    padding-top: 20px; }
  &.-first-actual {
    margin-top: 0; } }

.form-question_grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.-title {
    max-width: 700px;
    align-items: flex-start; }
  &.-custom {
    margin-top: 10px; }
  &.-order-modal {
    margin: 0 15px; } }

.form-question_row-item {
  display: flex;
  flex: 0 0 auto;
  margin-left: 27px;
  margin-top: 8px;
  &.-first {
    margin-top: 14px; } }

.form-question_row-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 1em 1em  30px;
  padding: 0; }

.form-question_row-text {}

.form-question_grid-item {
  flex: 0 0 auto;
  &.-grow {
    flex: 1 1 auto; }
  //todo tl: refactoring
  &.-sm {
    max-width: 234px; }
  &.-select {
    min-width: 145px; }
  //place for 2 circle buttons
  &.-buttons {
    min-width: 88px; }
  & + & {
    margin-left: 10px; }
  &.-transparent {
    opacity: 0; }
  &.-first-answer-item {
    min-width: 18px; }
  &.-disabled {
    div, select {
      border-color: $form-border-color;
      background: none;
      color: #A8A5B2; } }
  &.-has-error {
    input {
      border-color: $color-danger; } }
  &.-readonly {
    input {
      border-color: $form-border-color;
      background: $bg-chosen-form-gray;
      color: #787F93; } }
  select {
    cursor: pointer; }
  &.-with-custom-placeholder {
    overflow: hidden;
    position: relative;
    .custom-placeholder-label {
      width: fit-content;
      position: absolute;
      bottom: 0;
      z-index: 1;
      left: 16px;
      font-size: 8px;
      color: $form-border-color-dark;
      font-weight: bold; } }
  &.-with-left-margin {
    margin-left: 10px; }
  &.-with-bottom-margin {
    margin-bottom: 10px; }
  &.-with-margins {
    margin-left: 28px;
    margin-top: 4px; }
  &.-flex-1-3 {
    flex: 0 0 30%; } }

.form-question_grid-button {
  & + & {
    margin-left: 10px; } }

.form-question_answers, .form-question_questions {
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 700px;
  &.-margin-bottom {
    margin-bottom: 20px; } }

.form-draggable_item {
  margin-bottom: 25px; }

.form-question_answer, .form-question_question {
  & + & {
    margin-top: 15px; }
  &.-section-header {
    font-weight: bold;
    font-size: 15px;
    color: $color-text;
    opacity: 0.6; } }

.form-question_answer-item {
  display: block;
  height: 38px;
  font-size: 15px;
  color: #1F2542;
  padding-top: 6px;
  margin-left: 10px;
  &.-draggable {
    height: unset;
    padding-top: 0; } }

.form-question_answer-move {
  cursor: pointer;
  color: #BBD1D6;
  &.-disabled {
    cursor: default; } }

.form-question_conditions {
  padding: 0;
  margin: 0;
  list-style: none; }

.form-question_condition {
  & + & {
    margin-top: 15px; } }

.form-question_add-condition {
  margin-top: 15px; }

.form-question_type {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 700px; }

.form-question_type-col {
  cursor: pointer;
  padding: 11px;
  max-width: none;
  min-width: 0;
  flex: auto;
  &:hover {
    border-radius: 5px;
    background: $color-link;
    .form-question_type-title {
      color: #fff; }
    .form-question_type-text {
      color: #fff; } } }

.form-question_type-divider {
  flex: 0 0 auto;
  align-self: center;
  margin: 11px 25px;
  color: $color-line;
  font-size: 15px;
  position: relative;
  padding: 5px 0;
  line-height: 1;
  min-width: 0;
  &:after, &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    background: $color-line;
    left: 50%; }
  &:after {
    top: 100%; }
  &:before {
    bottom: 100%; } }

.form-question_type-title {
  font-size: 15px;
  line-height: 1.4;
  color: $color-link;
  font-weight: bold; }

.form-question_type-text {
  .bolder {
    font-weight: bold; }
  color: $color-options-description;
  font-size: 15px;
  line-height: 1.4;
  &.-grid {
    display: flex;
    align-items: center;
    margin-left: 16px;
    width: 226px; } }
