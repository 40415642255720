@import "../../../node_modules/react-phone-input-2/lib/style.css";

.react-tel-input {
  input.form-control {
    @extend .form-field;
    border-radius: 38px;
    z-index: 1;
    width: 100%;
    padding-left: 50px; }
  .flag-dropdown {
    z-index: 2;
    border-radius: 38px 2px 2px 38px;
    .selected-flag {
      border-radius: 38px 2px 2px 38px;
      &.open {
        border-radius: 38px 2px 2px 38px; } }
    &.open {
      border-radius: 38px 2px 2px 38px;
      .selected-flag {
        border-radius: 38px 2px 2px 38px; } }
    .country-list {
      top: -211px; } } }
