.summary-markup_regions {
  display: flex;
  margin: 30px 0;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 34px; //36px
    height: 34px; //36px
    border: 1px solid $color-border;
    border-radius: 4px;
    &:not(:last-child) {
      margin-right: 10px; } }
  input:checked + label {
    background-color: #f5f5f5; }
  input:disabled + label {
    opacity: 0.3;
    cursor: default; } }

.summary-markup_form {
  display: flex;
  width: 100%;
  min-height: 36px;
  & + & {
    margin-top: 10px; }
  .button {
    margin-left: 15px;
    //override for classes in template
    &.-edit {
      text-align: left;
      text-indent: -1000px;
      overflow: hidden;
      width: 38px;
      height: 38px;
      padding: 0;
      background-color: $color-secondary;
      background-repeat: no-repeat;
      background-position: 50% 48%;
      background-size: 18px 19px;
      background-image: url('../assets/images/icons/i-edit.svg'); } } }


.summary-markup_comments {
  //min-height: 375px
  //min-height: 550px
  max-height: 550px;
  overflow: hidden;
  overflow-y: auto;
  outline: none; }

.summary-markup_comment {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  padding: 10px;
  border-bottom: 1px solid $color-border;
  //cursor: pointer
  user-select: none;
  color: currentColor;
  @include break-word;
  //&:before
  //content: ''
  //display: block
  //position: absolute
  //top: 15px
  //bottom: 15px
  //border-radius: 2px
  //width: 3px
  //background-color: currentColor
  //left: 20px
  &[ng-click] {
    cursor: pointer; }
  &.-pointer {
    cursor: pointer; }
  &.-header {
    //padding: 10px 20px
    background: #EFF4F5;
    color: #787F93;
    border: none;
    .summary-markup_comment_action {
      color: inherit; } }
  //&:before
  //display: none
  &.-global {
    background: #C2CDD9;
    color: #fff;
    border-radius: 4px 4px 0 0;
    .summary-markup_comment_action {
      color: inherit; } }
  //min-height: 32px
  //padding-top: 5px
  //padding-bottom: 5px
  //padding-left: 20px
  //border: none
  //justify-content: flex-end
  //&:before
  //display: none
  &.-group {
    //padding-left: 20px
    border: none;
    background: #C2CDD9;
    color: #fff !important;
    .summary-markup_comment_action {
      color: inherit; } }
  //&:before
  //display: none
  &.-positive {
    color: $color-success !important; }
  &.-negative {
    color: $color-red !important; }
  //&.-confusing
  //color: currentColor
  &.-selected {
    background-color: $color-menu-active;
    color: #fff !important; } }
//.summary-markup_comment_name
//color: $color-selected-respondent-id

.summary-markup_comment_icon,
.summary-markup_comment_action {
  //position: absolute
  //right: 20px
  //top: 50%
  //margin-top: -11px
  flex-grow: 0;
  flex-shrink: 0; }

.summary-markup_comment_icon {
  margin-right: 10px;
  display: none; }

.summary-markup_comment_action {
  color: currentColor;
  &.icon-link.-no-text {
    font-size: 0; }
  &.-clear-highlight {
    flex: auto;
    text-decoration: underline;
    font-size: 13px;
    line-height: 1; }
  &:hover {
    color: currentColor;
    opacity: .8; } }

.summary-markup_comment_author,
.summary-markup_comment_title {
  flex-grow: 1;
  font-size: 13px;
  font-weight: bold;

  span.demos {
    padding-right: 12px;
    font-size: 12px;
    color: #999999; } }

.summary-markup_comment_comment {
  flex-grow: 1;
  font-size: 13px;
  font-weight: bold;
  padding-right: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 91%; }

.summary-markup_comment_name {
  color: $color-label; }

.summary-markup_comment_action_link {
  text-decoration: underline; }
