.table-comparison {

  .time-addition-start {
    td {
      padding-bottom: 0; }
    & + tr {
      td {
        color: $color-base-gray;
        font-size: 10px;
        padding-top: 0;
        border-color: transparent;
        font-weight: bold;
        &.time-addition-title {
          text-align: left; }
        &.table-comparison_average-cell {
          border-top: none; } } } }

  & + & {
    margin-top: 20px; }

  > thead > tr > th {
    vertical-align: top;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: $color-base-gray;


    padding: 25px 9px 20px;
    &:first-child {
      padding-left: 10px; }
    &:last-child {
      padding-right: 10px; }

    &.-with-concept {
      padding-top: 0; } }

  > tbody > tr > td {
    color: $color-text;
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
    padding: 13px 9px;
    min-width: 70px;
    position: relative;
    &:first-child {
      padding-left: 10px; }
    &:last-child {
      padding-right: 10px; }
    &.-collapse-bottom {
      padding-bottom: 3px; }
    &.-collapse-top {
      border-top: none;
      padding-top: 3px; } }

  > tbody > tr.-highlighted > td {
    background: $bg-comparison-metric-title;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: $color-base-gray;
    text-align: left;
    border-top: none; }
  > tbody > tr.-highlighted  + tr > td {
    border-top: none; }


  > thead > tr > th,
  > tbody > tr > td {
    &.table-comparison_name-cell {
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      color: $color-base-gray;
      z-index: 10;
      &.-text-center {
        text-align: center; }
      &.-text-right {
        text-align: right; }
      &.-nowrap {
        white-space: nowrap; }
      &.-clickable {
        cursor: pointer;
        &:hover {
          color: #00AEEF; } }
      &.-with-bigger-z-index {
        z-index: 20; }
      &.-grid {
        display: grid; }
      &.-no-padding {
        padding-left: 0; } }
    &.table-comparison-subtitle {
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      color: $color-base-gray;
      &.-custom {
        display: inline-grid; } }

    &.table-comparison_bold-text {
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      color: $color-text;
      &.-no-padding {
        padding-left: 0; } }
    &.table-comparison_percent {
      text-align: right;
      font-size: 12px;
      font-weight: bold;
      &.-green {
        color: $color-success; }
      &.-blue {
        color: $color-primary; }
      &.-black {
        color: #000; } }
    &.table-comparison_info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;
      span {
        margin-left: 4px;
        margin-right: 20px;
        font-size: 12px; } }
    &.table-comparison_cell-header {
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      margin: 0;
      color: $color-text;
      width: 60%; } }

  tr.-clickable {
    cursor: pointer;
    &:hover {
      > td {
        background: rgba(239, 244, 245, 0.3); } } }

  &.-long-first-cell {
    > tbody > tr > td {
      &.table-comparison_name-cell {
        width: 60%; } } }

  .-arrow-pointer-down {
    color: $color-danger;
    margin-left: 4px; }

  .-arrow-pointer-up {
    color: $color-success;
    margin-left: 4px; }

  th {
    &.-hint {
      font-weight: normal; } }

  .-fixed-width {
    width: 50px;
    text-align: right;
    display: inline-block; }

  tr {
    &.-highlight-top {
      td {
        border-top: 1px solid $color-orange; }
      .table-comparison_bold-text {
        color: $color-orange;
        border-bottom: 1px solid $color-orange; } }
    &.-highlight-bottom {
      td {
        border-bottom: 1px solid $color-orange; } }
    &.-top2-box {
      background-color: #F0F6D6; }
    &.-bottom2-box {
      background-color: #FFDEBD; } } }

.table-comparison {
  &.-stick-header {
    th {
      position: sticky;
      top: 0;
      background: #fff;
      box-shadow: 0 1px 0 $color-border;
      z-index: 1;
      &.-with-tooltip {
        z-index: 11; } } }

  &.-stick-average {
    .table-comparison_average-cell {
      position: sticky;
      right: 0;
      background: #fff;
      &.-highlighted {
        background: $bg-comparison-metric-title; } } }

  &.-stick-first-column {
    tr {
      th:first-child {
        z-index: 11; } }
    tr.-highlighted {
      td:first-child {
        background: $bg-comparison-metric-title; } }

    tr td:first-child, tr th:first-child {
      position: sticky;
      left: 0;
      background: #fff; } }
  &.-concepts-database-sticky-head {
    th {
      position: sticky;
      top: 0;
      background-color: $bg-gray; } }
  &.-margins {
    margin-top: 1em;
    margin-bottom: 2em; }
  .-padding-top {
    padding-top: 1em; } }

.-not-relative-position {
  position: inherit !important; }

.table-comparison_average-cell {
  position: relative;
  padding-left: 18px !important;
  &.-concept-list {
    min-width: 100px; }
  &:before {
    content: "";
    position: absolute;
    left: 8px;
    right: 0;
    top: 0;
    bottom: 0;
    background: $color-primary;
    opacity: 0.1; } }

th.table-comparison_average-cell {
  width: 17%;
  &.-word-wrap {
    white-space: pre-line; }
  &:before {
    border-radius: 10px 10px 0 0; } }

.table-comparison_box-cell {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 3em;
  height: 1.5em;
  color: #97999F;
  font-size: 12px;
  font-weight: bold; }

tr:last-child > td.table-comparison_average-cell {
  border-radius: 0 0 10px 0;
  &:before {
    border-radius: 0 0 10px 10px; } }


.table-comparison_cell-title {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  &.-inline {
    display: inline-block; } }

.table-comparison_cell-tooltip {
  margin: -3px 0 0 5px; }

.table-comparison_cell-link {
  display: inline-block;
  color: $color-link;
  text-decoration: underline; }

.table-comparison_concept {
  //min-width: 77px
  //max-width: 120px
  display: inline-block;
  padding: 5px 5px 10px;
  border-radius: 10px;
  &:hover {
    background-color: $bg-concept-hover; }
  &.-with-letter-under {
    margin-bottom: 5px; }
  &.-naming {
    padding-bottom: 5px; }
  &.-no-left-padding {
    padding-left: 0; } }

.table-comparison_concept-preview {
  width: 100%;
  cursor: pointer;
  position: relative;
  &.-centered {
    display: flex;
    justify-content: center; } }

.table-comparison_concept-name {
  margin-top: 10px;
  word-break: break-all;
  font-size: 12px;
  font-weight: normal;
  color: $color-text;
  text-align: center;
  &.-max-comparison-width {
   max-width: 100px;
   word-break: break-word; } }

.table-comparison_concept-letter {
  text-align: center;
  margin-top: 5px;
  font-size: 13px;
  font-weight: normal;
  color: $color-text;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px; }

.table-comparison_concept-tags {
  display: flex;
  flex-direction: row;
  padding: 0 3px;
  margin-bottom: 5px;
  justify-content: center;
  &.-left {
    justify-content: flex-start;
    padding: 0; }
  .comparison-tag-item {
    color: $color-flavor-label;
    background-color: $color-secondary;
    padding: 3px 5px;
    border-radius: 10px;
    font-size: 10px; } }

.table-comparison_title {
  color: $color-text;
  font-size: 15px;
  font-weight: bold; }

.table-comparison_cell-value-move {
  display: inline-block;
  font-weight: bold;
  position: relative;
  padding: 0 15px;
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;
    color: $color-success; } }

.table-comparison_cell-image {
  img {
    width: 100px;
    height: 100px; } }

.table-comparison_bold-block {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  color: $color-text;
  border-top: 1px solid $color-border;
  &:first-child {
    padding-left: 10px; } }

.table-comparison-grid-col-view {
  display: flex;
  flex-direction: column; }
.table-comparison-grid-col-view-item + .table-comparison-grid-col-view-item {
  margin-top: 5px; }
