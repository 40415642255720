.verizon-modifier {
  // common styles for tables (scorcard, comparisons)
  .table {
    table-layout: auto;
    color: $va-black;
    font-size: 14px;
    line-height: 1;
    @include font-normal;
    b, .bold {
      @include font-bold;
      &.-with-margin {
        margin-bottom: 5px; } }
    .note {
      @include note-style;
      font-size: 13px; }
    .red, .low-base-size {
      color: $va-red; }
    .orange {
      color: $va-orange; }
    .green {
      color: $va-green; }
    .note-message {
      color: $va-dark-grey-text; }
    div + div {
      margin-top: 1px; }
    .paragraph + .paragraph {
      margin-top: 20px; }
    &.-fixed {
      table-layout: fixed; }
    &.-margin-top {
      margin-top: 20px; }
    & > tbody > tr {
      & > td, & > th {
        @include font-normal;
        text-align: left;
        height: 50px;
        border-top: 1px solid $va-gray-text;
        background-color: $va-white;
        vertical-align: middle;
        padding: 10px 18px;
        &.-center {
          text-align: center; }
        &.-right {
          text-align: right; }
        &.-top {
          vertical-align: top; }
        &.-bottom {
          vertical-align: bottom; }
        &.-grayed {
          color: $va-gray-dark; }
        &.td-empty {
          padding: 6px;
          border-top: none;
          height: auto; }
        &.th-section {
          background-color: $va-stone;
          @include font-bold;
          &.-black {
            background-color: $va-black;
            color: $va-white; } }
        &.-colored {
          background-color: $va-stone; }
        &.-marked {
          background-color: $va-yellow; }
        &.min-width {
          width: 1%; }
        &.-sticky-left {
          position: sticky;
          left: 0;
          z-index: $va-sticky-zi - 1; }
        &.-no-zindex {
          z-index: unset; }
        &.-with-tabs {
          padding-top: 6px;
          padding-bottom: 6px; }
        &.-dashed {
          border-top-style: dashed;
          border-top-width: 2px; }
        &.-width-25 {
          width: 25%; } }
      &.tr-head {
        & > td, & > th {
          border-top: none;
          padding-top: 17px;
          padding-bottom: 17px;
          box-shadow: inset 0 -1px 0 0 $va-gray-text; }
        & + tr {
          & > td, & > th {
            border-top: none; } } } } }

  .category-title {
    font-weight: bold;
    span {
      display: inline-block;
      line-height: 1.1; }
    span + span {
      margin-top: 5px; } }


  .table_legend {
    background-color: $va-white;
    padding: 20px 18px; }

  .table_notes-right {
    font-size: 13px;
    color: $va-dark-grey-text;
    margin-top: 10px;
    @include font-normal;
    b {
      @include font-bold; } }

  .table_status-label {
    font-size: 11px;
    @include font-bold;
    border-radius: 8px;
    padding: 2px 6px;
    text-align: center;
    display: inline-block;
    text-transform: capitalize;
    color: #fff;
    &.-green {
      background-color: $va-green; }
    &.-orange {
      background-color: $va-orange; }
    &.-red {
      background-color: $va-red; }
    &.-black, &.-na {
      color: $va-black; }
    &.-tag {
      background-color: $va-black;
      text-wrap: nowrap;
      margin-right: 5px; } }

  // projects
  .comparison-title {
    .info-tooltip_control {
      width: 12px;
      height: 12px;
      svg.icon {
        height: 7px; } } }

  .projects_list_table {
    color: $va-black;
    box-shadow: none;
    border-radius: 0;
    overflow: auto;
    height: calc(100vh - 200px);
    .table {
      .body-row {
        background-color: $va-white;
        &:nth-child(odd) {
          background-color: $va-stone; }
        &:hover {
          background-color: $va-white; }
        &:nth-child(odd):hover {
          background-color: $va-stone; }
        &.-selected {
          background-color: $va-yellow;
          &:nth-child(odd) {
            background-color: $va-yellow; } }
        & > td, & > th {
          background-color: inherit;
          &.-sticky-left {
            position: sticky;
            left: 0;
            z-index: $va-sticky-zi - 1; } } }
      .-copying {
        .-verizon_cell {
          opacity: 0;
          visibility: hidden;
          &:first-child {
            opacity: 1;
            visibility: visible;
            position: static;
            div {
              opacity: 0;
              visibility: hidden; }
            &:before {
              content: 'Copying...';
              position: absolute;
              z-index: 2;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%); } } } }
      & > tbody {
        & > tr {
          & > th, & > td {
            padding-left: 8px;
            padding-right: 8px; }
          & > th {
            font-size: 11px;
            color: black;
            @include font-bold;
            &:first-child {
              width: 9%; }
            &.-sample {
              width: 10%; }
            &.-subgroups {
              width: 22%; }
            &.-sample-cat {
              width: 20%; }
            &.-subgroups-cat {
              width: 15%; }
            &.-downloads {
              width: 25%; } }
          & > td {
            font-size: 12px;
            &.-pointer {
              cursor: pointer; }
            &.-sample {
              word-break: break-word; } } } }

      .icon.-i-compare {
        color: $va-dark-grey-text;
        width: 16px;
        height: 16px;
        &.-selected {
          color: $va-green; } } }

    .tr-head {
      &.-sticky {
        position: sticky;
        top: 0;
        background-color: $va-white;
        z-index: $va-sticky-zi; } }


    .project-ease_rating {
      font-size: 12px;
      font-weight: bold;
      &.-green {
        color: $va-green; }
      &.-orange {
        color: $va-orange; }
      &.-red {
        color: $va-red; } }

    // dropdown
    .project-actions {
      .dropdown_inset {
        ul {
          background-color: $va-white; } } } }

  // comparisons
  .comparisons-table {
    table-layout: auto;
    width: 100%;
    .col-name {
      width: 25vw;
      min-width: 300px; }
    .col-data {
      width: 12vw;
      min-width: 200px; }
    & > tbody > tr {
      & > td, & > th {
        padding-left: 15px;
        padding-right: 15px; } } }

  .comparisons-table_wrapper {
    overflow: auto;
    max-height: calc(100vh - 370px);
    &.-side-by-side {
      height: 100vh;
      max-height: calc(100vh - 282px);
      margin-top: 10px; }
    &.-cat-comparison {
      max-height: calc(100vh - 376px); }
    .tr-head {
      &.-sticky {
        position: sticky;
        top: 0;
        background-color: $va-white;
        z-index: $va-sticky-zi;
        &.-sticky-alphabet-margin {
          top: 156px; } } }
    & + .comparisons_legend {
      margin-top: 15px;
      margin-bottom: 15px; } } }





