.client {
  & + & {
    margin-top: 50px; } }

.client-label-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px; }

.flex-left {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start; }

.flex-right {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start; }

.client-title {
  font-size: 15px;
  font-weight: bold;
  margin-right: 9px; }

.subclients-number {
  padding: 1px 7px 2px;
  margin-right: 4px;
  font-weight: bold; }

.subclients-add {
  button {
    user-select: none;
    padding: 0;
    width: 22px;
    height: 22px;
    .icon {
      width: 8px;
      height: 8px; } }
  .dropdown_container {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    .dropdown_toggle {
      height: 20px;
      width: 20px;
      padding: 3px; }
    .dropdown_inset {
      width: 180px;
      max-height: 250px;
      ul {
        background-color: #fff;
        margin: 0;
        width: 100%;
        padding: 14px 0;
        li {
          list-style: none;
          margin: 0 0 7px 0;
          display: flex;
          flex-direction: row;
          padding: 0;
          &.-title {
            color: #4C5168;
            font-size: 12px; }
          span {
            padding: 0 25px; }
          a {
            padding: 0 25px;
            display: block;
            width: 100%;
            color: $color-text;
            text-decoration: none;
            &:hover {
              color: $color-text;
              text-decoration: none;
              background-color: rgba(91, 187, 228, 0.1); } }

          &:last-child {
            margin-bottom: 0; } } } } } }

.-clients-button {
  font-size: 11px;
  border-radius: 4px;
  background: transparent;
  color: $color-primary;
  border: 1px solid #CFEAF3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  &:hover {
    color: #fff;
    border-color: $color-primary;
    background-color: $color-primary; }
  &.-no-hover {
    &:hover {}
    color: $color-primary;
    border-color: #CFEAF3;
    background-color: transparent; } }

.client-credits {
  font-size: 15px;
  font-weight: bold;
  margin-right: 19px; }

.clients-credits-modify {
  input {
    width: 120px;
    margin-right: 5px;
    height: 34px;
    min-height: 34px; }
  button {
    padding: 5px 16px; }
  button.-add {
    margin-right: 0;
    margin-left: 0;
    font-size: 14px;
    height: 33px;
    min-height: 33px;
    margin-bottom: 1px; }
  button.-remove {
    background-color: $color-danger;
    margin-left: 5px;
    font-size: 14px;
    height: 33px;
    min-height: 33px;
    margin-bottom: 1px;
    &:hover {
      background-color: rgba(237, 115, 46, 0.9); } } }

.subclient-item {
  & + & {
    border-top: 1px solid $color-border; } }

.subclients-row {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.15);
  border-radius: 8px;
  ul {
    margin: 0;
    padding: 0;
    li {
      padding: 17px 20px;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      button {
        padding: 0;
        height: auto;
        background: transparent;
        color: $color-danger;
        &:hover {
          color: rgba(237, 115, 46, 0.9);
          background-color: #fff; } } } } }


.project-country-codes {
  .sharing-buttons + .country-code, .project-demo-share-buttons.-cards +.country-code {
    margin-left: 4px; } }
.sharing-buttons {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-left: 4px;
  &.-right-side {
    justify-content: flex-end; }
  &.-center-side {
    justify-content: center;
    margin-left: 0; }

  .sharing-item + .sharing-item {
    margin-left: 4px; }

  .dropdown_container {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    left: -67px;

    .dropdown_toggle {
      height: 20px;
      width: 20px;
      padding: 3px; }
    .dropdown_inset {
      width: 180px;
      max-height: 250px;
      ul {
        background-color: #fff;
        margin: 0;
        width: 100%;
        padding: 14px 0;
        li {
          list-style: none;
          margin: 0 0 7px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          padding: 0;
          &.-title {
            color: #4C5168;
            font-size: 12px; }
          &.-with-padding {
            padding: 0 25px; }
          span {
            width: 100%;
            padding: 0 25px; }
          a {
            padding: 0 25px;
            display: block;
            width: 100%;
            color: $color-text;
            text-decoration: none;
            &:hover {
              color: $color-text;
              text-decoration: none;
              background-color: rgba(91, 187, 228, 0.1); } }
          button {
            padding: 0;
            height: auto;
            background: transparent;
            color: $color-danger;
            &:hover {
              color: rgba(237, 115, 46, 0.9);
              background-color: #fff; } }
          &:last-child {
            margin-bottom: 0; } } } } } }

button.revoke {
  span {
    margin-left: 5px; } }

button.share, button.revoke {
  padding: 3px;
  height: 23px;
  min-height: 23px; }
