.quota-error-message + .quota-mode {
  margin-top: 0; }
.quota-mode {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  &.-with-401-width {
    width: 401px;
    justify-content: space-between;
    .quota-mode-title + .quota-mode-selection {
      margin-left: 0; } } }
.quota-mode-title {
  display: flex;
  flex-direction: row;
  align-items: center; }
.quota-mode-title-item + .quota-mode-title-item {
  margin-left: 3px; }
.quota-mode-selection {
  .radio-tabs {
    height: auto; }
  .radio-tabs_label {
    padding: 7px 10px; } }
.quota-mode-title + .quota-mode-selection {
  margin-left: 15px; }
