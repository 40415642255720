html, body {
  min-width: auto !important; }
.container-404 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px; }
.centered-404 {
  padding: 20px;
  max-width: 500px;
  h1 {
    margin-bottom: 25px; }
  p + p {
    margin-top: 10px; }
  p {
    font-size: 15px; } }


