.vcc-scorecard {
  hr {
    margin: 25px 0; }
  .comparisons_legend {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    &.black-text {
      .comparisons_legend-text {
        color: $va-black; } }
    .comparisons_legend-label {
      &.red {
        background: $va-red; }
      &.green {
        background: $va-green; }
      &.gray1 {
        background: $color-label; }
      &.gray2 {
        background: $color-bar-gray; } }
    .comparisons_legend-text {
      color: $va-dark-grey-text;
      font-size: 12px;
      &.lighter {
        color: $color-grayer; } } }

  .comparisons_legend-letters-vs-each {
    display: flex;
    flex-direction: column;
    color: $va-dark-grey-text;
    width: 100%; }

  .comparisons_legend-grid {
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -5px -9px; }

  .comparisons_legend-grid-item {
    margin: 0;
    padding: 5px 9px; }

  .comparisons_legend-label {
    min-width: 45px;
    & + .comparisons_legend-text {
      margin-left: 7px; } }

  svg {
    .red {
      fill: $va-red; }
    .orange {
      fill: $va-orange; }
    .green {
      fill: $va-green; } }

  .icon {
    &.high, &.low {
      border-radius: 99%; }
    &.high {
      color: $va-red;
      background-color: $va-red;
      &.-switch {
        color: $va-green;
        background-color: $va-green; } }
    &.low {
      color: $va-green;
      background-color: $va-green;
      &.-switch {
        color: $va-red;
        background-color: $va-red; } } }
  .comparisons_plate {
    .donut-chart {
      svg {
        width: 100%;
        height: auto;
        display: block; } } }

  .comparisons_plate-image {
    width: 100%;
    height: auto; }

  .sentiment {
    border-color: transparent;
    background-color: $va-stone;
    .icon.-circle {
      align-self: flex-start; }
    .playback-button {
      color: #000; }
    .eye-button {
      color: #000; }
    &.-hidden {
      .sentiment_content, .sentiment-color {
        opacity: 0.1; } } }

  .sentiment-color {
    align-self: flex-start;
    .icon {
      width: 12px;
      height: 12px; } }

  .sentiment_content {
    padding: 0 10px; }

  .sentiments_header {
    margin: 0 0 5px; }

  .sentiments-list {
    max-height: 450px;
    overflow: auto; }

  .sentiments-list_item {
    margin: 10px 0; }

  .sentiments_content-scrollable {
    max-height: 438px; }

  .admin_control {
    text-align: left; }


  .answer-block_txt {
    color: $va-black;
    @include font-bold;
    line-height: 1.3; }

  .answer-block_author {
    color: $va-dark-grey-text;
    @include font-normal; }

  .tile-header_main {
    padding-right: 0; }


  .summary-panel {
    .middle {
      margin-left: 0; }
    .pre {
      font-size: 14px; }
    .buttons {
      margin-top: 15px;
      a.link {
        color: #000;
        @include font-bold;
        font-size: 13px; }
      .checkbox_label {
        color: #000;
        @include font-bold;
        font-size: 13px;
        line-height: 1.5;
        padding-left: 25px; }
      .text-grayed {
        color: $va-dark-grey-text; } }
    .icon.-refresh-nocolor {
      color: $va-brand; }
    .icon.-pen-nocolor {
      color: $va-brand; }
    .run-button {
      font-size: 15px;
      min-width: 140px;
      min-height: 44px; } }

  .comparisons_details-item {
    & + .comparisons_details-item {
      margin-top: 12px; } }

  .comparisons_details-name {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 2px;
    @include font-bold; }

  .comparisons_details-value {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 2px;
    @include font-normal;
    .sample-texts {
      p {
        margin: 0; }
      p + p {
        margin-top: 10px; } } }

  .comparisons_plate-row {
    .title.-bigger {
      font-size: 18px; }
    .note.-grayer {
      font-size: 14px;
      color: $color-grayer; } }

  .comparisons_plate-text {
    .grayer {
      font-size: 12px;
      color: $color-grayer; }
    .bold {
      font-size: 14px;
      font-family: $font-verizon-bold;
      font-weight: bold; } }

  .plate-note {
    margin-bottom: 20px;
    font-size: 12px;
    color: $color-grayer;
    b {
      font-family: $font-verizon-bold; } }

  .comparisons_plate-row + .comparisons_plate-title {
    margin-top: 22px; } }

.comparisons_kpi-list {
  margin-top: 25px; }

.ccs-included {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-color: transparent;
    width: calc(100% + 30px);
    height: calc(100% + 14px);
    left: -18px;
    top: -14px;
    border: 1px dashed $color-text; } }


.comparisons_plate-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  & + & {
    margin-top: 10px; }
  & + &.-space-md {
    margin-top: 25px; }

  .comparisons_grid {
    flex: 1 1 100%; }

  .texts {
    flex: 1 1 100%;
    color: $color-text;
    line-height: 1;
    b, strong {
      @include font-bold; }
    .red {
      color: $va-red; }
    .orange {
      color: $va-orange; }
    .green {
      color: $va-green; }
    .gray {
      color: $va-gray-text;
      font-size: 13px; } }
  .title {
    font-size: 14px;
    line-height: 1;
    @include font-bold;
    margin-bottom: 2px;
    &.-big {
      font-size: 18px;
      margin-bottom: 5px; }
    &.-inline {
      display: inline; } }
  .note {
    @include note-black-style;
    line-height: 1.3;
    &.-sm {
      font-size: 13px; }
    &.-xs {
      font-size: 12px; }
    &.-gray {
      color: $va-dark-grey-text; } }

  .thin-title {
    font-size: 18px;
    &.-inline {
      display: inline; } }

  .value {
    font-size: 14px;
    @include font-bold;
    line-height: 1;
    margin-left: 10px;
    flex: 0 0 auto;
    min-width: 40px;
    text-align: right;
    &.-big {
      font-size: 18px;
      margin-left: 15px;
      min-width: 50px; }
    &.-lg {
      font-size: 22px;
      margin-left: 15px;
      min-width: 60px; }
    & + .note {
      margin-top: 2px; }
    &.-big, &.-lg {
      & + .note {
        margin-top: 4px; } }
    &:first-child {
      margin-left: 0;
      text-align: left;
      min-width: 0; }
    &.-tiny {
      min-width: auto; }
    &.-red {
      color: $va-red; }
    &.-orange {
      color: $va-orange; }
    &.-green {
      color: $va-green; }
    &.-black {
      color: #000000; }
    &.-normal-font {
      @include font-normal; } } }

.comparisons_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.-bordered {
    border-bottom: 1px solid $va-brand;
    margin-bottom: 12px; }
  &.-block {
    display: block; }
  .scores {
    max-height: 550px; } }

.comparisons_section-title {
  margin: 40px 0 20px;
  font-size: 26px;
  @include font-bold;
  line-height: 1.3;
  &.-no-margin {
    margin-bottom: 0; } }

.comparisons_status {
  font-size: 28px;
  line-height: 1.2;
  @include font-bold;
  text-transform: uppercase;
  &.-center {
    text-align: center; }
  &.-red {
    color: $va-red; }
  &.-orange {
    color: $va-orange; }
  &.-green {
    color: $va-green; }
  &.-black {
    color: $va-black; }
  &.-huge {
    font-size: 48px; } }

.comparisons_grid-item {
  .note-messages {
    &.-center {
      text-align: center; } } }

span.arrow {
  margin-left: 5px;
  display: inline-block;
  width: 12px;
  &.-red {
    color: $va-red; }
  &.-green {
    color: $va-green; } }

// top menu

.comparisons_menu {
  background-color: $va-white;
  position: sticky;
  top: $page-header-height;
  // 1px — to hide outer border of focused search input if user start to scroll it
  padding: 25px 1px;
  margin: 0 -1px;
  z-index: $z-index-header; }

.comparisons_menu-list {
  padding: 0;
  margin: -8px;
  list-style: none; }

.comparisons_menu-item {
  display: inline-block;
  margin: 8px; }

.comparisons_menu-link {
  color: $va-gray-text;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px dashed currentColor;
  display: block;
  line-height: 1.2;
  &.-icon {
    border-bottom: none; }
  .verizon-modifier & {
    color: $va-dark-grey-text;
    &:hover, &:active, &:focus {
      color: #000; } }
  .icon {
    vertical-align: top;
    background: #fff;
    border-radius: 6px; } }

.anchor-point {
  position: relative;
  top: -170px;
  // it is necessary to set height more than 0 to correct position
  height: 1px;
  opacity: 0;
  width: 0;
  @media (min-width: 1630px) {
    top: -135px; }
  .vcc-scorecard.-no-deviation & {
    @media (min-width: 1164px) {
      top: -135px; } } }

.vcc_checkbox_label {
  padding-left: 30px;
  margin: 0;
  font-weight: 400;
  display: inline-block;
  position: relative;
  user-select: none;
  min-height: 22px;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.1em;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-top: 1px;
    background: #fff;
    box-shadow: inset 0 0 0 2px #000; }
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 4px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    left: 5px;
    top: 0.25em;
    margin-top: 5px;
    opacity: 0; }
  input:checked + &, &.-checked {
    &:before {
      background: #000; }
    &:after {
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 1; } }
  input:disabled + & {
    opacity: 0.6;
    cursor: default; } }

.vcc_checkbox {
  .checkbox {}
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 5px;
  &.-ai {
    margin-top: -5px; }
  input {
    position: fixed;
    opacity: 0; } }
