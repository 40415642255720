.icon {
  display: inline-block;
  fill: currentColor;

  &.-link {
    cursor: pointer;
    position: relative; }

  &.-logo {
    width: 100px;
    height: 55px; }

  &.-verizon-logo {
    color: #EE0000; }

  &.-menu-projects {
    width: 12px;
    height: 11px; }

  &.-menu-project {
    width: 10px;
    height: 13px; }

  &.-menu-project-claims {
    width: 13px;
    height: 13px; }

  &.-menu-project-concepts_test {
    width: 13px;
    height: 13px; }

  &.-menu-project-naming {
    width: 15px;
    height: 15px; }

  &.-menu-project-flavor {
    width: 15px;
    height: 15px; }

  &.-menu-project-package {
    width: 13px;
    height: 13px; }

  &.-menu-project-adcept {
    width: 13px;
    height: 13px; }

  &.-menu-project-brand_snapshot {
    width: 13px;
    height: 13px; }

  &.-menu-profile {
    width: 10px;
    height: 12px; }

  &.-menu-clients {
    width: 12px;
    height: 12px;
    &.-amount {
      width: 16px;
      height: 16px;
      top: 1px; } }

  &.-menu-downloads {
    width: 13px;
    height: 12px; }

  &.-menu-tags {
    width: 13px;
    height: 12px;

    &.-menu-concepts-database {
      width: 13px;
      height: 10px; } }

  &.-i-squared-minus {
    width: 18px;
    height: 18px; }

  &.-i-squared-plus {
    width: 18px;
    height: 18px; }

  &.-i-tag {
    width: 13px;
    height: 11px; }

  &.-i-edit {
    width: 15px;
    height: 15px; }

  &.-menu-logout {
    width: 13px;
    height: 12px; }

  &.-navigation-done {
    width: 12px;
    height: 12px; }

  &.-i-error {
    width: 18px;
    height: 18px;
    color: $color-danger; }

  &.-i-process-download {
    width: 15px;
    height: 17px; }

  &.-i-process-pending {
    width: 18px;
    height: 18px; }

  &.-i-move {
    width: 18px;
    height: 10px; }

  &.-i-delete {
    width: 11px;
    height: 12px; }

  &.-i-pinned {
    width: 16px;
    height: 16px; }

  &.-i-unpinned {
    width: 16px;
    height: 16px; }

  &.-i-add {
    width: 10px;
    height: 10px; }

  &.-i-upload {
    width: 19px;
    height: 15px; }

  &.-i-search {
    width: 18px;
    height: 18px; }

  &.-project-user {
    color: #00B7BB;
    width: 10px;
    height: 10px; }

  &.-i-switch {
    color: currentColor;
    width: 17px;
    height: 17px; }

  &.-warning-circle {
    width: 14px;
    height: 14px; }

  &.-i-close {
    width: 19px;
    height: 19px; }

  &.-i-close-sm {
    width: 14px;
    height: 14px; }

  &.-close {
    width: 9px;
    height: 9px; }

  &.-change {
    width: 13px;
    height: 13px; }

  &.-i-gear {
    width: 16px;
    height: 17px; }

  &.-i-cross {
    width: 10px;
    height: 10px; }

  &.-i-pin {
    width: 10px;
    height: 13px; }

  &.-i-pin-fill {
    width: 10px;
    height: 13px; }

  &.-i-select-reaction {
    width: 16px;
    height: 19px; }

  &.-i-region {
    width: 16px;
    height: 17px; }

  &.-i-remove-round {
    width: 18px;
    height: 18px; }

  &.-eye-open {
    width: 24px;
    height: 14px; }

  &.-eye-closed {
    width: 24px;
    height: 17px; }

  &.-i-eye {
    width: 18px;
    height: 12px; }

  &.-i-eye-crossed {
    width: 17px;
    height: 14px; }

  &.-i-eye-v2-open {
    width: 20px;
    height: 20px; }

  &.-i-eye-v2-closed {
    width: 20px;
    height: 20px; }

  &.-i-merged,
  &.-i-unmerge {
    width: 13px;
    height: 13px; }

  &.-i-pencil {
    width: 12px;
    height: 12px; }

  &.-i-info {
    width: 4px;
    height: 11px; }

  &.-i-check {
    margin-right: 8px;
    width: 10px;
    height: 14px; }

  &.-i-sliders {
    width: 16px;
    height: 16px; }

  &.-reaction {
    width: 16px;
    height: 17px; }

  &.-reaction-t {
    width: 14px;
    height: 16px; }

  &.-region1 {
    width: 25px;
    height: 25px; }

  &.-region2 {
    width: 26px;
    height: 26px; }

  &.-region3 {
    width: 24px;
    height: 26px; }

  &.-shield {
    display: block;
    width: 74px;
    height: 90px;
    margin: 37px auto 28px; }

  &.-arrow-pointer-left {
    width: 16px;
    height: 12px;
    &.-big {
      width: 23px;
      height: 17px; } }

  &.-arrow-pointer-up {
    width: 9px;
    height: 12px; }

  &.-arrow-pointer-down {
    width: 9px;
    height: 12px; }

  &.-arrow-next {
    width: 22px;
    height: 16px;
    &.-mirrored {
      transform: rotate(180deg); } }

  &.-arrow-next-button {
    width: 25px;
    height: 18px; }

  &.-i-trash {
    width: 11px;
    height: 13px; }

  &.-i-trash-big {
    width: 14px;
    height: 18px; }

  &.-i-filter-warning {
    width: 25px;
    height: 26px; }

  &.-paper {
    width: 13px;
    height: 18px; }

  &.-goal {
    width: 26px;
    height: 26px; }

  &.-rocket {
    width: 21px;
    height: 21px; }

  &.-question {
    width: 15px;
    height: 23px; }

  &.-upload {
    width: 20px;
    height: 17px; }

  &.-express-swipe {
    width: 77px;
    height: 66px; }

  &.-express-claims {
    width: 65px;
    height: 55px; }

  &.-express-concepts_test {
    width: 64px;
    height: 55px; }

  &.-express-naming {
    width: 57px;
    height: 58px; }

  &.-express-flavor {
    width: 77px;
    height: 68px; }

  &.-express-package {
    width: 77px;
    height: 68px; }

  &.-express-adcept {
    width: 78px;
    height: 66px; }

  &.-express-swipe-md {
    width: 135px;
    height: 110px; }

  &.-express-claims-md {
    width: 110px;
    height: 92px; }

  &.-express-brand_snapshot {
    width: 79px;
    height: 79px; }

  &.-arrow-top {
    width: 8px;
    height: 11px; }
  &.-burger {
    width: 10px;
    height: 10px; }
  &.-squares {
    width: 10px;
    height: 10px; }
  &.-vertical-vector {
    width: 10px;
    height: 10px; }
  &.-eye {
    width: 13px;
    height: 13px; }
  &.-share {
    width: 16px;
    height: 16px; }
  &.-no-image, &.-i-no-image {
    width: 36px;
    height: 36px; }
  &.-i-no-image-current-color {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: $bg-button-light-grey; }
  &.-arrow-up-left {
    width: 16px;
    height: 16px; }
  &.-arrow-simple-down {
    width: 11px;
    height: 7px; }
  &.-i-star {
    width: 16px;
    height: 14px; }
  &.-i-star-gray {
    width: 20px;
    height: 19px;
    cursor: pointer; }
  &.-i-star-blue {
    width: 20px;
    height: 19px;
    cursor: pointer; }
  &.-i-dot {
    width: 8px;
    height: 8px; }
  &.-i-warning {
    width: 15px;
    height: 13px; }
  &.-i-no-data {
    width: 120px;
    height: 120px; }
  &.-plus-circle {
    width: 36px;
    height: 36px; }
  &.-plus {
    width: 11px;
    height: 11px; }
  &.-delete-circle {
    width: 28px;
    height: 28px; }
  &.-circle {
    width: 20px;
    height: 20px; }
  &.-i-cloud-download {
    width: 18px;
    height: 18px; }
  &.-v-middle {
    vertical-align: middle; }
  &.-pointer {
    cursor: pointer;
    user-select: none; }
  &.-scheme {
    width: 62px;
    height: 59px; }
  &.-pen, &.-pen-nocolor {
    width: 16px;
    height: 16px; }
  &.-refresh, &.-refresh-nocolor {
    width: 17px;
    height: 16px; }
  &.-to-top {
    width: 24px;
    height: 24px; }
  &.-selected {
    width: 17px;
    height: 17px; }
  &.-unselected {
    width: 17px;
    height: 17px; } }
