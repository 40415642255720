// Bootstrap-based modal layout

.modal {
  &[uib-modal-window] {
    z-index: 1063 !important; }
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  //for show sidebar
  left: $sidebar-width;
  z-index: $z-index-modal;
  display: none;
  outline: none;
  overflow-y: auto;

  &.-no-overflow {
    overflow: hidden; }

  &.-with-inner-backdrop {
    background: rgba(214,220,227,0.95); }

  &.-create-region-modal {
    left: 0;
    background: rgba(214,220,227,0.55);

    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      .modal_content {
        min-width: 500px; } } }

  &.-with-inner-scrollbar {
    .modal-dialog {
      height: 100%; }
    .modal-content {
      height: 100%;
      padding-right: 0; }
    .modal-content_inner {
      height: 100%;
      overflow-y: auto;
      padding-right: 40px;
      @include mobile {
        padding-right: 20px; } } }

  &.-left-0 {
    left: 0; }
  &.-clean-shelf {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  &.-top-20p {
    margin-top: 20%; }
  &.-full-screen {
    left: 0;
    width: 100%;
    .modal-dialog {
      max-width: none;
      padding: 0;
      .modal-content {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0; } } }

  &.-overflow-hidden {
    overflow: hidden; }

  &.-contains-image-only {
    text-align: center; }

  &.-scorecard {
    .modal-dialog {
      height: 100%;
      min-width: 950px; }
    .modal-content {
      overflow: visible; }
    img {
      max-width: 100%;
      max-height: 450px; }
    @include breakpoint-lo-height(900px) {
      .modal-dialog {
        padding: 2vh; } } } }

.modal_dialog,
.modal-dialog {
  z-index: $z-index-modal-dialog;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  //overflow: hidden
  &.-customize {
    width: 675px;
    @include intermediate {
      width: 100%; } }

  @include mobile {
    width: 100%;
    padding: 10px; }

  .modal.fade & {
    opacity: 0;
    transform: translate(0, -25%);
    transition: all .3s ease-out, opacity .3s; }

  .modal.in & {
    opacity: 1;
    transform: translate(0, 0); }

  .modal.centered & {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }

  .modal.-contains-image-only & {
    display: inline-block;
    width: auto; }

  &.-modal-lg,
  &.modal-lg {
    width: 100%;
    max-width: $screen-lg; }

  &.-modal-md,
  &.modal-md {
    width: 100%;
    max-width: 900px; }

  &.-modal-md2,
  &.modal-md2 {
    width: 100%;
    max-width: 820px; }

  &.-modal-md3,
  &.modal-md3 {
    width: 100%;
    max-width: 950px; }

  &.-modal-sm,
  &.modal-sm {
    width: 100%;
    max-width: 750px; }

  &.-modal-xs,
  &.modal-xs {
    width: 100%;
    max-width: 500px; }

  &.-modal-xs2,
  &.modal-xs2 {
    width: 100%;
    max-width: 440px; }

  &.-full-width {
    width: 100%; }
  &.-no-overflow {
    overflow: hidden; } }

.modal_content,
.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(83, 122, 167, 0.15);
  outline: 0;
  //box-shadow: none
  padding: 25px 40px;
  overflow: hidden;
  &.-overflow-visible {
    overflow: visible; }
  .modal.-contains-image-only & {
    overflow: hidden; }
  @include mobile {
    padding: 20px; }
  &.-fraud {
    padding: 0;
    width: 440px;
    height: 404px;
    border-radius: 50px; }
  &.-fit-screen {
    height: calc(100vh - 70px);
    .normal-popup {
      display: flex;
      flex-direction: column;
      height: 100%; }
    .modal_body {
      height: 100%;
      overflow: auto; } } }

.modal_header,
.modal-header {
  border: none;
  padding: 0 40px 0 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  min-height: 61px;
  &.-auto-height {
    height: auto;
    min-height: inherit; }
  &.-no-padding {
    padding: 0; } }

.modal-description {
  padding: 0 40px 0 0;
  margin-bottom: 20px;
  &.-flex {
    display: flex;
    margin-bottom: 0; } }

.modal-input {
  margin-bottom: 24px; }

.modal-answer {
  font-size: 15px;
  height: 38px; }

.modal_close, button.close {
  position: absolute;
  right: 0;
  top: 10px;
  color: #BBD1D6;
  cursor: pointer;
  outline: none;
  &.-separate {
    top: 35px;
    right: 35px; }
  &:before {
    content: none !important; }
  @include mobile {
    top: 0; }
  // for bootstrap modal close
  // ex: respondent follow up with big radio with image in mobile
  &:not(.icon-link) {
    width: 14px;
    height: 14px;
    background-image: url('../assets/images/icons/i-close.svg');
    background-color: transparent;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    border: none;
    padding: 0;
    right: 8px;
    top: 8px; } }

.modal_title {
  font-size: 26px;
  line-height: 1.4;
  margin: 0 0 10px;
  flex-grow: 1;
  &.-center {
    text-align: center; } }

.modal-title-md {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  width: 100%;
  max-width: 255px;
  margin: 0 auto 37px;
  text-align: center;
  &.-left {
    text-align: left;
    margin: 25px 15px 24px; } }

.modal-title-sm {
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 10px;
  flex-grow: 1;
  text-align: center;
  &.-left {
    text-align: left;
    margin-right: 5px; }
  &.-non-grow {
    flex-grow: unset; } }

.modal_title-icon {
  vertical-align: middle;

  &.-i-merged {
    color: #cecfcf; } }

.modal_subtitle {
  display: block;
  min-height: 19px;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: $color-label; }

.modal_header-addon {
  margin: -7px 0 0;

  > .icon-link {
    margin: 5px 15px 0; } }

.modal_title-edit {
  margin: -5px 0 4px;
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap; }

.modal_title-edit-inplace {
  flex-grow: 1; }

.modal_title-edit-control {
  padding: 0 0 0 8px; }

.modal_body,
.modal-body {
  .modal.-contains-image-only & {
    padding: 0;
    z-index: 1; }

  &.-scrollable {
    overflow-y: auto;
    margin-right: -40px;
    padding-right: 40px;
    max-height: calc(100vh - 280px);
    .modal-plate + & {
      // 90 - height of plate
      max-height: calc(100vh - 280px - 90px); } }

  &.-relative {
    position: relative; } }

.modal_footer,
.modal-footer {
  padding: 25px 0 0;
  margin: 0;
  border: none;
  border-top: 1px solid $color-border;
  @include clearfix;
  &.-margin-top {
    margin-top: 25px; }
  &.-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      content: none; } }
  &.-new-region-standalone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    &:after {
      content: none; } }
  &.-bg-gray {
    background: $bg-gray;
    border-top: none;
    margin: 0 -40px -25px;
    padding: 40px; }
  &.-center {
    text-align: center; }
  &.-ml {
    margin-top: 16px;
    text-align: right; }
  &.-no-border-top {
    border-top: none; } }

.modal_footer-text {
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 15px;
  &.-bold {
    font-weight: bold; } }


.modal_button {
  min-width: 140px;
  @include mobile {
    min-width: 120px; }
  &.-right {
    float: right; } }


.modal_loader {
  text-align: center;
  font-weight: normal;
  margin: 10px 0 20px;
  color: $color-placeholder; }

.modal-backdrop {
  &[uib-modal-backdrop] {
    z-index: 1062 !important; }
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  //for show sidebar
  left: $sidebar-width;
  z-index: $z-index-modal-back;
  background: #D6DCE3;

  &.fade {
    opacity: 0;
    transition: opacity .3s; }

  &.in {
    opacity: .95; }

  &.-full-screen {
    left: 0;
    opacity: .5; }

  &.-transparent {
    opacity: .5; } }

.modal-plate {
  background: #EFF4F5;
  padding: 20px;
  border-radius: 20px;
  &:not(:last-child) {
    margin-bottom: 15px; } }

.modal-plate_text {
  font-size: 14px;
  min-width: 60px; }

.modal-grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & + & {
    margin-top: 10px; } }

.modal-grid_item {
  flex: 0 0 auto;
  &.-grow {
    flex: 1 1 auto; }
  & + & {
    margin-left: 10px; } }
