.concept-report_tab.wordcloud {
  padding-top: 0; }

.concept-focus-main-title {
  margin: 30px 0 30px;
  font-weight: bold;
  font-size: 18px; }

.concept-focus-heatmap-title {
  margin: 0 0 30px 0;
  font-weight: bold;
  font-size: 18px; }

.concept-focus-markups-title {
  margin: 30px 0 0 0;
  font-weight: bold;
  font-size: 18px; }

.page-container.-wordcloud {
  .header-with-controls_title {
    .resp_number {
      font-weight: normal;
      display: inline-block;
      margin-left: -5px; } }
  .resp_number {
    display: none; } }
