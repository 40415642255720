.modal-shelf-editor {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;

  .left-panel {
    width: 200px;
    height: 100%;
    background: white;
    border-right: 1px solid $color-border; }
  .right-panel {
    background: $bg-gray;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .right-top {
      display: flex;
      height: 65px;
      padding: 15px;
      .-clean {
        margin-left: auto; } }

    .right-main {
      position: relative;
      border-top: 1px solid $color-border;
      height: calc(100vh - 65px);
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .hint {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        padding: 50px 200px 50px 200px;
        border: 1px dashed $color-label;
        border-radius: 50px;
        white-space: nowrap;
        cursor: pointer;
        background: $bg-gray; }

      img {
        user-select: none;
        max-height: 100%;
        width: auto;
        max-width: 100%; }

      .products-greed-item-uploaded {
        height: 100%; } } } }

.shelf-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #E7EEF1;
  border: 1px solid $form-border-color-dark;
  border-radius: 10px;
  padding: 12px;
  margin-top: 10px;
  position: relative; }

.shelf-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.shelf-image-wrapper {
  padding: 20px 40px 20px 40px;
  position: relative;
  width: 100%;
  height: 100%;

  .image-container {
    position: relative;
    .image {
      max-width: 100%;
      height: auto; }

    .place {
      position: absolute;
      border: 2px solid white;
      background: rgba(108, 194, 134, 0.5);
      border-radius: 4px;
      .-active {
        background: rgba(0, 174, 239, 0.5); }
      .name {
        padding-left: 4px;
        padding-right: 4px;
        position: absolute;
        top: -24px;
        max-width: 98px;
        background: #00AEEF;
        color: #F2F6FA;
        border-radius: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; } }

    .top-left, .top-right, .bottom-right {
      z-index: 2001;
      position: absolute;
      width: 10px;
      height: 10px;
      border-top-left-radius: 4px;
      border-top: 2px solid #00AEEF;
      border-left: 2px solid #00AEEF;
      transition: width 0.2s ease-in, height 0.2s ease-in; }

    .bottom-left {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: -8px;
      left: -8px;
      cursor: pointer; }

    .top-left {
      top: -2px;
      left: -2px;
      cursor: nwse-resize; }
    .top-right {
      top: -2px;
      right: -2px;
      cursor: nesw-resize;
      transform: rotateZ(90deg); }
    .bottom-right {
      bottom: -2px;
      right: -2px;
      cursor: nwse-resize;
      transform: rotateZ(180deg); }

    .resize-frame {
      position: absolute;
      border: 2px dashed #00AEEF;
      z-index: 2000; } } }

.products-column {
  margin: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  line-height: 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .product + .product {
    margin-top: 10px; }

  .product {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: auto;
    border: 1px solid $color-border;
    border-radius: 5px;
    background: $bg-gray;
    list-style: none;
    text-align: left;
    padding: 5px 5px 12px;
    line-height: 1rem;
    cursor: pointer;

    .label {
      top: 80px;
      text-align: center;
      margin-top: 10px; }

    .price {
      margin-top: 10px;
      text-align: center;
      color: white;
      border-radius: 10px;
      padding: 4px 6px 4px 6px;
      background: #00B6C0;
      &.-zero {
        background: #A9BFC7; } }

    .image {
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 100%;
        margin: 0; }
      .icon {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }

    .count {
      position: absolute;
      top: 10px;
      right: -10px;
      background: #78C99E;
      color: white;
      border: 2px solid white;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      font-weight: bold;
      text-align: center;
      line-height: 16px; } }

  hr {
    display: inline-block;
    width: 100px;
    margin: 6px 0 6px 0;
    padding: 0;
    border-top: 1px solid $color-label; } }

.upload-click-area {
  width: 100%;
  height: 300px;
  background: $bg-gray;
  border-radius: 50px;
  border: 1px dashed $color-border;
  font-size: 1rem;
  cursor: default;
  &.-pointer {
    cursor: pointer; }
  .image {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center; }
  .hint {
    display: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    padding: 20px;
    background: rgba(128,128,128,0.6);
    color: white;
    cursor: pointer; }
  &:hover {
    .hint {
      display: unset; } } }



